import styled from 'styled-components'

export const StyledEntry = styled.div`
  display: flex;
  gap: 25px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.background.darkBlue};
  padding: 20px;
  width: fit-content;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    gap: 15px;
  }
`
export const ImageWrapper = styled.div`
  width: 30%;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const Image = styled.img`
  width: 100%;
  border-radius: 20px;
`

export const Content = styled.div`
  width: 100%;
`

export const DateLabel = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.color.font.orange};
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const Title = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
`

export const Location = styled.div`
  color: ${({ theme }) => theme.color.font.orange};
  font-size: ${({ theme }) => theme.font.size.sm};
  margin-top: 10px;
`

export const Text = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.sm};
  margin-top: 10px;
  white-space: pre-wrap;
`

export const CTA = styled.a`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
  position: relative;
  &:hover {
    .arrow {
      right: 0px;
    }
  }
`

export const CTAText = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const Arrow = styled.img`
  height: 15px;
  position: absolute;
  right: 40px;
  transition: all 0.2s ease-in-out;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    height: auto;
    width: 40px;
  }
`
