import { Container } from '@think-internet/ui-components'
import Layout from '../Layout/Layout'
import { Box, Boxes, BoxHeadline, BoxSubheadline, Headline, Image, ImageWrapper, StyledReasonOverview, Subheadline } from './ReasonOverview.Styled.js'
import { useSelector } from 'react-redux'
import { getUUID } from '../../utility'
import parents from '../../assets/image/reasonOverview/parents.svg'
import womanChild from '../../assets/image/reasonOverview/woman-child.svg'
import woman from '../../assets/image/reasonOverview/woman.svg'

const ReasonOverview = () => {
  const translation = useSelector((s) => s.TRANSLATION)
  const boxImages = [parents, womanChild, woman]

  return (
    <Layout authRequired={false}>
      <StyledReasonOverview>
        <Container>
          <Boxes>
            {translation.reasonOverview.boxes.map((b, i) => (
              <Box href={b.href} key={getUUID()}>
                <ImageWrapper className="image-wrapper">
                  <Image src={boxImages[i]} className="image" />
                </ImageWrapper>
                <BoxHeadline>{translation.reasonOverview.boxHeadline}</BoxHeadline>
                <BoxSubheadline>
                  {translation.reasonOverview.keywordPrefix}
                  <span className="keyword">{` ${b.keyword}`}</span>
                </BoxSubheadline>
              </Box>
            ))}
          </Boxes>
          <Headline>{translation.reasonOverview.headline}</Headline>
          <Subheadline>{translation.reasonOverview.subheadline}</Subheadline>
        </Container>
      </StyledReasonOverview>
    </Layout>
  )
}

export default ReasonOverview
