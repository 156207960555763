import styled from 'styled-components'

export const StyledStart = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding-bottom: 200px;
  }
`

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: 'bold';
  color: ${({ theme }) => theme.color.font.secondary};
  text-align: center;
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`
export const Subheadline = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
  text-align: center;
  margin-bottom: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.md};
    margin-top: 10px;
  }
`
