import { useSelector } from 'react-redux'
import { StyledPrinciples, Headline, SubHeadline, BigWheel, CTA, HeadlineWrapper } from './Principles.Styled.js'
import bigWheel from '../../../assets/image/concept/principles/bigWheel.svg'
import Animated from '../../SubComponents/Animated/Animated.js'
import { Container } from '@think-internet/ui-components'

const Principles = () => {
  const translation = useSelector((s) => s.TRANSLATION)

  return (
    <StyledPrinciples>
      <Container>
        <HeadlineWrapper>
          <Headline>{translation.concept.principles.headlinePrinciple}</Headline>
          <SubHeadline>{translation.concept.principles.subheadlinePrinciple}</SubHeadline>
        </HeadlineWrapper>
        <Animated animation="fadeIn" showIfVisible duration="3">
          <BigWheel src={bigWheel} />
        </Animated>

        <CTA href={'/grundsaetze'}>{translation.concept.principles.cta}</CTA>
      </Container>
    </StyledPrinciples>
  )
}

export default Principles
