import { useSelector } from 'react-redux'
import { StyledFooter, Column, Flex, Text, ImageWrapper, Logo, BackgroundWave } from './Footer.Styled'
import { Container } from '@think-internet/ui-components'
import props from '../../../redux/props'
import CustomLink from './CustomLink/CustomLink.js'
import kitaLogo from '../../../assets/image/generic/kita-black.svg'
import verbandLogo from '../../../assets/image/generic/drk-black.svg'

const Footer = ({ mode }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledFooter>
      <BackgroundWave mode={mode} />
      <Container>
        <Flex justify={'space-between'}>
          <Flex>
            <Column>
              <Text>{translation.footer.companyName}</Text>
              <Text>{translation.footer.subline}</Text>

              <CustomLink href="/kontakt" text={translation.contact.headline} />
              <CustomLink margin={'10px 0 0 0'} href="/ansprechpartner" text={translation.partner.headline} />
            </Column>
            <Column>
              <CustomLink href="/impressum" text={translation.imprint.headline} />
              <CustomLink margin={'10px 0 0 0'} href="/datenschutz" text={translation.dataProtection.headline} />
            </Column>
          </Flex>

          <Column>
            <ImageWrapper>
              <Logo src={kitaLogo} />
              <Logo src={verbandLogo} />
            </ImageWrapper>
          </Column>
        </Flex>
      </Container>
    </StyledFooter>
  )
}

export default Footer
