import styled from 'styled-components'

export const StyledSplit = styled.div`
  display: flex;
  align-items: center;
  padding: 50px 0;
  justify-content: center;
`

export const Content = styled.div`
  display: flex;
  gap: 25px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column-reverse;
  }
`

export const ImageSection = styled.div`
  width: 60%;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    position: relative;
    z-index: 1;
  }
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 500px;
`

export const TextSection = styled.div`
  width: 40%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const Name = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: 'bold';
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`
export const Title = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.sm};
  }
`
export const Tel = styled.a`
  display: block;
  text-decoration: none;
  margin-top: 25px;
  font-family: 'light';
`
export const Mail = styled.a`
  display: block;
  margin-top: 25px;
  text-decoration: none;
`
export const Street = styled.div`
  margin-top: 25px;
  font-family: 'light';
`
export const City = styled.div`
  font-family: 'light';
`
