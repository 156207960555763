import styled from 'styled-components'

export const StyledAdditionalInfoSelection = styled.div`
  width: 100%;
`

export const Headline = styled.div``

export const List = styled.div`
  background-color: ${({ theme }) => theme.color.background.white};
  height: 150px;
  border-radius: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 5px;
`
export const Prop = styled.div`
  cursor: pointer;
  padding: 10px 15px;
  &:hover {
    font-family: 'bold';
  }
  ${({ selected, theme }) =>
    selected &&
    `
    background-color: ${theme.color.background.thirdary};
  `}
`
