import { Route, Routes, Navigate } from 'react-router-dom'
import Start from './Start/Start'

const KitaOverview = () => {
  return (
    <Routes>
      <Route path={'/'} element={<Start />} />
      <Route path={'*'} element={<Navigate replace to="/kitauebersicht" />} />
    </Routes>
  )
}

export default KitaOverview
