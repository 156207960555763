import { Input } from '@think-internet/ui-components'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import call from '../../../interface/functional'
import props from '../../../redux/props'
import routes from '../../../redux/routes'
import { Prediction, Predictions, StyledPlacePrediction } from './PlacePrediction.Styled'
import { useEffect } from 'react'

const PlacePrediction = ({ set, initLocation = null, initInput = null, placeholder = null, required = false }) => {
  const [input, setInput] = useState(initInput)
  const [predictions, setPredictions] = useState(null)
  const translation = useSelector((s) => s[props.TRANSLATION])

  useEffect(() => {
    if (initLocation && input === null) {
      setInput(initLocation.formatted_address)
    } else if (
      initLocation &&
      ((initLocation.formatted_address && initLocation.formatted_address !== input) || (initLocation.name && initLocation.name !== input))
    ) {
      setInput(initLocation.formatted_address || initLocation.name)
    }
  }, [initLocation, input])

  const getPredictions = async (input) => {
    setInput(input)
    set(null)
    if (input.length > 5) {
      const results = await call(null, routes.get_location_predictions, { input })
      if (results.status && Array.isArray(results.response)) {
        setPredictions(results.response)
      } else {
        setPredictions(null)
      }
    }
  }

  const setPredition = (prediction) => {
    setInput(prediction.name)
    set(prediction)
    setPredictions(null)
  }

  if (typeof set !== 'function') return <></>
  return (
    <StyledPlacePrediction>
      <Input value={input} onChange={getPredictions} placeholder={placeholder || translation.placePrediction.input.placeholder} required={required} />
      {predictions && (
        <Predictions>
          {predictions.map((prediction) => (
            <Prediction key={prediction.place_id} onClick={() => setPredition(prediction)}>
              {prediction.name}
            </Prediction>
          ))}
        </Predictions>
      )}
    </StyledPlacePrediction>
  )
}

export default PlacePrediction
