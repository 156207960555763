import styled from 'styled-components'

export const StyledFamilyCenter = styled.div`
  margin-top: 15px;
`

export const Icons = styled.div`
  display: flex;
  gap: 10px;
`

export const Icon = styled.div`
  height: 40px;
  width: 40px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  ${({ isActive, srcHover }) => (isActive ? `background-image: url(${srcHover});` : '')}
  &:hover {
    background-image: url(${({ srcHover }) => srcHover});
  }
`

export const Text = styled.div`
  margin-top: 10px;
  color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.primary : theme.color.font.orange)};
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const Logo = styled.img`
  height: 40px;
  width: auto;
`
