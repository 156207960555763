import styled from 'styled-components'

export const StyledNews = styled.div`
  padding-bottom: 100px;
`
export const Entries = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`
