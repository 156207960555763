import styled from 'styled-components'
import longArrow from '../../../../assets/image/burgermenu/pfeil_rot.svg'
import shortArrow from '../../../../assets/image/burgermenu/pfeil_weiß.svg'

export const StyledItem = styled.a`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};
  gap: 10px;
  cursor: pointer;
  :hover > div {
    color: ${({ theme, reverse }) => (reverse ? theme.color.font.orange : theme.color.font.secondary)};
  }

  :hover > .arrow {
    transform: translateX(10px);
    background-image: url(${({ reverse }) => (reverse ? shortArrow : longArrow)});
  }
`

export const Text = styled.div`
  color: ${({ theme, reverse }) => (reverse ? theme.color.font.secondary : theme.color.font.orange)};
  font-size: ${({ theme }) => theme.font.size.sm};
`

// export const Arrow = styled.img.attrs(({ reverse }) => ({ src: reverse ? longArrow : shortArrow }))`
//   width: 50px;
// `

export const Arrow = styled.div.attrs({ className: 'arrow' })`
  background-image: url(${({ reverse }) => (reverse ? longArrow : shortArrow)});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 25px;
  height: 10px;
`
