import { Container } from '@think-internet/ui-components'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { levels } from '../../../../enum'
import { list } from '../../../../interface/crud'
import props from '../../../../redux/props'
import toast, { TYPE } from '../../../../toast'
import Layout from '../../../Layout/Layout'
import { Location, Name, StyledLocations, Headline, List } from './Locations.Styled.js'

const Locations = () => {
  const token = useSelector((s) => s[props.TOKEN])
  const translation = useSelector((s) => s[props.TRANSLATION])
  const navigate = useNavigate()

  const [locations, setLocations] = useState(null)

  useEffect(() => {
    const fetchLocations = async () => {
      const response = await list(token, props.LOCATION)
      if (response) {
        if (response.length === 1) {
          navigate(`/dashboard/manager/location/${response[0].uuid}`)
        } else {
          setLocations(response)
        }
      } else {
        toast(TYPE.ERROR, translation.dashboard.manager.locations.error.fetch)
      }
    }
    if (token && !locations) fetchLocations()
  }, [locations, token, translation, navigate])

  const goTo = (uuid) => () => {
    navigate(`/dashboard/manager/location/${uuid}`)
  }

  return (
    <Layout requiredLevel={levels.MANAGER}>
      <StyledLocations>
        <Headline>{translation.dashboard.manager.locations.headline}</Headline>
        <Container>
          <List>
            {Array.isArray(locations) &&
              locations.map((location) => (
                <Location key={location.uuid}>
                  <Name onClick={goTo(location.uuid)}>{location.name}</Name>
                </Location>
              ))}
          </List>
        </Container>
      </StyledLocations>
    </Layout>
  )
}

export default Locations
