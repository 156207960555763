import styled from 'styled-components'
import { Form } from '@think-internet/ui-components'

export const StyledLogin = styled.div`
  padding: 100px 0;
`

export const CustomForm = styled(Form)`
  color: ${({ theme }) => theme.color.white};
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 50px 50px;
  border-radius: ${({ theme }) => theme.border.radius};
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  gap: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 50px 15px;
  }
`

export const Headline = styled.div`
  color: ${({ theme }) => theme.color.white};
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.xlg};
`
