import { StyledSlider, Wrapper, ImageWrapper, Image, Video, Remove } from './Slider.Styled'
import { getUUID, s3StorageBucketPublicURLPrefix } from '../../../../../../utility'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'

const Slider = ({ assetKeys, remove }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  if (!Array.isArray(assetKeys) || assetKeys.length === 0) return <></>

  const getURL = (assetKey) => s3StorageBucketPublicURLPrefix + assetKey

  const isVideo = (assetKey) => {
    if (!assetKey) return false
    const videoExtentions = ['mp4', 'webm', 'mpeg']
    const ext = assetKey.split('.').pop()
    return videoExtentions.includes(ext)
  }

  return (
    <StyledSlider>
      <Wrapper>
        {assetKeys.map((key) => (
          <ImageWrapper key={getUUID()}>
            {isVideo(key) && <Video src={getURL(key)} controls />}
            {!isVideo(key) && <Image src={getURL(key)} />}
            <Remove className="remove" onClick={() => remove(key)}>
              {translation.generic.remove}
            </Remove>
          </ImageWrapper>
        ))}
      </Wrapper>
    </StyledSlider>
  )
}

export default Slider
