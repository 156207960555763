import styled from 'styled-components'

export const StyledApplication = styled.div`
  padding-bottom: 100px;
`

export const OfferingsHeadline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: 'bold';
`

export const MoreHeadline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: 'bold';
  text-align: center;
  margin-top: 50px;
`

export const Dots = styled.div`
  display: inline-block;
  line-height: ${({ theme }) => theme.font.size.xlg};
  font-family: 'bold';
  position: relative;
  margin-top: 20px;
  margin-left: 15px;
  width: 7.5px;
  height: 7.5px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.background.blue};
  color: ${({ theme }) => theme.color.background.blue};
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  &::before,
  &::after {
    content: ' ';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &::before {
    left: -10px;
    width: 7.5px;
    height: 7.5px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.color.background.blue};
    color: ${({ theme }) => theme.color.background.blue};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  &::after {
    left: 10px;
    width: 7.5px;
    height: 7.5px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.color.background.blue};
    color: ${({ theme }) => theme.color.background.blue};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: ${({ theme }) => theme.color.background.blue};
    }
    50%,
    100% {
      background-color: ${({ theme }) => theme.color.background.primary};
    }
  }
`
