//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  header: {
    follow: 'FOLGE UNS HIER',
    back: 'zurück',
  },
  nav: {
    Home: 'Startseite',
    Kitapilot: 'Kitapilot',
    Kitauebersicht: 'Kitaübersicht',
    Ueberuns: 'Über uns',
    OurConcept: 'Unser Konzept',
    Bewerbung: 'Stellenangebote',
    Kontakt: 'Kontakt',
    ManagingDirectors: 'Geschäftsführung',
    Ansprechpartner: 'Ansprechpartner',
    Impressum: 'Impressum',
    Datenschutz: 'Datenschutz',
    Login: 'Login',
    Logout: 'Logout',
    JetztEinenPlatzAnfragen: 'Jetzt einen Platz anfragen',
    InfomaterialAnfordern: 'Infomaterial anfordern',
    Users: 'Benutzer',
    Locations: 'Standorte',
    FamilyCenter: 'Familienzentren',
    News: 'News',
    Districts: 'Kreise',
    Jobs: 'Jobs',
  },

  consent: {
    headline: 'Cookie-Hinweis',
    text: 'Diese Website speichert Cookies auf Ihrem Computer. Diese Cookies werden verwendet, um Ihre Website-Erfahrung zu optimieren und Ihnen einen persönlich auf Sie zugeschnittenen Service bereitstellen zu können, sowohl auf dieser Website als auch auf anderen Medienkanälen. Mehr Infos über die von uns eingesetzten Cookies finden Sie in unserer Datenschutzrichtlinie. Bei Ihrem Besuch auf unserer Seite werden Ihre Daten nicht verfolgt. Damit Sie diesen Hinweis aber nicht jedes mal erneut weg klicken müssen wenn Sie auf unserer Seite unterwegs sind, müssen wir dennoch einen kleinen Cookie speichern, damit werden aber keine personenbezogenen Daten von Ihnen assoziert.',
    acceptCTA: 'Akzeptieren',
    declineCTA: 'Nur essentielle Cookies',
  },

  home: {
    hero: {
      headline: ['Herzlich Willkommen bei', ' Deine Kitas ', 'im Kreis Coesfeld.'],
      subheadline: 'Entdecke mit uns die bunte Welt der Kitas.',
      cta: 'Jetzt einen Platz Anfragen',
    },
    productOverview: {
      headline: ['FINDE JETZT DIE PASSENDE KITA, ODER DURCHSTÖBERE', 'UNSERE STANDORTE UND VERFÜGBARKEITEN.'],
      kitapiloot: {
        description:
          'Die passende Kita in wenigen Minuten finden. Der „Kitapiloot“ hilft Dir in einfachen Schritten die perfekte Kita für Dich zu finden.',
      },
      kitauebersicht: {
        description: 'Hier findest Du alle unsere Standorte auf einem Blick.',
      },
      actionHint: 'Probiere es jetzt aus.',
      hint: [
        'Unsere Kitaübersicht ist nützlich für Eltern und Erziehungsberechtigte, die auf der Suche nach einer passenden Kindertagesstätte für ihr Kind sind. Die Übersicht enthält Informationen über verschiedene Kitas in einer bestimmten Region oder einem bestimmten Kreis, wie zum Beispiel die Lage, die Größe der Einrichtung, die pädagogische Ausrichtung und das Betreuungsangebot.',
        'Unser Kitapilot ist eine Plattform, die eine umfassende Kitaübersicht bereitstellt und Eltern bei der Suche nach der richtigen Kita unterstützt. Durch die Filter- und Suchfunktionen von Kitapilot können Eltern schnell und einfach nach Kitas suchen, die ihren individuellen Bedürfnissen und Wünschen entsprechen.',
        'Die Vorteile von Kitapilot liegen auf der Hand: Eltern sparen Zeit und Mühe bei der Suche nach der passenden Kita und erhalten einen umfassenden Überblick über die verfügbaren Einrichtungen. Außerdem können sie sich alle Kindertagesstätten virtuell erkunden und so eine fundierte Entscheidung treffen. Kitapilot ist daher eine wertvolle Unterstützung für Eltern, die eine gute Kita für ihr Kind suchen.',
      ],
      downloadCTA: 'Infomaterial herunterladen',
    },
    concept: {
      headline: 'UNSER KONZEPT.',
      subheadline: 'DAS IST UNSERE PHILOSOPHIE.',
      description:
        'Unser Konzept basiert auf einer individuellen Förderung jedes Kindes, in einer liebevollen Umgebung mit gesunder Ernährung und ausreichend Bewegung. Wir begleiten die Kinder in ihrer Entwicklung und fördern ihre Talente und Fähigkeiten. Eine respektvolle Kommunikation und Zusammenarbeit mit den Eltern ist uns dabei wichtig. Jedes Kind ist einzigartig, deshalb gestalten wir unsere pädagogische Arbeit flexibel und passen sie individuell an jedes Kind an. Unser Ziel ist es, dass jedes Kind selbstbewusst seinen eigenen Weg gehen kann.',
      cta: 'MEHR ERFAHREN',
    },
    whyAndHistory: {
      cta: 'MEHR ERFAHREN',
      items: [
        {
          title: 'WARUM DEINE KITAS?',
          text: 'Die Wahl der richtigen Kita für das eigene Kind ist eine der wichtigsten Entscheidungen, die Eltern treffen müssen. Doch was macht "Deine Kitas" so besonders und warum sollten Eltern ihre Kinder gerade hier betreuen lassen?',
          href: '/gruende',
        },
        {
          title: 'UNSERE GESCHICHTE',
          text: 'Unsere Geschichte begann vor vielen Jahren, als wir beschlossen, eine Einrichtung zu gründen, die den Bedürfnissen von Kindern und Eltern gerecht wird. Von unseren Anfängen bis hin zu unseren heutigen Einrichtungen erfahren Sie, wie wir zu dem geworden sind, was wir heute sind.',
          href: '/geschichte',
        },
      ],
    },
    news: {
      headline: 'AKTUELLES',
      cta: 'Weiterlesen',
    },
    jobs: {
      headline: 'DEIN NEUER JOB?',
      text: [
        'Du bist auf der Suche nach einem erfüllenden Job, bei dem Du einen positiven Einfluss auf die Entwicklung von Kindern nehmen kannst? Dann ist eine Stelle bei „Deine.Kitas“ genau das Richtige für Dich! Als Mitarbeiter*in bei „Deine.Kitas“ hast Du die Chance, Kinder in ihrer Entwicklung zu begleiten und zu unterstützen.',
        'Bei „Deine.Kitas“ arbeitest Du in einem engagierten Team, das sich täglich dafür einsetzt, den Kindern eine liebevolle und fördernde Umgebung zu bieten. Dabei hast Du die Möglichkeit, Deine eigenen Stärken und Fähigkeiten einzubringen und in einem motivierten Team mitzugestalten.',
        'Wenn Du Dich noch nicht bei uns beworben hast, solltest Du jetzt die Chance nutzen und Dich für eine Stelle bei „Deine.Kitas“ bewerben. Wir freuen uns auf Dich!',
      ],
      cta: 'JETZT BEWERBEN!',
    },
  },

  story: {
    hero: {
      headline: 'ERFAHRE ALLES ÜBER UNS.',
      subheadline: ['WIR ZEIGEN DIR WIE WIR ENTSTANDEN SIND.'],
    },
    headline: 'DIE GESCHICHTE DES ROTEN KREUZES',
    text: 'Die Geschichte des Deutschen Roten Kreuzes ist mehr als 150 Jahre alt. So wurde 1863 in Baden-Württemberg die erste Rotkreuzgesellschaft der Welt gegründet. Die Idee, Menschen allein nach dem Maß der Not zu helfen, ohne auf Hautfarbe, Religion oder Nationalität zu achten, geht auf den Schweizer Henry Dunant zurück.',
    accordion: {
      items: [
        { title: 'DIE SCHLACHT VON SOLFERINO:', subtitle: 'GEBURT DES ROTKREUZGEDANKENS' },
        { title: '"EINE ERINNERUNG AN SOLFERINO"', subtitle: '- DUNANTS BUCH UND SEINE VISION' },
        { title: 'EINE IDEE WIRD WIRKLICHKEIT' },
      ],
      solferino: {
        war: 'Als Geschäftsmann war Henry Dunant 1859 in Italien unterwegs, als er die Folgen der Schlacht von Solferino, des entscheidenden Gefechts zwischen dem Kaisertum Österreich und dem Königreich Piemont-Sardinien sowie dessen Verbündetem Frankreich im Sardinischen Krieg, miterlebte. Dem 31-Jährigen bot sich ein schreckliches Bild. An Straßenrändern, auf Plätzen und in Kirchen lagen dicht an dicht verwundete Soldaten. Dunant erlebte das Grauen des größten Waffengangs jener Zeit in seiner ganzen Brutalität.',
        person: [
          'Der Geschäftsmann vergaß seine ursprüngliche Mission und kümmerte sich um Verwundete wie Sterbende. Er wusch schmutzige Wunden aus, verteilte Lebensmittel und Wasser, sprach Mut zu. Auch für Nachschub an Verbandsmaterial und Nahrung sorgte Dunant. Weil professionelle Hilfe überall fehlte, forderte Dunant Einheimische zur Mithilfe auf – Frauen, Kinder und Männer halfen mit. „Sono tutti fratelli“ – wir sind alle Brüder – sagten sie und versorgten jeden Verletzten ungeachtet seiner Nationalität.',
          'Als Dunant erfuhr, dass die Franzosen österreichische Ärzte gefangen hielten, suchte er den französischen Herrscher auf. Er gestattete den österreichischen Ärzten an dem Hilfseinsatz teilzunehmen. Zusammen mit Dunant praktizierten diese Freiwilligen zum ersten Mal den Grundsatz des späteren Roten Kreuzes: dass alle verwundeten Soldaten neutral und gleich zu behandeln sind.',
        ],
      },
      rememberSolferino: {
        text: [
          'Henry Dunant verarbeitete seine Erinnerungen an die Erlebnisse in Italien in seinem Buch „Un souvenir de Solferino“ (Eine Erinnerung an Solferino). Noch heute gilt es als literarisches Meisterstück, mit dem er die damalige Gesellschaft in Europa aufrüttelte.',
          'In seinem Buch schildert Dunant zunächst die politischen Zusammenhänge und informiert detailliert über das Militär und Kriegsstrategien. Danach beschreibt er in einem dramatischen Epos den Ablauf der Schlacht und das Gemetzel auf den Schlachtfeldern. Es fällt kein Wort über den Triumph der Siegermächte. Dunant beschreibt stattdessen, wie primitiv und brutal die Verwundetentransporte durchgeführt wurden oder wie prekär die Zustände in den Lazaretten waren.',
          'Die letzten Seiten widmete Dunant seiner Vision: „Wäre es nicht möglich, in Friedenszeiten eine freiwillige Organisation zu gründen, deren Zweck es sein müsste, die Verwundeten in Kriegszeiten durch begeisterte und aufopfernde Freiwillige, die für ein solches Werk besonders geeignet sind, pflegen zu lassen?“ Er schloss sein Buch mit dem Appell, Hilfsgesellschaften für Verwundete in verschiedenen Ländern Europas zu gründen.',
          'Dunant ließ auf eigene Rechnung 1.600 Exemplare seines Buches drucken, die er mit persönlicher Widmung an Fürsten, Generäle und Regierungen in Europa verschickte. Das Echo war gewaltig. Eine zweite Auflage erschien schon vier Monate nach der ersten und wurde zum Bestseller, der Verfasser berühmt wie ein Star.',
        ],
      },
      truth: {
        text: [
          'Einer der Ersten, die Dunant gratulierten, war der Genfer Gustave Moynier, ein brillanter Jurist und erprobter Organisator. Er war Präsident der privaten Gemeinnützigen Gesellschaft Genfs und konnte Dunant für ein Referat vor zwanzig angesehenen Bürgern der Stadt gewinnen, unter ihnen General Guillaume-Henri Dufour.',
          'Die Versammlung beauftragte fünf Anwesende, einen Plan zu erstellen, wie Dunants Idee, „kriegsführende Armeen durch Korps freiwilliger Krankenpfleger zu unterstützen“ in die Tat umgesetzt werden konnte. Das Fünfergremium mit der Bezeichnung „Ständiges Internationales Komitee“ – dem späteren Internationalen Komitee vom Roten Kreuz (IKRK) – setzte sich zusammen aus General Henri Dufour als Präsident, Gustave Moynier als Vizepräsident, Dunant als Sekretär und den zwei Ärzten Louis Appia, einem Spezialist in Chirurgie, und Théodore Maunoir, einem Mediziner mit internationaler Erfahrung.',
          'Für seine Verdienste erhielt Henry Dunant 1901 gemeinsam mit Frédéric Passy den Friedensnobelpreis.',
        ],
      },
    },
  },

  reasonOverview: {
    boxHeadline: '10 Gründe',
    keywordPrefix: 'für uns als',
    boxes: [
      {
        keyword: 'Elternteil.',
        href: '/gruende/eltern',
      },
      {
        keyword: 'Kind.',
        href: '/gruende/kinder',
      },
      {
        keyword: 'Arbeitgeber.',
        href: '/gruende/interessierte',
      },
    ],
    headline: 'Hier erfährst du unsere 10 Guten Gründe.',
    subheadline: 'Wähle aus, was dich interessiert.',
  },

  reasonPages: {
    thoughtsHeadlines: ['NOCH NICHT ÜBERZEUGT?', 'DANN SCHREIB UNS DOCH EINFACH.'],
    parents: {
      heroSubline: 'FÜR ELTERN.',
      headline: ['10 Gründe für die Wahl ihr', 'Kind bei uns betreuen zu lassen.'],
      reasons: [
        'Willkommenskultur.',
        'Tiergestützte Pädagogik.',
        'Wertschätzende Haltung zu Kind, Familie und MA (?).',
        'ERZIEHUNGSPARTNERSCHAFT ZU FAMILIEN.',
        'PÄDAGOGISCHES KONZEPT.',
        'JEDES KIND WIRD EINZIGARTIG GESEHEN.',
        'TEAMS, DIE ENGAGIERT BEI DER ARBEIT SIND.',
        'SCHÖNE UND MODERNE KITAS.',
        'WERTVOLLE ERZIEHUNG (LEITBILD).',
        'REGIONALER TRÄGER MIT 33 STANDORTEN.',
      ],
    },
    children: {
      heroSubline: 'FÜR KINDER.',
      headline: ['10 GRÜNDE FÜR DIE BETREUUNG', 'BEI UNS ALS KIND.'],
      reasons: [
        'ICH WERDE GESEHEN, SO WIE ICH BIN.',
        'ICH DARF MIT TOLLEM SPIELZEUG SPIELEN.',
        'ICH DARF MITENTSCHEIDEN.',
        'ES IST IMMER JEMAND FÜR MICH DA.',
        'ICH BESTIMME, MIT WEM ICH WO SPIELE.',
        'ICH DARF MICH AUSPROBIEREN UND WERDE DABEI UNTERSTÜTZT.',
        'ICH LERNE FÜR MEIN LEBEN.',
        'ICH DARF IN MEINEM TEMPO ERFAHRUNGEN MACHEN.',
        'ICH FINDE FREUNDE.',
        'ICH DARF HIER KIND SEIN.',
      ],
    },
    interested: {
      heroSubline: 'FÜR INTERESSIERTE.',
      headline: ['10 GRÜNDE FÜR UNS ALS ARBEITGEBER.'],
      reasons: [
        'MITBESTIMMUNG.',
        'WERTSCHÄTZENDER UMGANG.',
        'TARIFLICHE VERGÜTUNG.',
        'BENEFITS: JOBBIKE, ZUSATZVORSORGE.',
        'FORTBILDUNG.',
        'PÄDAGOGISCHES KONZEPT.',
        'TIERGESTÜTZTE PÄDAGOGIK.',
        'WOHLFÜHLEN IM TEAM.',
        'PERSÖNLICHE WEITERENTWICKLUNG.',
        'WILLKOMMENSKULTUR.',
      ],
    },
  },
  dashboard: {
    logoutCTA: 'Ausloggen',
    loginCTA: 'Einloggen',
    admin: {
      users: {
        headline: 'Benutzer',
        create: {
          headline: 'Neuen Benutzer anlegen',
          success: {
            submit: 'Benutzer erfolgreich angelegt.',
          },
          error: {
            submit: 'Benutzer konnte nicht angelegt werden.',
          },
        },
        list: {
          headline: 'Bestehende Nutzer',
        },
        update: {
          level: {
            label: 'Berechtigungsstufe',
          },
          success: {
            save: 'Einstellungen gespeichert.',
          },
          error: {
            save: 'Einstellungen konnten nicht gespeichert werden.',
          },
        },
      },
      locations: {
        headline: 'Standorte',
        search: {
          placeholder: 'Standort suchen...',
        },
        upsert: {
          toggleCTA: 'Neuen Standort anlegen',
          close: 'Schließen',
          availability: {
            headline: 'Verfügbarkeit der Plätze wählen',
          },
          gallery: {
            title: 'Bilder/Videos hochladen',
            hint: 'Bild max. 5 MB | Video max. 100 MB (mp4, webm, mpeg)',
          },
          conceptInfo: {
            title: 'PDF ihres individuellen pädagogischen Konzepts',
            hint: 'Maximale Dateigröße von 10 MB',
          },
          contactPerson: {
            title: 'Bild des Ansprechpartners hochladen',
            hint: 'Maximale Bildgröße von 5 MB',
          },
          managerAssignment: {
            headline: 'Standortverantwortliche zuweisen',
          },
          success: {
            submit: 'Standort erfolgreich gespeichert.',
          },
          error: {
            submit: 'Standort konnte nicht gespeichert werden.',
            upload: 'Bilder konnten nicht verarbeitet werden.',
            managerFetch: 'Benutzer konnten nicht geladen werden.',
          },
          processingAssetLabel: 'Datei wird verarbeitet...',
          maxFileSizeExceededToast: 'Maximale Dateigröße von 100 MB überschritten.',
        },
      },
      familyCenter: {
        headline: 'Familienzentren',
        search: {
          placeholder: 'Familienzentrum suchen...',
        },
        upsert: {
          toggleCTA: 'Neues Familienzentrum anlegen',
          close: 'Schließen',
          locationAssignment: {
            headline: 'Standortzuordnung',
          },
          infoPDF: {
            title: 'Info-PDF hochladen',
            hint: 'Maximale Dateigröße von 10 MB',
            removeExisting: 'Vorhandene PDF entfernen',
            dynamicTitlePDF: 'Info-PDF für: ',
          },
          moreInfoPDFManager: {
            title: '"Familienzentrum und mehr"-Download',
            addFile: 'PDF hinzufügen',
            hint: 'Maximale Dateigröße von 10 MB',
            remove: 'PDF entfernen',
            status: {
              uploading: "PDF's werden hochgeladen...",
              processing: "PDF's werden verarbeitet...",
            },
          },
          services: {
            headline: 'Angebotene Leistungen',
            add: 'Leistung hinzufügen',
            namePlaceholder: 'Leistungsbereich',
            pointsPlaceholder: 'Punkte (Ein Punkt je Zeile)',
          },
          success: {
            submit: 'Familienzentrum erfolgreich gespeichert.',
          },
          maxFileSizeExceededToast: 'Maximale Dateigröße von 10 MB überschritten.',
          processingPDFLabel: 'PDF wird verarbeitet...',
          error: {
            submit: 'Familienzentrum konnte nicht gespeichert werden.',
            upload: 'Datei konnte nicht verarbeitet werden.',
          },
        },
      },
      districts: {
        headline: 'Kreise',
        search: {
          placeholder: 'Kreis suchen...',
        },
        upsert: {
          toggleCTA: 'Neuen Kreis anlegen',
          close: 'Schließen',
          outline: {
            title: 'Bild des Umrisses hochladen',
            hint: 'Maximale Bildgröße von 5 MB',
          },
          bg: {
            title: 'Hintergrundbild hochladen',
            hint: 'Maximale Bildgröße von 5 MB',
          },
          locationAssignment: {
            headline: 'Standortzuordnung',
          },
          success: {
            submit: 'Kreis erfolgreich gespeichert.',
          },
          error: {
            submit: 'Kreis konnte nicht gespeichert werden.',
            upload: 'Bilder konnten nicht verarbeitet werden.',
          },
          processingImageLabel: 'Bild wird verarbeitet...',
          maxFileSizeExceededToast: 'Maximale Bildgröße von 5 MB überschritten.',
        },
      },
      jobs: {
        headline: 'Jobs',
        search: {
          placeholder: 'Job suchen...',
        },
        upsert: {
          toggleCTA: 'Neuen Job anlegen',
          close: 'Schließen',
          currentPDFDownload: 'Aktuelle PDF herunterladen',
          pdf: {
            title: 'PDF hochladen',
            hint: 'Maximale Dateigröße von 10 MB',
          },
          success: {
            submit: 'Job erfolgreich gespeichert.',
          },
          error: {
            submit: 'Job konnte nicht gespeichert werden.',
            upload: 'PDF konnten nicht verarbeitet werden.',
          },
          processingPDFLabel: 'PDF wird verarbeitet...',
          maxFileSizeExceededToast: 'Maximale Dateigöße von 10 MB überschritten.',
        },
      },
    },
    manager: {
      locations: {
        headline: 'Standorte',
        update: {
          availability: {
            headline: 'Verfügbarkeit der Plätze wählen',
          },
        },

        error: {
          fetch: 'Fehler beim Laden der Daten.',
        },
      },
      familyCenters: {
        headline: 'Familienzentren',
        update: {
          availability: {
            headline: 'Verfügbarkeit der Plätze wählen',
          },
        },

        error: {
          fetch: 'Fehler beim Laden der Daten.',
        },
      },
    },
    sharedPages: {
      news: {
        headline: 'News',
        title: 'Title',
        text: 'Text',
        search: {
          placeholder: 'Nach Artikel suchen...',
        },
        upsert: {
          toggleCTA: 'Neuen Artikel anlegen',
          images: {
            title: 'Bilder',
            hint: 'Maximale Bildgröße von 5 MB',
            maxFileSizeExceededToast: 'Maximale Bildgröße von 5 MB überschritten.',
            processingImageLabel: 'Bild wird verarbeitet...',
          },
          locationAssignment: {
            headline: 'Standortzuordnung',
          },
        },
        error: {
          upload: 'Bilder konnten nicht verarbeitet werden.',
          submit: 'Artikel konnte nicht gespeichert werden.',
        },
        success: {
          submit: 'Artikel erfolgreich gespeichert.',
        },
      },
    },
  },

  news: {
    hero: {
      headline: 'DAS GIBT‘S BEI UNS NEUES.',
    },
  },

  application: {
    hero: {
      headline: 'WERDE EIN TEIL VON UNS.',
      subheadline: 'WIR FREUEN UNS AUF DICH.',
    },
    personio: {
      headline: 'AKTUELLE STELLENANZEIGEN.',
      location: 'Standort ',
    },
    offerings: {
      headline: 'DAS BIETEN WIR DIR:',
      itemCTA: 'MEHR ERFAHREN',
      items: [
        {
          headline: 'JOBBIKE',
          subheadline: 'SO HALTEN WIR DICH MOBIL.',
          text: [
            'Wir bei "Deine.Kitas" legen großen Wert auf das Wohlfühlen unserer Mitarbeiter*innen. Deshalb haben wir das "JobBike"-Programm ins Leben gerufen, um Dich mobil zu halten und Deine Gesundheit zu fördern.',
            'Als Teil des "JobBike"-Programms hast Du die Möglichkeit, ein Dienstfahrrad zu nutzen und damit zur Arbeit zu fahren. Das fördert nicht nur Deine Gesundheit, sondern auch die Umwelt.',
            'Zusätzlich kannst Du mit dem "JobBike"-Programm Deine Freizeit aktiv gestalten und die schöne Natur im Kreis Coesfeld entdecken. Wir sind davon überzeugt, dass eine gesunde Work-Life-Balance ein wichtiger Bestandteil eines erfüllten Berufslebens ist. Mehr findest Du online unter <a href="http://www.drk-dienstrad.de/" target="_blank">www.drk-dienstrad.de</a>.',
          ],
          cta: 'Mehr erfahren',
        },
        {
          headline: 'ZUSATZVORSORGE',
          subheadline: 'DEINE ZUKUNFT.',
          text: [
            'Wir bei "Deine.Kitas" möchten, dass Du auch in Zukunft sorgenfrei und ohne finanzielle Belastungen leben kannst. Deshalb schließen wir für alle unsere Mitarbeitenden eine Zusatzversicherung ab, damit Du gut abgesichert bist, wenn Du einmal nicht mehr arbeitest.',
            'Als Mitarbeiter*in bei uns kannst Du Dich darüber hinaus noch weiter für die Zeit nach dem Arbeitsleben absichern. Dadurch kannst Du Deine Zukunft aktiv gestalten und Dich auf ein finanziell abgesichertes Leben freuen.',
            'Unser "Zusatzvorsorge"-Programm ist ein wichtiger Bestandteil unseres Bemühens, unsere Mitarbeiter*innen bestmöglich zu unterstützen und zu fördern. Denn wir sind davon überzeugt, dass ein sorgenfreies Leben die Basis für ein erfülltes Berufsleben bildet.',
          ],
          cta: 'Mehr erfahren',
        },
        {
          headline: 'PERSÖNLICHE WEITERENTWICKLUNG',
          subheadline: 'WIR STÄRKEN DICH.',
          text: [
            // 'Wir bei "Deine.Kitas" legen großen Wert auf die persönliche Weiterentwicklung unserer Mitarbeiter*innen. Denn wir sind davon überzeugt, dass Deine individuellen Stärken und Talente unser Unternehmen bereichern und voranbringen.',
            // 'Deshalb bieten wir Dir als Teil unseres Teams die Möglichkeit, Dich regelmäßig weiterzubilden und Deine Fähigkeiten zu stärken. Durch interne Schulungen, Workshops und individuelle Entwicklungspläne für Deine Weiterbildung unterstützen wir Dich dabei, Deine Ziele zu erreichen und Dein volles Potenzial zu entfalten.',
            // 'Wir sind davon überzeugt, dass eine kontinuierliche persönliche Weiterentwicklung nicht nur für Dich, sondern auch für unser Unternehmen von Vorteil ist. Denn nur so können wir uns stetig verbessern und die uns anvertrauten Kinder und deren Familien optimal begleiten.',
            'Wir bei "Deine.Kitas" legen großen Wert auf die persönliche Weiterentwicklung unserer Mitarbeiter*innen. Wir glauben fest daran, dass Deine individuellen Stärken und Talente unser Unternehmen bereichern und voranbringen können.',
            'Deshalb bieten wir Dir als Teil unseres Teams die Möglichkeit, Dich regelmäßig weiterzubilden und Deine Fähigkeiten zu stärken. Wir unterstützen Dich dabei durch interne Schulungen, Workshops und individuelle Entwicklungspläne, um Deine Ziele zu erreichen und Dein volles Potenzial zu entfalten.',
            'Wir sind davon überzeugt, dass eine kontinuierliche persönliche Weiterentwicklung nicht nur für Dich, sondern auch für unser Unternehmen von Vorteil ist. Nur so können wir uns stetig verbessern und die uns anvertrauten Kinder und deren Familien optimal begleiten.',
          ],
          cta: 'Mehr erfahren',
        },
      ],
    },
    more: {
      headline: 'UND VIELES MEHR',
      cta: 'HIER GEHT ES ZUR STELLENANZEIGE',
    },

    form: {
      jobs: {
        toggle: 'WÄHLE DEINE STELLENANZEIGE',
      },
      upload: 'Lade hier deine Bewerbung als PDF hoch. (Maximal 50 MB)',
      firstname: 'Dein Vorname...',
      lastname: 'Dein Nachname...',
      mail: 'Deine Mailadresse...',
      message: 'Deine Nachricht...',
      ctaSend: 'Absenden',
      cv_error: 'Bitte füge deinen Lebenslauf hinzu.',
      max_size_error: 'Die Datei ist zu groß. Bitte wähle eine PDF-Datei mit einer Größe von maximal 50 MB.',
      success: 'Vielen Dank für deine Bewerbung. Wir werden uns schnellstmöglich bei dir melden.',
      error: 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.',
    },
  },
  contactPerson: {
    hero: {
      headline: 'WIR SIND DEINE ANSPRECHPARTNER:INNEN',
      subheadline: 'WIR FREUEN UNS DARAUF MIT DIR KONTAKT AUFZUNEHMEN.',
    },
    sections: [
      {
        people: [
          {
            name: 'Arnd Rutenbeck',
            // title: 'Fachberatung Kita',
            speciality: 'Personal und Finanzen',
            phone: '02541 9442-1501',
            fax: '02541 9442-1099',
            mail: 'a.rutenbeck@drk-coe.de',
            street: 'Bahnhofstraße 128',
            city: '48653 Coesfeld',
          },
          {
            name: 'Nina Baringhorst',
            speciality: 'pädagogische Fachberatung',
            phone: '02541 9442-1506',
            fax: '02541 9442-1099',
            mail: 'n.baringhorst@drk-coe.de',
            street: 'Bahnhofstraße 128',
            city: '48653 Coesfeld',
          },
          {
            name: 'Valessa Burmeister',
            speciality: 'pädagogische Fachberatung',
            phone: '02541 9442-1505',
            fax: '02541 9442-1099',
            mail: 'v.burmeister@drk-coe.de',
            street: 'Bahnhofstraße 128',
            city: '48653 Coesfeld',
          },
          {
            name: 'Birgit Poschmann',
            speciality: 'Inklusion, Kinderschutz, Migration, Projektmanagement',
            phone: '02541 9442-1502',
            fax: '02541 9442-1099',
            mail: 'b.poschmann@drk-coe.de',
            street: 'Bahnhofstraße 128',
            city: '48653 Coesfeld',
          },
          {
            name: 'Bernadette Nattler',
            speciality: 'pädagogische Fachberatung',
            phone: '02541 9442-1503',
            fax: '02541 9442-1099',
            mail: 'b.nattler@drk-coe.de',
            street: 'Bahnhofstraße 128',
            city: '48653 Coesfeld',
          },
          {
            name: 'Andrea Zellner',
            speciality: 'Verwaltungskraft',
            phone: '02541/9442-1504',
            fax: '02541 9442-1099',
            mail: 'a.zellner@drk-coe.de',
            street: 'Bahnhofstraße 128',
            city: '48653 Coesfeld',
          },
        ],
      },
      {
        title: 'UNSER BETRIEBSRAT',
        people: [
          {
            name: 'Fabian Poschmann',
            position: 'Betriebsratsvorsitzender | Staatlich anerkannter Erzieher',
            phone: '02541 9442-1161',
            mobile: '01577 7416864',
            mail: 'f.poschmann@drk-coe.de',
          },
          {
            name: 'Doris Fromm',
            position: 'Stellv. Betriebsratsvorsitzende | Dipl. Pädagogin',
            phone: '02541 9442-1162',
            mobile: '01577 7415404',
            mail: 'd.fromm@drk-coe.de',
          },
        ],
      },
    ],
    subline: 'WIR FREUEN UNS VON DIR ZU HÖREN.',
  },

  concept: {
    hero: {
      headline: 'Unser Konzept für deine Kitas und Dich.',
      subheadline: ['Weil ', 'du ', 'uns wichtig bist.'],
    },

    philosophieAndConcept: {
      splitSections: [
        {
          headline: 'Unsere Philosophie.',
          subHeadline: 'Das ist unsere Philosophie.',
          text: [
            'Unsere Philosophie bei "Deine Kitas im Kreis Coesfeld" ist es, eine liebevolle und fördernde Umgebung für Kinder zu schaffen, in der sie sich frei entfalten können. Wir glauben daran, dass jedes Kind einzigartig ist und seine eigenen Talente und Fähigkeiten hat, die wir unterstützen und fördern möchten.',
            'Als Mitarbeiter*innen in unseren Kitas setzen wir uns täglich dafür ein, dass sich die Kinder bei uns wohl und geborgen fühlen. Wir legen großen Wert auf eine enge Zusammenarbeit zwischen Eltern und pädagogischem Personal, um die individuellen Bedürfnisse jedes Kindes bestmöglich zu berücksichtigen.',
            'Unser Ziel ist es, die Kinder in ihrer Entwicklung zu begleiten und zu unterstützen, damit sie zu selbstbewussten und glücklichen Menschen heranwachsen können.',
          ],
        },
        {
          headline: 'Unser Konzept.',
          subHeadline: 'Das ist unser Konzept.',
          text: [
            'Unser Konzept bei "Deine Kitas im Kreis Coesfeld" basiert auf dem Early-Excellence-Ansatz. Wir glauben daran, dass jedes Kind von Anfang an individuell und ganzheitlich gefördert werden sollte, um sein volles Potential entfalten zu können.',
            'Der Early-Excellence-Ansatz sieht vor, dass Kinder schon frühzeitig in ihrer Entwicklung unterstützt werden, um ihnen die bestmöglichen Startchancen zu bieten. Dabei steht die enge Zusammenarbeit zwischen Eltern, pädagogischem Personal und anderen Fachkräften im Fokus.',
            'In unseren Kitas legen wir großen Wert auf die Schaffung einer liebevollen und fördernden Umgebung, in der sich die Kinder frei entfalten können. Wir bieten eine breite Palette an pädagogischen Angeboten und Aktivitäten, die auf die individuellen Bedürfnisse und Interessen jedes Kindes abgestimmt sind.',
            'Unser Ziel ist es, den Kindern nicht nur eine gute Vorbereitung auf die Schule zu bieten, sondern auch ein solides Fundament für ihr späteres Leben zu schaffen.',
          ],
        },
      ],
      cta: 'Jetzt einen Platz anfragen',
    },

    principles: {
      headlinePrinciple: 'Unsere 7 Grundsätze.',
      subheadlinePrinciple: '7 Richtige für dein Kind.',
      cta: 'ZU DEN GRUNDSÄTZEN',
    },
  },

  managingDirectors: {
    people: {
      headline: 'DAS SIND WIR.',
      sections: [
        {
          title: 'UNSERE GESCHÄFTSFÜHRUNG',
          people: [
            {
              name: 'Christoph Schlütermann',
              position: 'Geschäftsführer',
              phone: '02541 9442-1000',
              fax: '02541 9442-1099',
              mail: 'c.schluetermann@drk-coe.de',
              street: 'Bahnhofstraße 128',
              city: '48653 Coesfeld',
            },
          ],
        },
        {
          title: 'UNSER AUFSICHTSRAT',
          people: [{ name: 'Konrad Püning Landrat a.D.', position: 'Aufsichtsratsvorsitzender' }],
        },
      ],
    },
  },

  principles: {
    hero: {
      headline: 'Unsere 7 Grundsätze für deine Kitas',
      subheadline: ['Weil ', 'du ', 'uns wichtig bist.'],
    },
    cards: {
      headline: 'SCHAU DICH MAL UM.',
      items: [
        {
          title: 'Unparteilichkeit',
        },
        {
          title: 'Menschlichkeit',
        },
        {
          title: 'Einheit',
        },
        {
          title: 'Freiweilligkeit',
        },
        {
          title: 'Neutralität',
        },
        {
          title: 'Unabhängigkeit',
        },
        {
          title: 'Universalität',
        },
      ],
    },
  },
  contact: {
    headline: 'Kontakt',
    hero: {
      headline: 'Sag uns was du wissen möchtest.',
      subheadline: ['Wir freuen uns auf eine Nachricht von dir.'],
    },
    form: {
      firstname: 'Dein Vorname...',
      lastname: 'Dein Nachname...',
      mail: 'Deine Mailadresse...',
      category: 'Nenn uns eine Kategorie...',
      categories: ['Ich bin Elternteil/Kind/Interessent:in', 'Ich habe eine Frage zum Träger/Kita/Aufnahmeprozess', 'Sonstiges'],
      message: 'Deine Nachricht...',
      newsletter: 'ZUM NEWSLETTER ANMELDEN, UM KEINE NEUIGKEITEN MEHR ZU VERPASSEN.',
      cta: 'Abschicken',
    },
  },

  download: {
    headline: 'HOLE DIR DIE INFORMATIONEN, DIE DU BRAUCHST.',
    subheadline: ['LADE DIR HIER KOSTENLOS DAS INFOMATERIAL ZU UNS HERUNTER.'],
    cta: 'HERUNTERLADEN',
  },
  downloadThankYou: {
    headline: 'VIELEN DANK FÜR DEIN INTERESSE AN DEINE KITAS.',
    subheadline: ['BEI FRAGEN KANNST DU DICH GERNE AN UNS WENDEN.'],
  },

  kitapiloot: {
    start: {
      headline: 'Wähle Deinen Standort aus.',
      subheadline: 'Wir benötigen Deinen Standort, um für Dich die Kitas in der Nähe anzuzeigen.',
    },
    search: {
      radiusSelection: {
        label: 'Wähle einen Radius aus:',
      },
      useExactLocation: {
        label: 'Genauen Standort verwenden',
      },
      cta: 'Loslegen',
    },
    results: {
      error: {
        noLocations: 'Es wurden keine Kitas in der Nähe gefunden.',
        internal: 'Bei der Suche ist ein Fehler aufgetreten.',
      },
      location: {
        distance: ' Kilometer entfernt',
        city: 'Standort ',
        header: {
          genericInfo: 'Kita Standorte',
          trafficLight: 'Freie Plätze',
          detail: 'Besichtigen',
        },
      },
    },
  },

  kitaoverview: {
    start: {
      headline: 'Wo möchtest du nach einer passenden Kita suchen?',
      subheadline: 'Stöbere durch unsere Kitas und finde die perfekte Kita für Dich und dein Kind.',
    },
    districts: {
      namePrefix: 'Kita in ',
    },
    results: {
      error: {
        noLocations: 'Es wurden keine Kitas in diesem Kreis gefunden.',
        internal: 'Bei der Suche ist ein Fehler aufgetreten.',
      },
    },
  },

  kita: {
    detail: {
      thatsUs: 'Das sind Wir.',
      discoverVirtually: 'Virtuell erkunden',
      externalSignup: 'Zum Anmeldeportal',
      conceptInfo: 'Unser pädagogisches Konzept',
      contactNow: 'Jetzt kontaktieren',
      back: 'zurück',
      availability: {
        AVAILABLE: 'Wir haben freie Plätze zu vergeben.',
        LIMITED: 'Es gibt nur noch wenige freie Plätze.',
        UNAVAILABLE: 'Leider sind aktuell keine Plätze verfügbar.',
      },
      familyCenter: {
        button: 'Angebote Familienzentrum',
        headline: 'Zusätzliche Eigenschaft dieser Kita',
        staticProperties: {
          beratung: 'Beratung und Unterstützung',
          familienbildung: 'Familienbildung und Erziehungspartnerschaft',
          vereinbarkeit: 'Vereinbarkeit von Familie und Beruf',
        },
        properties: {
          b: 'B = Berufstätigkeit und zeitsensible Angebotsgestaltung',
          f: 'F = Familienbegleitung in schwierigen Lebenssituationen',
          h: 'H = Heterogene Struktur (Leistungen aus den 6 anderen Profilbereichen auswählbar)',
          k: 'K = Kindertagespflege',
          l: 'L = Ländlich geprägter Raum',
          m: 'M = Migration und Integration',
          p: 'P = Prävention',
          moreInfoZIP: 'Familienzentrum und mehr',
        },
        popup: {
          headline: 'Hol Dir jetzt alle Informationen, die Du brauchst.',
          subheadline: 'Schau dir unsere Leistungsbereiche an.',
          close: 'zurück',
        },
      },
    },
  },

  pageNotFound: {
    headline: 'huch...',
    subheadline: 'Da wurde der Stecker gezogen.',
  },

  placePrediction: {
    input: {
      placeholder: 'Deine Adresse...',
    },
  },

  partner: {
    headline: 'Ansprechpartner',
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Datenschutz',
  },

  footer: {
    companyName: 'DRK Kita gGmbH',
    subline: 'Deine Kitas im Kreis Coefeld',
  },

  generic: {
    salutation: 'Anrede',
    title: 'Titel',
    name: 'Name',
    firstname: 'Vorname',
    lastname: 'Nachname',
    address: 'Adresse',
    street: 'Straße',
    postcode: 'Postleitzahl',
    city: 'Stadt',
    phone: 'Telefon',
    phoneShortPrefix: 'Tel: ',
    mobileShortPrefix: 'Mobil: ',
    fax: 'Fax',
    faxShortPrefix: 'Fax: ',
    mail: 'E-Mail',
    password: 'Passwort',
    km: ' km',
    save: 'Speichern',
    remove: 'Löschen',
    description: 'Beschreibung',
    virtualViewingLink: 'Link zur virtuellen Besichtigung',
    contactPerson: 'Ansprechpartner',
    responsiblePerson: 'Leitung',
    externalSignupLink: 'Link zur externen Anmeldung',
    availability: {
      AVAILABLE: 'Verfügbar',
      LIMITED: 'Limitiert',
      UNAVAILABLE: 'Ausgebucht',
    },
    level: {
      ADMIN: 'Administator',
      MANAGER: 'Manager',
    },
  },

  login: {
    headline: 'Login',
    cta: 'Einloggen',
    error: 'E-Mail Adresse oder Passwort falsch.',
  },

  UI: {
    dropdown: {
      emptySelection: 'Bitte wählen',
    },
  },
}

module.exports = {
  translation,
  langKey: 'DE',
}
