import { Processing, StyledImageVideoManager, CustomDragAndDrop } from './ImageVideoManager.Styled'
import Slider from './Slider/Slider'
import toast, { TYPE } from '../../../../../toast'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { s3Upload } from '../../../../../fetch'
import call from '../../../../../interface/functional'
import routes from '../../../../../redux/routes'

const ImageVideoManager = ({ callback, title, hint, existingAssetKeys = [], multiple = true, additionalFileExtensions = [] }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const token = useSelector((s) => s[props.TOKEN])
  const [isProcessing, setIsProcessing] = useState(false)

  const processNewAssets = async (assets) => {
    if (assets.length > 0) {
      setIsProcessing(true)
      const assetKeyList = []
      for (const asset of assets) {
        const { status, response } = await call(token, routes.get_location_asset_upload_url, {
          fileMeta: { name: asset.name, type: asset.type, size: asset.size },
        })
        if (status && response) {
          const { key, presignedURL } = response
          const uploadStatus = await s3Upload(presignedURL, asset)
          if (!uploadStatus) return false
          assetKeyList.push(key)
        } else {
          toast(TYPE.ERROR, translation.dashboard.admin.locations.upsert.error.upload)
        }
      }
      callback([...existingAssetKeys, ...assetKeyList])
      setIsProcessing(false)
    }
  }

  const removeAssetKey = (key) => {
    if (multiple === false) {
      callback(null)
    } else {
      const newAssetKeys = existingAssetKeys.filter((assetKey) => assetKey !== key)
      callback(newAssetKeys)
    }
  }

  return (
    <StyledImageVideoManager>
      <CustomDragAndDrop
        hasFiles={existingAssetKeys.length > 0}
        title={title}
        hint={hint}
        onFileCallback={processNewAssets}
        maxFileSizeInBytes={100000000}
        maxFileSizeExceededToast={translation.dashboard.admin.locations.upsert.maxFileSizeExceededToast}
        multiple={multiple}
        allowedFileExtensions={['jpeg', 'jpg', 'png', ...additionalFileExtensions]}
      />
      {isProcessing && <Processing>{translation.dashboard.admin.locations.upsert.processingAssetLabel}</Processing>}
      <Slider assetKeys={existingAssetKeys} remove={removeAssetKey} />
    </StyledImageVideoManager>
  )
}

export default ImageVideoManager
