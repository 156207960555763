import { getUUID, googleAPIKey } from '../../../../utility'
import GoogleMapReact from 'google-map-react'
import markerIconImage from '../../../../assets/image/generic/map-marker.svg'
import { StyledGoogleMap, ImageWrapper, StyledMarker, MarkerIcon } from './GoogleMap.Styled.js'
import styles from './styles'

const Marker = ({ name }) => {
  return (
    <StyledMarker>
      <ImageWrapper>
        <MarkerIcon src={markerIconImage} alt={name} />
      </ImageWrapper>
    </StyledMarker>
  )
}

const GoogleMap = ({ centerCoordinates, locations, width, height }) => {
  return (
    <StyledGoogleMap width={width} height={height}>
      <GoogleMapReact options={{ styles }} bootstrapURLKeys={{ key: googleAPIKey }} center={centerCoordinates} defaultZoom={9.5}>
        {locations &&
          locations.map(({ geoLocation, name }) => {
            return <Marker key={getUUID()} lat={geoLocation.coordinates[1]} lng={geoLocation.coordinates[0]} name={name} />
          })}
      </GoogleMapReact>
    </StyledGoogleMap>
  )
}

export default GoogleMap
