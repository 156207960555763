import { useSelector } from 'react-redux'
import { s3StorageBucketPublicURLPrefix } from '../../../../utility'
import props from '../../../../redux/props'

const { default: styled } = require('styled-components')

const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const RemoveButton = styled.div`
  color: ${({ theme }) => theme.color.font.red};
  cursor: pointer;
`

const Item = ({ data, remove }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledItem key={data.key}>
      <a href={`${s3StorageBucketPublicURLPrefix}${data.key}`} target="_blank" rel="noreferrer">
        {data.name}
      </a>
      <RemoveButton onClick={remove}>{translation.dashboard.admin.familyCenter.upsert.moreInfoPDFManager.remove}</RemoveButton>
    </StyledItem>
  )
}

export default Item
