import { Image, Text } from '../Accordion.Styled'
import book from '../../../../assets/image/story/accordion/rememberSolferino/book.jpg'
import { translation } from '../../../../translations/de'
import { getUUID } from '../../../../utility'

const RememberSolferino = () => {
  return (
    <div>
      <Image src={book} />
      {translation.story.accordion.rememberSolferino.text.map((t) => (
        <Text key={getUUID()}>{t}</Text>
      ))}
    </div>
  )
}

export default RememberSolferino
