import styled from 'styled-components'

export const StyledSlider = styled.div`
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  position: relative;
  height: 200px;
`

export const Wrapper = styled.div`
  width: fit-content;
  min-width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  gap: 5px;
`

export const ItemWrapper = styled.div`
  width: fit-content;
  display: inline-block;
  height: 100%;
  position: relative;
`

export const ImageWrapper = styled.div`
  width: 250px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    .remove {
      display: flex;
    }
  }
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const Remove = styled.div`
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  padding: 5px;
  background-color: ${({ theme }) => theme.color.background.red};
  border-radius: 5px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.color.font.secondary};
`
