import styled from 'styled-components'

export const StyledJob = styled.div`
  text-decoration: none;
  background-color: ${({ theme }) => theme.color.background.thirdary};
  padding: 10px 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    flex-direction: column;
    align-items: flex-start;
    border-radius: 25px;
  }
`

export const Field = styled.div`
  ${({ bold, theme }) =>
    !bold &&
    `
    font-family: light;
    font-size: ${theme.font.size.sm};   
  `}
`

export const MetaData = styled.div`
  display: flex;
  gap: 10px;
`

export const Separator = styled.div`
  font-family: light;
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const Location = styled.div`
  background-color: ${({ theme }) => theme.color.background.primary};
  border-radius: 30px;
  font-size: ${({ theme }) => theme.font.size.sm};
  text-align: center;
  padding: 5px 10px;
  width: 25%;
  height: fit-content;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
    box-sizing: border-box;
  }
`

export const Info = styled.div`
  width: 60%;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
  }
`

export const CTA = styled.a`
  display: flex;
  height: 30px;
  width: 40px;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  &:hover {
    background-image: url(${({ srcHover }) => srcHover});
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    margin-left: auto;
    margin-right: auto;
  }
`
