import styled from 'styled-components'

export const StyledArrow = styled.div`
  position: absolute;
  left: -70px;
  opacity: 1;
`

export const Image = styled.img`
  height: 40px;
  transition: opacity 0.2s ease-in-out;
`
