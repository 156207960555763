import { Route, Routes, Navigate } from 'react-router-dom'
import Admin from './Admin/Admin'
import Manager from './Manager/Manager.js'

const Dashboard = () => {
  return (
    <Routes>
      <Route path={'admin/*'} element={<Admin />} />
      <Route path={'manager/*'} element={<Manager />} />
      <Route path={'*'} element={<Navigate replace to="/" />} />
    </Routes>
  )
}

export default Dashboard
