import styled from 'styled-components'

export const StyledAccordion = styled.div`
  margin: 50px 0px;
`

export const StyledAccordionItem = styled.div``

export const Header = styled.div`
  position: relative;
  cursor: pointer;
  padding: 10px 0px;
  &:hover {
    padding: 10px 20px;
    background-color: ${({ theme }) => theme.color.background.blue};
    * {
      color: white;
    }
    .indicator {
      filter: invert(1);
    }
  }
`
export const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
`
export const Subtitle = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
`
export const Content = styled.div`
  padding: 20px 0;
`

export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: justify;
`

export const Image = styled.img`
  width: 100%;
`

export const Indicator = styled.img`
  height: 30px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`
