import styled from 'styled-components'

export const StyledWave = styled.div`
  width: 100%;
  overflow: hidden;
  line-height: 0;
  ${({ reverse }) => reverse && `transform: rotate(180deg);`}
  svg {
    z-index: 1;
    display: block;
    width: ${({ width }) => width};
    height: 125px;
    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      height: 25px;
    }
  }
  .shape-fill {
    fill: ${({ theme, backgroundColor }) => backgroundColor || theme.color.background.blue};
  }
`
