import { StyledCarousel } from './Carousel.Styled'
import Core from './Core'

const Carousel = ({ height, ...props }) => {
  return (
    <StyledCarousel height={height}>
      <Core {...props} />
    </StyledCarousel>
  )
}

export default Carousel
