import { DragAndDrop } from '@think-internet/ui-components'
import { ItemList, Processing, StyledMoreInfoPDFManager } from './MoreInfoPDFManager.Styled'
import toast, { TYPE } from '../../../../toast'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { s3Upload } from '../../../../fetch'
import call from '../../../../interface/functional'
import routes from '../../../../redux/routes'
import Loading from '../../../SubComponents/Loading/Loading'
import Item from './Item'

const Status = {
  WAITING: 'WAITING',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
}

const MoreInfoPDFManager = ({ data, callback }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const token = useSelector((s) => s[props.TOKEN])
  const [status, setStatus] = useState(Status.WAITING)

  const creatingZIP = async (pdfNameKeyList) => {
    if (pdfNameKeyList.length === 0) return callback({ pdfNameKeyList: [], zip: null })
    setStatus(Status.PROCESSING)
    const { status, response } = await call(token, routes.get_family_center_zip, {
      pdfNameKeyList,
      familyCenterName: data.name,
    })
    if (status && !!response) {
      callback({ pdfNameKeyList, zip: response })
    } else {
      toast(TYPE.ERROR, translation.dashboard.admin.familyCenter.upsert.error.upload)
    }
  }

  const processFiles = async (files) => {
    if (files.length > 0) {
      setStatus(Status.UPLOADING)
      const pdfNameKeyList = []
      for (const pdf of files) {
        const { status, response } = await call(token, routes.get_family_center_pdf_upload_url, {
          fileMeta: { name: pdf.name, type: pdf.type, size: pdf.size },
        })
        if (status && response) {
          const { key, presignedURL } = response
          const uploadStatus = await s3Upload(presignedURL, pdf)
          if (!uploadStatus) return false
          pdfNameKeyList.push({ key, name: pdf.name })
        } else {
          toast(TYPE.ERROR, translation.dashboard.admin.familyCenter.upsert.error.upload)
        }
      }
      if (Array.isArray(data.moreInfoPDFNameKeyList)) {
        await creatingZIP([...data.moreInfoPDFNameKeyList, ...pdfNameKeyList])
      } else {
        await creatingZIP(pdfNameKeyList)
      }
      setStatus(Status.WAITING)
    }
  }

  const remove = (key) => async () => {
    const pdfNameKeyList = data.moreInfoPDFNameKeyList.filter((pdfNameKey) => pdfNameKey.key !== key)
    await creatingZIP(pdfNameKeyList)
    setStatus(Status.WAITING)
  }

  return (
    <StyledMoreInfoPDFManager>
      {translation.dashboard.admin.familyCenter.upsert.moreInfoPDFManager.title}
      {status === Status.WAITING && (
        <DragAndDrop
          title={translation.dashboard.admin.familyCenter.upsert.moreInfoPDFManager.addFile}
          hint={translation.dashboard.admin.familyCenter.upsert.moreInfoPDFManager.hint}
          onFileCallback={processFiles}
          maxFileSizeInBytes={10000000}
          maxFileSizeExceededToast={translation.dashboard.admin.familyCenter.upsert.maxFileSizeExceededToast}
          multiple={true}
          allowedFileExtensions={['pdf']}
        />
      )}
      {status === Status.UPLOADING && <Processing>{translation.dashboard.admin.familyCenter.upsert.moreInfoPDFManager.status.uploading}</Processing>}
      {status === Status.PROCESSING && (
        <Processing>{translation.dashboard.admin.familyCenter.upsert.moreInfoPDFManager.status.processing}</Processing>
      )}
      {status !== Status.WAITING && <Loading />}
      {!!data.moreInfoPDFNameKeyList && (
        <ItemList>
          {data.moreInfoPDFNameKeyList.map((pdfNameKey) => (
            <Item key={pdfNameKey.key} data={pdfNameKey} remove={remove(pdfNameKey.key)} />
          ))}
        </ItemList>
      )}
    </StyledMoreInfoPDFManager>
  )
}

export default MoreInfoPDFManager
