import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import {
  StyledLocation,
  Index,
  GenericInfo,
  Colorizer,
  Label,
  TrafficLightWrapper,
  VirtualViewingLink,
  GoToToggle,
  IndexWrapper,
} from './Location.Styled'
import TrafficLight from '../../../../Kita/TrafficLight/TrafficLight'
import { setLocal } from '../../../../../redux/action/local'

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  var R = 6371
  var dLat = toRad(lat2 - lat1)
  var dLon = toRad(lon2 - lon1)
  lat1 = toRad(lat1)
  lat2 = toRad(lat2)

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c
  return d
}

const toRad = (Value) => {
  return (Value * Math.PI) / 180
}

const Location = ({ data, geoLocation, index, mode }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const dispatch = useDispatch()

  const getDistance = () => {
    if (data.distance) return data.distance
    if (geoLocation && geoLocation.lat && geoLocation.lng) {
      return calculateDistance(geoLocation.lat, geoLocation.lng, data.geoLocation.coordinates[1], data.geoLocation.coordinates[0]).toFixed(1)
    }
    return false
  }

  const showDetailView = (location) => () => dispatch(setLocal(props.KITA_DETAIL_LOCATION, { mode, location }))

  return (
    <StyledLocation>
      <IndexWrapper>
        <Index>{index + 1}</Index>
      </IndexWrapper>
      <GenericInfo>
        <Colorizer color="white">
          <Colorizer color="lightorange">
            <Colorizer color="orange">
              <Label>{data.name}</Label>
            </Colorizer>
            {getDistance() && (
              <Label>
                {getDistance()}
                {translation.kitapiloot.results.location.distance}
              </Label>
            )}
          </Colorizer>
          <Label>
            {translation.kitapiloot.results.location.city}
            {data.city}
          </Label>
        </Colorizer>
      </GenericInfo>
      <TrafficLightWrapper>
        <TrafficLight availability={data.availability} />
      </TrafficLightWrapper>
      <VirtualViewingLink href={data.virtualViewingLink} target="_blank" />
      <GoToToggle onClick={showDetailView(data)} />
    </StyledLocation>
  )
}

export default Location
