import Layout from '../Layout/Layout'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import { StyledDownload, Content, Headline, Subheadline, ImageWrapper, Image, CTA } from './Download.Styled'
import { getUUID } from '../../utility'
import image from '../../assets/image/download/image.svg'
import Animated from '../SubComponents/Animated/Animated'

const Download = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  const triggerDownload = () => {
    window.open('https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.drk-kita.storage/CUSTOM_STATIC_ASSETS/Dein_Infomaterial.zip')
    window.location = '/infomaterial-danke'
  }

  return (
    <Layout authRequired={false}>
      <StyledDownload>
        <ImageWrapper>
          <Animated animation="fadeIn" duration={2.5}>
            <Image src={image} />
          </Animated>
        </ImageWrapper>
        <Content>
          <Headline duration="2.5" animation="fadeIn" delay={3}>
            {translation.download.headline}
          </Headline>
          <Subheadline duration="5" animation="fadeIn">
            {translation.download.subheadline.map((s, i) => (
              <span key={getUUID()} style={{ fontFamily: i === 1 ? 'bold' : 'regular' }}>
                {s}
              </span>
            ))}
          </Subheadline>
          <CTA onClick={triggerDownload}>{translation.download.cta}</CTA>
        </Content>
      </StyledDownload>
    </Layout>
  )
}

export default Download
