import { StyledList, CustomAccordion, Headline } from './List.Styled'
import UserContent from './UserContent/UserContent'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { list } from '../../../../../redux/action/crud'

const List = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const users = useSelector((s) => s[props.USER])
  const dispatch = useDispatch()

  useEffect(() => {
    if (!users) dispatch(list(props.USER))
  }, [users, dispatch])

  if (!Array.isArray(users)) return <></>
  return (
    <StyledList>
      <Headline>{translation.dashboard.admin.users.list.headline}</Headline>
      <CustomAccordion
        items={users.map((u) => ({
          title: `${u.firstname} ${u.lastname}`,
          content: <UserContent data={u} />,
        }))}
      />
    </StyledList>
  )
}

export default List
