import styled from 'styled-components'

export const StyledPhilosophieAndConcept = styled.div`
  margin-top: 100px;
`

export const SplitSectionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 100px;
`

export const SplitSection = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  width: 100%;

  flex-direction: ${({ invert }) => (invert ? 'row' : 'row-reverse')};

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`

export const ImageWrapper = styled.div`
  width: 60%;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    text-align: center;
  }
`

export const Content = styled.div`
  width: 40%;
  text-align: ${({ invert }) => (invert ? 'right' : 'left')};

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const Image = styled.img`
  height: 500px;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 200px;
    margin-bottom: 20px;
  }
`

export const Headline = styled.div`
  margin-top: 20px;
  font-family: bold;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.xlg};

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.md};
    margin-top: 10px;
    text-align: center;
  }
`

export const SubHeadline = styled.div`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.lg};

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.sm};
    text-align: center;
  }
`
export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  text-align: justify;
  margin-top: 25px;
`

export const CTA = styled.a`
  height: 30px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.color.red};
  width: 30%;
  margin: auto;
  cursor: pointer;
  padding: 5px 10px;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-top: 50px;
    width: 80%;
  }
`

export const CTAText = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.font.darkBlue};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 15px;
  }
`

export const CTAArrow = styled.img`
  width: 20px;
`
