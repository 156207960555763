import styled from 'styled-components'

export const StyledUserContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: right;
`

export const CTASection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
`

export const Headline = styled.div`
  font-family: 'bold';
`
