import styled from 'styled-components'

export const StyledMoreInfoPDFManager = styled.div`
  width: 100%;
  border: thin solid ${({ theme }) => theme.color.border.orange};
  padding: 10px;
  border-radius: 15px;
`
export const Processing = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const ItemList = styled.div`
  margin-top: 10px;
`
