import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { Prop, List, StyledAdditionalInfoSelection, Headline } from './AdditionalInfoSelection.Styled'

const AdditionalInfoSelection = ({ additionalProperty = null, set }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  const toggle = (key) => () => set(additionalProperty === key ? null : key)

  return (
    <StyledAdditionalInfoSelection>
      <Headline>{translation.kita.detail.familyCenter.headline}</Headline>
      <List>
        {Object.keys(translation.kita.detail.familyCenter.properties).map((key) => (
          <Prop key={key} selected={additionalProperty === key} onClick={toggle(key)}>
            {translation.kita.detail.familyCenter.properties[key]}
          </Prop>
        ))}
      </List>
    </StyledAdditionalInfoSelection>
  )
}

export default AdditionalInfoSelection
