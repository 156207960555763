import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import Layout from '../Layout/Layout'
import { Headline, StyledDataProtection, Subheadline, P, Link, Strong, UL, LI } from './DataProtection.Styled'

const DataProtection = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <Layout authRequired={false}>
      <StyledDataProtection>
        <Container>
          <Headline>{translation.dataProtection.headline}</Headline>
          <P>Allgemeines</P>
          <P>
            Wir, die <Strong>DRK Kindertageseinrichtungen im Kreis Coesfeld gGmbH</Strong>, nehmen den Schutz Ihrer personenbezogenen Daten und die
            diesem Schutz dienenden gesetzlichen Verpflichtungen sehr ernst. Unsere Datenschutzerklärung erklärt Ihnen deshalb ausführlich, welche
            sog. personenbezogenen Daten von uns bei der Nutzung der Website{' '}
            <Link href="https://www.drk-kita-ggmbh.de" target="_blank">
              www.drk-kita-ggmbh.de
            </Link>{' '}
            verarbeitet werden.
          </P>
          <P>
            <Strong>Name und Anschrift des Verantwortlichen</Strong>
          </P>

          <P>
            Verantwortlicher im Sinne der Datenschutzgrundverordnung (DSGVO), des Bundesdatenschutzgesetzes (BDSG) sowie sonstiger
            datenschutzrechtlicher Vorgaben ist die
          </P>

          <P>
            DRK Kindertageseinrichtungen im Kreis Coesfeld gGmbH
            <br />
            Bahnhofstraße 128 in 48653 Coesfeld
            <br />
            Tel: 02541 9442-1000
            <br />
            E-Mail: kgst(at)drk-coe.de
          </P>
          <P>Vertretungsberechtigter: Christoph Schlütermann, Vorstand</P>

          <P>Folgend „Verantwortlicher“ oder „wir“ genannt.</P>
          <P>
            <Strong>Unseren Datenschutzbeauftragten erreichen Sie unter:</Strong>
          </P>
          <Link href="mailto:datenschutz@drk-bbs.de">datenschutz(at)drk-bbs.de</Link>

          <P>oder postalisch unter</P>

          <P>
            DRK Landesverband Westfalen-Lippe
            <br />
            Betriebswirtschaftliche Beratungs- und Service-GmbH
            <br />
            Hammer Str. 138-140
            <br />
            48153 Münster
          </P>

          <P>
            Bitte beachten Sie, dass Sie über Links auf unserer Website zu anderen Internetseiten gelangen können, die nicht von uns, sondern von
            Dritten betrieben werden. Solche Links werden von uns entweder eindeutig gekennzeichnet oder sind durch einen Wechsel in der Adresszeile
            Ihres Browsers erkennbar. Für die Einhaltung der datenschutzrechtlichen Bestimmungen und einen sicheren Umgang mit Ihren personenbezogenen
            Daten auf diesen von Dritten betriebenen Internetseiten sind wir nicht verantwortlich.
          </P>
          <P>
            <Strong>Beschwerderecht</Strong>
          </P>

          <P>
            Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen den Datenschutz verstößt, steht ihnen
            das Recht auf Beschwerde bei einer Datenschutzaufsichtsbehörde gem. Art. 77 DSGVO in Verbindung mit § 19 BDSG zu.
          </P>

          <P>Unsere Datenschutzaufsichtsbehörde erreichen Sie unter:</P>

          <P>
            Landesbeauftragte für Datenschutz und Informationsfreiheit
            <br />
            Nordrhein-Westfalen
            <br />
            Postfach 20 04 44
            <br />
            40102 Düsseldorf
          </P>

          <P>
            Tel.: 0211/38424-0
            <br />
            Fax: 0211/38424-10
            <br />
            E-Mail: <Link href="mailto:poststelle@ldi.nrw.de">poststelle@ldi.nrw.de</Link>
          </P>
          <P>
            <Strong>Allgemeines zur Datenverarbeitung</Strong>
          </P>

          <P>
            Wir erheben und verwenden personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies zur Bereitstellung einer funktionsfähigen
            Website sowie unserer Inhalte und Leistungen erforderlich ist. Wir verarbeiten personenbezogene Daten nur, soweit dies gesetzlich zulässig
            ist.
          </P>

          <P>Rechtsgrundlage für die Verarbeitung personenbezogener Daten ist der Art. 6 DSGVO, hier sind u.a. folgende Rechtsgrundlagen genannt:</P>

          <UL>
            <LI>die Verarbeitung auf Basis einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO</LI>
            <LI>die Verarbeitung für die Erfüllung eines Vertrags Art. 6 Abs. 1 lit. b DSGVO</LI>
            <LI>die Verarbeitung zur Erfüllung einer rechtl. Verpflichtung Art. 6 Abs. 1 lit. c DSGVO</LI>
            <LI>die Verarbeitung auf Basis eines berechtigten Interesses Art. 6 Abs. 1 lit. f DSGVO</LI>
          </UL>
          <Subheadline>Datenverarbeitung beim Besuch unserer Website</Subheadline>

          <P>
            Wenn Sie unsere Website aufrufen und rein informatorisch nutzen, werden automatisch personenbezogene Daten erhoben. Hierbei handelt es
            sich um folgende Informationen, die Ihr Internetbrowser an unseren Webserver übermittelt:
          </P>

          <P>
            <UL>
              <LI>IP-Adresse des anfragenden Rechners</LI>
              <LI>Datum und Uhrzeit Ihres Besuches</LI>
              <LI>Name und URL der abgerufenen Datei</LI>
              <LI>Website, von der aus der Zugriff erfolgt (Referrer-URL)</LI>
              <LI>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie</LI>
              <LI>der Name Ihres Access-Providers</LI>
            </UL>
          </P>

          <P>
            Die Informationen werden von Ihrem Browser übermittelt, sofern Sie diesen nicht so konfiguriert haben, dass die Übermittlung der
            Informationen unterdrückt wird.
          </P>

          <P>Diese Daten werden anonymisiert gespeichert. Nutzerprofile mit Bezug zu bestimmten oder bestimmbaren Personen werden nicht erstellt.</P>

          <P>
            Die Verarbeitung dieser personenbezogenen Daten erfolgt aus Zwecken der Funktionsfähigkeit und Optimierung der Website, sowie zur
            Gewährleistung der Sicherheit unserer informationstechnischen Systeme. Hierin liegt zugleich unser berechtigtes Interesse, weswegen die
            Verarbeitung nach Art. 6 Abs. 1 lit. f DSGVO zulässig ist.
          </P>
          <Subheadline>Cookies</Subheadline>

          <P>
            Cookies sind kleine Dateien mit individuellen Kennungen (IDs), die eine Website auf dem Endgerät des Nutzers setzt und speichert. Mithilfe
            von Cookies können Website-Betreiber (First-Party-Cookies) oder Dritte (Third-Party-Cookies) dann bestimmte Informationen von dem Endgerät
            des Nutzers auslesen. Individuellen Kennungen ermöglichen es, den Nutzer wiederzuerkennen, teilweise über unterschiedliche Websites und
            verschiedene Geräte hinweg.
          </P>

          <P>
            Cookies können für den Betrieb einer Website erforderlich sein, z.B. wenn hierüber der Inhalt des Warenkorbs in einem Onlineshop für einen
            späteren Besuch der Seite gespeichert wird.
          </P>

          <P>
            Andere Cookies sind hingegen nicht für den Betrieb einer Website erforderlich, z.B. wenn hierüber das Surfverhalten von Nutzern über
            mehrere Seiten hinweg nachverfolgt wird, um interessengerechte Werbung platzieren zu können.
          </P>

          <P>
            Bei erforderlichen Cookies liegt die Verarbeitung Ihrer personenbezogenen Daten gem. Art. 6 Abs. 1 lit. f DSGVO in unserem berechtigten
            Interesse, Ihnen unsere Website mit vollem Leistungsumfang anbieten zu können.
          </P>

          <P>Für nicht erforderliche Cookies benötigen wir Ihre Einwilligung.</P>

          <P>
            Hierzu blenden wir, sofern wir nicht erforderliche Cookies verwenden, beim ersten Besuch der Website ein Consent-Banner ein und geben
            ihnen die Möglichkeit, in die Verwendung der unterschiedlichen Cookie-Arten einzuwilligen. Diese Einwilligung ist freiwillig und für die
            Nutzung unserer Website nicht erforderlich. Sie können Ihre Einwilligung zur Verwendung der Cookies gegebenenfalls jederzeit widerrufen.
          </P>

          <P>Sofern Sie in die Verwendung von Cookies eingewilligt haben, verarbeiten wir die Daten gem. Art. 6 Abs. 1 lit. a DSGVO.</P>
          <Subheadline>Kontaktaufnahme per E-Mail oder Telefon</Subheadline>

          <P>
            Wenn Sie uns eine E-Mail schreiben oder anrufen, werden die von Ihnen dort angegebenen personenbezogenen Daten (Name, E-Mail-Adresse,
            IP-Adresse, Datum und Uhrzeit der Eingabe, Name, Mitteilungstext, etc.) von uns verarbeitet. Diese Informationen werden im Fall einer
            E-Mail von Ihrem E-Mail-Client übermittelt und in unseren informationstechnischen Systemen gespeichert.
          </P>

          <P>
            Die Datenverarbeitung dient der Beantwortung Ihrer Anfrage und der Gewährleistung der Sicherheit unserer informationstechnischen Systeme.
            Diese Verarbeitungen sind rechtmäßig, weil die Beantwortung Ihrer Anfrage sowie der Schutz unserer informationstechnischen Systeme
            berechtigte Interessen im Sinne des Art. 6 Abs. 1 lit. f DSGVO darstellen. In Fällen, in denen Ihre Anfrage auf den Abschluss eines
            Vertrags oder eines bestehenden Vertrags abzielt, findet die Verarbeitung auf der Grundlage von Art. 6 Abs. 1 lit. b DSGVO statt.
          </P>

          <P>Die personenbezogenen Daten werden grds. nur solange gespeichert, wie dies zur Beantwortung Ihrer Anfrage erforderlich ist.</P>

          <P>
            Bitte beachten Sie, dass generell nicht ausgeschlossen werden kann, dass unverschlüsselte E-Mails von Unbefugten während der Übertragung
            mitgelesen werden können. Wir raten Ihnen daher vom Versand unverschlüsselter E-Mails ab, sofern Sie uns personenbezogene oder sensible
            Daten mitteilen möchten. Nutzen Sie im Zweifel den Postweg oder rufen Sie uns an.
          </P>
          <Subheadline>Kontaktaufnahme per Onlineformular</Subheadline>

          <P>
            Darüber hinaus bieten wir auf unserer Website verschiedene Onlineformulare an, über welche Sie mit uns in Kontakt treten können. Alle
            Onlineformulare senden die Interessenten- und Kundendaten über eine HTTPS-verschlüsselte Verbindung.
          </P>

          <Subheadline>Kontaktformular</Subheadline>

          <P>Wir bieten ein allgemeines Kontaktformular an, welches Sie für Anfragen und Anregungen aller Art nutzen können.</P>

          <P>
            Die hierbei eingegebenen Daten werden dabei von uns verarbeitet. Diese Daten sind für die Bearbeitung Ihrer Anfrage und für die Sicherheit
            unserer informationstechnischen Systeme erforderlich. Hierin liegt unser berechtigtes Interesse gem. Art. 6 Abs. 1 lit f DSGVO.
          </P>

          <P>
            Die Daten dienen ausschließlich der Beantwortung Ihrer Anfrage und werden gelöscht, sobald die Bearbeitung Ihres Anliegens dies nicht mehr
            erfordert.
          </P>
          <Subheadline>Bewerbungen</Subheadline>

          <P>
            Wenn Sie sich bei uns bewerben, verarbeiten wir Ihre übermittelten Bewerberdaten (diese umfassen regelmäßig Personalien, Zeugnisse,
            weitere Nachweise, Lebenslauf, Foto) zur Vertragsanbahnung gem. Art. 6 Abs. 1 lit. b DSGVO und zur Entscheidung über die Begründung eines
            Beschäftigungsverhältnisses gem. Art. 88 DSGVO i. V. m. § 26 BDSG.
          </P>

          <P>
            Um an einem Bewerbungsverfahren teilzunehmen, ist die Übermittlung der oben genannten Daten erforderlich. Sie werden für die Durchführung
            des Bewerbungsverfahrens benötigt. Wenn Sie die erforderlichen Daten nicht angeben, kann Ihre Bewerbung nicht berücksichtigt werden.
          </P>

          <P>
            Sollten Sie im Bewerbungsverfahren nicht berücksichtigt werden, löschen wir Ihre personenbezogenen Daten 6 Monate nach Abschluss Ihres
            Bewerbungsverfahrens. Diese Löschfrist ermöglicht es uns, gegen uns in dieser Zeit geltend gemachte Ansprüche, z.B. aus dem Allgemeinen
            Gleichstellungsgesetz, abzuwehren. Die Verarbeitung Ihrer personenbezogenen Daten in diesem Zeitraum basiert damit auf Art. 6 Abs. 1 lit.
            f DSGVO.
          </P>
          <Subheadline>Rechte der betroffenen Person</Subheadline>

          <P>
            Nach der Datenschutz-Grundverordnung stehen Ihnen folgende Rechte zu: Werden Ihre personenbezogenen Daten verarbeitet, so haben Sie das
            Recht, Auskunft über die zu Ihrer Person gespeicherten Daten zu erhalten (Art. 15 DSGVO).
          </P>

          <P>Sollten unrichtige personenbezogene Daten verarbeitet werden, steht Ihnen ein Recht auf Berichtigung zu (Art. 16 DSGVO).</P>

          <P>
            Liegen die gesetzlichen Voraussetzungen vor, so können Sie die Löschung oder Einschränkung der Verarbeitung verlangen sowie Widerspruch
            gegen die Verarbeitung einlegen (Art. 17, 18 und 21 DSGVO).
          </P>

          <P>
            Wenn Sie in die Datenverarbeitung eingewilligt haben oder ein Vertrag zur Datenverarbeitung besteht und die Datenverarbeitung mithilfe
            automatisierter Verfahren durchgeführt wird, steht Ihnen gegebenenfalls ein Recht auf Datenübertragbarkeit zu (Art. 20 DSGVO).
          </P>

          <P>Außerdem haben Sie das Recht auf die Beschwerde bei einer Aufsichtsbehörde (Art. 77 DSGVO).</P>

          <P>
            Sollten Sie von Ihren oben genannten Rechten Gebrauch machen, prüft unser Unternehmen, ob die gesetzlichen Voraussetzungen hierfür erfüllt
            sind.
          </P>
          <Subheadline>Automatisierte Entscheidungsfindung</Subheadline>

          <P>Automatisierte Entscheidungen im Einzelfall einschließlich Profiling erfolgen nicht.</P>

          <Subheadline>Datenlöschung und Speicherdauer</Subheadline>

          <P>
            Grundsätzlich erfolgt eine Löschung der Daten, sobald der Zweck ihrer Erhebung erfüllt wurde. Einer Löschung von Daten können jedoch
            gesetzliche Aufbewahrungsfristen entgegenstehen. In diesem Fall dürfen die Daten erst nach Ablauf der Frist vernichtet bzw. gelöscht
            werden.
          </P>
          <P>Einzelne Informationen zur Datenlöschung und Speicherdauer entnehmen Sie den in dieser Erklärung aufgeführten Verarbeitungen.</P>

          <Subheadline>Übermittlung von personenbezogenen Daten an ein Drittland</Subheadline>

          <P>
            Eine Übermittlung in ein Drittland oder an eine internationale Organisation findet vorbehaltlich weiterer Bestimmungen dieser
            Datenschutzerklärung grds. nicht statt.
          </P>
          <Subheadline>Widerrufsrecht bei Einwilligung</Subheadline>

          <P>
            Falls eine Verarbeitung Ihrer personenbezogenen Daten auf einer entsprechenden Einwilligung von Ihnen erfolgt, können Sie die Einwilligung
            jederzeit gemäß Art. 7 Abs. 3 S. 4 DSGVO. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung wird
            hiervon nicht berührt. Der Widerruf gilt immer nur für die nach dem Widerruf geplante Verarbeitung. Der Widerruf ist formlos per Post oder
            E-Mail möglich. Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, eine andere
            (gesetzliche) Grundlage gestattet dies. Erfolgt jedoch ein Widerruf und es liegt kein anderer Erlaubnistatbestand vor, so müssen gemäß
            Art. 17 Abs. 2 lit. b DSGVO die personenbezogenen Daten auf Ihren Wunsch hin unverzüglich gelöscht werden. Der Widerruf kann formfrei
            erfolgen und ist zu richten an:
          </P>

          <P>
            E-Mail: <Link href="mailto:kgst@drk-coe.de">kgst(at)drk-coe.de</Link>
          </P>
          <Subheadline>Online-Meetings, Telefonkonferenzen und Webinare via „Zoom“</Subheadline>

          <Subheadline>Zweck der Verarbeitung</Subheadline>

          <P>
            Wir nutzen das Tool „Zoom“, um Telefonkonferenzen, Online-Meetings, Videokonferenzen und/oder Webinare durchzuführen (nachfolgend:
            „Online-Meetings“). „Zoom“ ist ein Service der Zoom Video Communications, Inc., die ihren Sitz in den USA hat.
          </P>

          <P>
            <Strong>Hinweis:</Strong> Soweit Sie die Internetseite von „Zoom“ aufrufen, ist der Anbieter von „Zoom“ für die Datenverarbeitung
            verantwortlich. Ein Aufruf der Internetseite ist für die Nutzung von „Zoom“ jedoch nur erforderlich, um sich die Software für die Nutzung
            von „Zoom“ herunterzuladen.
          </P>

          <P>
            Sie können „Zoom“ auch nutzen, wenn Sie die jeweilige Meeting-ID und ggf. weitere Zugangsdaten zum Meeting direkt in der „Zoom“-App
            eingeben.
          </P>

          <P>
            Wenn Sie die „Zoom“-App nicht nutzen wollen oder können, dann sind die Basisfunktionen auch über eine Browser-Version nutzbar, die Sie
            ebenfalls auf der Website von „Zoom“ finden.
          </P>

          <Subheadline>Welche Daten werden verarbeitet?</Subheadline>

          <P>
            Bei der Nutzung von „Zoom“ werden verschiedene Datenarten verarbeitet. Der Umfang der Daten hängt dabei auch davon ab, welche Angaben zu
            Daten Sie vor bzw. bei der Teilnahme an einem „Online-Meeting“ machen.
          </P>

          <Subheadline>Folgende personenbezogene Daten sind Gegenstand der Verarbeitung:</Subheadline>

          <P>
            <Strong>Angaben zum Benutzer:</Strong> Vorname, Nachname, Telefon (optional), E-Mail-Adresse, Passwort (wenn „Single-Sign-On“ nicht
            verwendet wird), Profilbild (optional), Abteilung (optional)
          </P>
          <P>
            <Strong>Meeting-Metadaten:</Strong> Thema, Beschreibung (optional), Teilnehmer-IP-Adressen, Geräte-/Hardware-Informationen
          </P>

          <P>
            Bei <Strong>Aufzeichnungen</Strong> (optional): MP4-Datei aller Video-, Audio- und Präsentationsaufnahmen, M4A-Datei aller Audioaufnahmen,
            Textdatei des Online-Meeting-Chats.
          </P>

          <P>
            Bei <Strong>Einwahl mit dem Telefon:</Strong> Angabe zur eingehenden und ausgehenden Rufnummer, Ländername, Start- und Endzeit. Ggf.
            können weitere Verbindungsdaten wie z.B. die IP-Adresse des Geräts gespeichert werden.
          </P>

          <P>
            <Strong>Text-, Audio- und Videodaten:</Strong> Sie haben ggf. die Möglichkeit, in einem „Online- Meeting“ die Chat-, Fragen- oder
            Umfragefunktionen zu nutzen. Insoweit werden die von Ihnen gemachten Texteingaben verarbeitet, um diese im „Online-Meeting“ anzuzeigen und
            ggf. zu protokollieren. Um die Anzeige von Video und die Wiedergabe von Audio zu ermöglichen, werden entsprechend während der Dauer des
            Meetings die Daten vom Mikrofon Ihres Endgeräts sowie von einer etwaigen Videokamera des Endgeräts verarbeitet. Sie können die Kamera oder
            das Mikrofon jederzeit selbst über die „Zoom“-Applikationen abschalten bzw. stummstellen.
          </P>

          <P>Um an einem „Online-Meeting“ teilzunehmen bzw. den „Meeting-Raum“ zu betreten, müssen Sie zumindest Angaben zu Ihrem Namen machen.</P>

          <Subheadline>Umfang der Verarbeitung</Subheadline>

          <P>
            Wir verwenden „Zoom“, um „Online-Meetings“ durchzuführen. Wenn wir „Online-Meetings“ aufzeichnen wollen, werden wir Ihnen das im Vorwege
            transparent mitteilen und – soweit erforderlich – um eine Zustimmung bitten. Die Tatsache der Aufzeichnung wird Ihnen zudem in der
            „Zoom“-App angezeigt.
          </P>

          <P>
            Wenn es für die Zwecke der Protokollierung von Ergebnissen eines Online-Meetings erforderlich ist, werden wir die Chatinhalte
            protokollieren. Das wird jedoch in der Regel nicht der Fall sein.
          </P>

          <P>
            Im Falle von Webinaren können wir für Zwecke der Aufzeichnung und Nachbereitung von Webinaren auch die gestellten Fragen von
            Webinar-Teilnehmenden verarbeiten.
          </P>

          <P>
            Wenn Sie bei „Zoom“ als Benutzer registriert sind, dann können Berichte über „Online-Meetings“ (Meeting-Metadaten, Daten zur
            Telefoneinwahl, Fragen und Antworten in Webinaren, Umfragefunktion in Webinaren) bis zu einem Monat bei „Zoom“ gespeichert werden.
          </P>

          <P>Eine automatisierte Entscheidungsfindung i.S.d. Art. 22 DSGVO kommt nicht zum Einsatz.</P>
          <Subheadline>Rechtsgrundlagen der Datenverarbeitung</Subheadline>

          <P>
            Soweit personenbezogene Daten von Beschäftigten der DRK Kindertageseinrichtungen im Kreis Coesfeld gGmbH verarbeitet werden, ist § 26 Abs.
            1 BDSG die Rechtsgrundlage der Datenverarbeitung. Sollten im Zusammenhang mitder Nutzung von „Zoom“ personenbezogene Daten nicht für die
            Begründung, Durchführung oder Beendigung des Beschäftigungsverhältnisses erforderlich, gleichwohl aber elementarer Bestandteil bei der
            Nutzung von „Zoom“ sein, so ist Art. 6 Abs. 1 lit. f) DSGVO die Rechtsgrundlage für die Datenverarbeitung. Unser Interesse besteht in
            diesen Fällen an der effektiven Durchführung von „Online-Meetings“.
          </P>

          <P>
            Im Übrigen ist die Rechtsgrundlage für die Datenverarbeitung bei der Durchführung von „Online-Meetings“ Art. 6 Abs. 1 lit. b) DSGVO,
            soweit die Meetings im Rahmen von Vertragsbeziehungen durchgeführt werden.
          </P>

          <P>
            Sollte keine vertragliche Beziehung bestehen, ist die Rechtsgrundlage Art. 6 Abs. 1lit. f) DSGVO. Auch hier besteht unser Interesse an der
            effektiven Durchführung von „On-line-Meetings“.
          </P>
          <Subheadline>Empfänger / Weitergabe von Daten</Subheadline>

          <P>
            Personenbezogene Daten, die im Zusammenhang mit der Teilnahme an „Online-Meetings“ verarbeitet werden, werden grundsätzlich nicht an
            Dritte weitergegeben, sofern sie nicht gerade zur Weitergabe bestimmt sind. Beachten Sie bitte, dass Inhalte aus „Online-Meetings“ wie
            auch bei persönlichen Besprechungstreffen häufig gerade dazu dienen, um Informationen mit Kunden, Interessenten oder Dritten zu
            kommunizieren und damit zur Weitergabe bestimmt sind.
          </P>

          <P>
            Weitere Empfänger: Der Anbieter von „Zoom“ erhält notwendigerweise Kenntnis von den o.g. Daten, soweit dies im Rahmen unseres
            Auftragsverarbeitungsvertrages mit „Zoom“ vorgesehen ist.
          </P>

          <Subheadline>Datenverarbeitung außerhalb der Europäischen Union</Subheadline>

          <P>
            „Zoom“ ist ein Dienst, der von einem Anbieter aus den USA erbracht wird. Eine Verarbeitung der personenbezogenen Daten findet damit auch
            in einem Drittland statt.
          </P>

          <P>Ein angemessenes Datenschutzniveau ist zum einen durch den Abschluss der sog. EU-Standardvertragsklauseln garantiert.</P>
          <P>
            <Link href="https://www.drk-kita-ggmbh.de/fileadmin/Kita_gmbh/Downloads/Datenenschutzhinweise_Zoom.pdf" target="_blank">
              Datenenschutzhinweise für Online-Meetings, Telefonkonferenzen und Webinare via „Zoom“ der DRK-Kindertageseinrichtungen im Kreis Coesfeld
              gGmbH als PDF.
            </Link>
          </P>
        </Container>
      </StyledDataProtection>
    </Layout>
  )
}

export default DataProtection
