import { Checkbox } from '@think-internet/ui-components'
import styled from 'styled-components'

export const StyledContactForm = styled.div`
  padding: 50px 0;
  z-index: 1;
  position: relative;
`

export const CTA = styled.div`
  padding: 10px 30px;
  border-radius: 30px;
  width: fit-content;
  background-color: ${({ theme }) => theme.color.background.darkBlue};
  margin: auto;
  cursor: pointer;
  color: ${({ theme }) => theme.color.font.secondary};
  text-transform: uppercase;
  margin-top: 25px;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`

export const CustomCheckbox = styled(Checkbox)`
  margin-top: 25px;
`
