import styled from 'styled-components'

export const StyledCard = styled.div`
  width: calc(20% - 20px);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  perspective: 1000px;
  &:hover {
    .content {
      transform: rotateY(180deg);
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  height: 200px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
`

export const IconWrapper = styled.div`
  text-align: center;
  position: absolute;
  width: 100%;
  height: 200px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-image: url(${({ bg }) => bg});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
`

export const Icon = styled.img`
  max-height: 125px;
  max-width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Title = styled.div`
  position: absolute;
  width: 100%;
  height: 200px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: ${({ theme }) => theme.color.background.primary};
  color: ${({ theme }) => theme.color.font.primary};
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.font.size.lg};
  border-radius: 10px;
  text-transform: uppercase;
`
