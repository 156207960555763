import { StyledLayout, CoreContent } from './Layout.Styled'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import Secure from './Secure/Secure'

const Layout = ({ children, className, hideHeader = false, authRequired = true, requiredLevel = null, mode = 'default' }) => {
  return (
    <StyledLayout className={className}>
      <Secure authRequired={authRequired} requiredLevel={requiredLevel}>
        {!hideHeader && <Header mode={mode} />}
        <CoreContent mode={mode} hideHeader={hideHeader}>
          {children}
        </CoreContent>
        <Footer mode={mode} />
      </Secure>
    </StyledLayout>
  )
}

export default Layout
