import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import call from '../../../../interface/functional'
import props from '../../../../redux/props'
import routes from '../../../../redux/routes'
import toast, { TYPE } from '../../../../toast'

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const useGetKitaResultsByDistrict = (districtUUID) => {
  const [data, setData] = useState(null)
  const translation = useSelector((s) => s[props.TRANSLATION])
  const previousDistrictUUID = usePrevious(districtUUID)

  useEffect(() => {
    const getResults = async () => {
      const payload = await call(null, routes.get_kita_results_by_district, { districtUUID })
      if (payload.status && Array.isArray(payload.response)) {
        if (payload.response.length === 0) {
          toast(TYPE.ERROR, translation.kitaoverview.results.error.noLocations)
        }
        setData(payload.response)
      } else {
        toast(TYPE.ERROR, translation.kitaoverview.results.error.internal)
      }
    }
    if ((!data || districtUUID !== previousDistrictUUID) && districtUUID) {
      getResults()
    }
  }, [districtUUID, data, translation, previousDistrictUUID])

  return data || null
}

export default useGetKitaResultsByDistrict
