import { StyledJobs, Content, ImageSection, Image, TextSection, Headline, Subheadline, Text, P, CTA, CTAWrapper } from './Jobs.Styled'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import image from '../../../assets/image/home/jobs/image.svg'
import Animated from '../Animated/Animated'
import { getUUID } from '../../../utility'

const Jobs = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledJobs>
      <Content>
        <ImageSection>
          <Animated animation="fadeInRight" duration={3}>
            <Image src={image} />
          </Animated>
        </ImageSection>
        <TextSection>
          <Headline>{translation.home.jobs.headline}</Headline>
          <Subheadline>{translation.home.jobs.subheadline}</Subheadline>
          <Text>
            {translation.home.jobs.text.map((t) => (
              <P key={getUUID()}>{t}</P>
            ))}
          </Text>
          <CTAWrapper>
            <CTA href="/bewerbung">{translation.home.jobs.cta}</CTA>
          </CTAWrapper>
        </TextSection>
      </Content>
    </StyledJobs>
  )
}

export default Jobs
