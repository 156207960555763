import styled from 'styled-components'

export const StyledNews = styled.div`
  padding: 100px 0;
`

export const Headline = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.xlg};
`

export const List = styled.div`
  margin-top: 50px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
`

export const CreateWrapper = styled.div`
  margin-top: 50px;
`
