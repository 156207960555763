import styled from 'styled-components'
import Animated from '../../SubComponents/Animated/Animated'

export const StyledCards = styled.div`
  padding: 50px 0px;
`

export const Headline = styled(Animated)`
  font-family: bold;
  text-align: center;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.xlg};
  margin-top: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
    margin-top: 10px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
`
