const props = {
  TOKEN: 'TOKEN',
  ACCOUNT: 'ACCOUNT',
  CURRENTLANG: 'CURRENTLANG',
  TRANSLATION: 'TRANSLATION',
  LOCATION: 'LOCATION',
  USER: 'USER',
  FAMILY_CENTER: 'FAMILY_CENTER',
  NEWS_ARTICLE: 'NEWS_ARTICLE',
  DISTRICT: 'DISTRICT',
  JOB: 'JOB',

  // local
  KITA_DETAIL_LOCATION: 'KITA_DETAIL_LOCATION',
}

export default props
