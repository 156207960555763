import { Link } from '@think-internet/ui-components'
import styled from 'styled-components'

export const StyledCustomLink = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: ${({ margin }) => margin || '0px'};
`

export const Text = styled(Link)``
export const Arrow = styled.img`
  width: 70px;
`
