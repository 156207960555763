import { StyledCard, Content, IconWrapper, Icon, Title } from './Card.Styled.js'

const Card = ({ title, icon, bg }) => {
  return (
    <StyledCard>
      <Content className="content">
        <IconWrapper bg={bg}>
          <Icon src={icon} />
        </IconWrapper>
        <Title>{title}</Title>
      </Content>
    </StyledCard>
  )
}

export default Card
