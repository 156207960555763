import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { StyledDistrictItem, Name, Outline } from './DistrictItem.Styled'
import locationPin from '../../../../assets/image/kitaoverview/start/locationPin.png'

const DistrictItem = ({ district, select, isSlider = false, isSelected = false }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const { name, bgImageURL } = district

  return (
    <StyledDistrictItem bg={bgImageURL} onClick={select} isSlider={isSlider} isSelected={isSelected}>
      <Outline className="outline" src={locationPin} />
      <Name>{`${translation.kitaoverview.districts.namePrefix}${name}`}</Name>
    </StyledDistrictItem>
  )
}

export default DistrictItem
