import styled from 'styled-components'

export const StyledSlider = styled.div`
  width: 100%;
  position: relative;
  height: 200px;
  border: thin solid ${({ theme }) => theme.color.border.secondary};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: none;
  overflow: hidden;
  box-sizing: border-box;
`

export const Wrapper = styled.div`
  display: inline-flex;
  display: flex;
  gap: 10px;
  white-space: nowrap;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
`

export const ImageWrapper = styled.div`
  width: auto;
  max-height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    .remove {
      display: flex;
    }
  }
`

export const Image = styled.img`
  width: auto;
  min-width: 100px;
  max-width: 200px;
  object-fit: contain;
  max-height: 100%;
`

export const Video = styled.video`
  max-width: 100%;
  max-height: 100%;
`

export const Remove = styled.div`
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  padding: 5px;
  background-color: ${({ theme }) => theme.color.background.red};
  border-radius: 5px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.color.font.secondary};
`
