import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import PlacePrediction from '../../SubComponents/PlacePrediction/PlacePrediction'
import RadiusSelection from './RadiusSelection/RadiusSelection'
import { SearchButton, SearchSplit, StyledSearch } from './Search.Styled'
import UseExactLocation from './UseExactLocation/UseExactLocation'

const Search = ({ initLocation = null, initRadius = 2, invertedColorScheme = false }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [googlePrediction, setGooglePrediction] = useState(initLocation || null)
  const [radius, setRadius] = useState(initRadius)

  const searchIsEnabled = () => {
    return googlePrediction && radius
  }

  const search = () => {
    if (searchIsEnabled()) {
      window.location.href = `/kitapiloot/results/${googlePrediction.place_id}/${radius}`
    }
  }

  return (
    <StyledSearch>
      <SearchSplit>
        <PlacePrediction initLocation={googlePrediction} set={setGooglePrediction} />
        <RadiusSelection invertedColorScheme={invertedColorScheme} radius={radius} setRadius={setRadius} />
      </SearchSplit>
      <UseExactLocation invertedColorScheme={invertedColorScheme} callback={setGooglePrediction} />
      {searchIsEnabled() && (
        <SearchButton invertedColorScheme={invertedColorScheme} onClick={search}>
          {translation.kitapiloot.search.cta}
        </SearchButton>
      )}
    </StyledSearch>
  )
}

export default Search
