import Layout from '../Layout/Layout'
import { StyledManagingDirectors } from './ManagingDirectors.Styled'
import Hero from './Hero/Hero'
import People from './People/People.js'

const ManagingDirectors = () => {
  return (
    <Layout authRequired={false}>
      <StyledManagingDirectors>
        <Hero />
        <People />
      </StyledManagingDirectors>
    </Layout>
  )
}

export default ManagingDirectors
