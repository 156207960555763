const { useState, useEffect } = require('react')

const useGeoLocator = () => {
  const [geoLocation, setGeoLocation] = useState(true)

  useEffect(() => {
    if (navigator.geolocation && geoLocation === true) {
      const successCallback = (position) => {
        const { latitude, longitude } = position.coords
        setGeoLocation({ lat: latitude, lng: longitude })
      }
      const errorCallback = (error) => {
        console.log(error)
        setGeoLocation(false)
      }
      const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
      }

      navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options)
    } else if (!navigator.geolocation) {
      setGeoLocation(false)
    }
  }, [geoLocation])

  return geoLocation
}

export default useGeoLocator
