import { Button, Input } from '@think-internet/ui-components'
import { useState } from 'react'
import { StyledUpsertForm, CTASection, PDFManagerWrapper } from './UpsertForm.Styled'
import { useDispatch, useSelector } from 'react-redux'
import { remove, list } from '../../../../../redux/action/crud'
import toast, { TYPE } from '../../../../../toast'
import { upsert } from '../../../../../interface/crud'
import props from '../../../../../redux/props'
import PDFManager from './PDFManager/PDFManager'

const UpsertForm = ({ data: initData, callback }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState(initData || {})
  const token = useSelector((s) => s[props.TOKEN])
  const translation = useSelector((s) => s[props.TRANSLATION])

  const removeHandler = async () => {
    await dispatch(remove(props.JOB, { uuid: data.uuid }))
    if (callback) callback()
  }

  const updateProp = (prop) => (value) => setData({ ...data, [prop]: value })

  const submit = async () => {
    const job = await upsert(token, props.JOB, data, ['uuid'])
    if (job) {
      toast(TYPE.SUCCESS, translation.dashboard.admin.jobs.upsert.success.submit)
      dispatch(list(props.JOB))
      if (callback) callback()
    } else {
      toast(TYPE.ERROR, translation.dashboard.admin.jobs.upsert.error.submit)
    }
  }

  return (
    <StyledUpsertForm>
      <Input placeholder={translation.generic.name} value={data.name} onChange={updateProp('name')} required />
      <PDFManagerWrapper>
        <PDFManager
          multiple={false}
          existingPDFKeys={data.pdfKey ? [data.pdfKey] : []}
          callback={updateProp('pdfKey')}
          title={translation.dashboard.admin.jobs.upsert.pdf.title}
          hint={translation.dashboard.admin.jobs.upsert.pdf.hint}
        />
      </PDFManagerWrapper>
      <CTASection singleItem={!initData?.uuid}>
        {initData?.uuid && <Button text={translation.generic.remove} onClick={removeHandler} />}
        <Button text={translation.generic.save} onClick={submit} />
      </CTASection>
    </StyledUpsertForm>
  )
}

export default UpsertForm
