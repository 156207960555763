import styled from 'styled-components'

export const StyledLocationUpsertForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`

export const CTASection = styled.div`
  display: flex;
  ${({ singleItem }) => (singleItem ? 'justify-content: right;' : 'justify-content: space-between;')}
  width: 100%;
`
