import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Arrow from '../../SubComponents/ArrowRedDownAnimated/ArrowRedDownAnimated'
import { StyledHero, Content, Headline, Subheadline, ImageWrapper, Image } from './Hero.Styled'
import { getUUID } from '../../../utility'
import image from '../../../assets/image/story/hero/image.png'
import Animated from '../../SubComponents/Animated/Animated'

const Hero = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <StyledHero>
      <ImageWrapper>
        <Animated animation="fadeIn" duration={2.5}>
          <Image src={image} />
        </Animated>
      </ImageWrapper>
      <Content>
        <Headline duration="2.5" animation="fadeIn" delay={3}>
          {translation.story.hero.headline}
        </Headline>
        <Subheadline duration="5" animation="fadeIn">
          {translation.story.hero.subheadline.map((s, i) => (
            <span key={getUUID()} style={{ fontFamily: i === 1 ? 'bold' : 'regular' }}>
              {s}
            </span>
          ))}
        </Subheadline>
        <Arrow />
      </Content>
    </StyledHero>
  )
}

export default Hero
