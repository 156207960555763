import { StyledFamilyCenter, Icons, Icon, Text } from './FamilyCenter.Styled'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import beratung_check from '../../../../assets/image/kita/detail/familyCenter/beratung_check.svg'
import beratung_hover from '../../../../assets/image/kita/detail/familyCenter/beratung_hover.svg'
import beratung_still from '../../../../assets/image/kita/detail/familyCenter/beratung_still.svg'
import berufstaetig_still from '../../../../assets/image/kita/detail/familyCenter/berufstaetig_still.svg'
import berufstaetig_hover from '../../../../assets/image/kita/detail/familyCenter/berufstaetig_hover.svg'
import berufstaetig_check from '../../../../assets/image/kita/detail/familyCenter/berufstaetig_check.svg'
import familie_beruf_still from '../../../../assets/image/kita/detail/familyCenter/familie_beruf_still.svg'
import familie_beruf_check from '../../../../assets/image/kita/detail/familyCenter/familie_beruf_check.svg'
import familie_beruf_hover from '../../../../assets/image/kita/detail/familyCenter/familie_beruf_hover.svg'
import familienbegleitung_check from '../../../../assets/image/kita/detail/familyCenter/familienbegleitung_check.svg'
import familienbegleitung_hover from '../../../../assets/image/kita/detail/familyCenter/familienbegleitung_hover.svg'
import familienbegleitung_still from '../../../../assets/image/kita/detail/familyCenter/familienbegleitung_still.svg'
import familienbildung_check from '../../../../assets/image/kita/detail/familyCenter/familienbildung_check.svg'
import familienbildung_hover from '../../../../assets/image/kita/detail/familyCenter/familienbildung_hover.svg'
import familienbildung_still from '../../../../assets/image/kita/detail/familyCenter/familienbildung_still.svg'
import hetero_check from '../../../../assets/image/kita/detail/familyCenter/hetero_check.svg'
import hetero_hover from '../../../../assets/image/kita/detail/familyCenter/hetero_hover.svg'
import hetero_still from '../../../../assets/image/kita/detail/familyCenter/hetero_still.svg'
import land_check from '../../../../assets/image/kita/detail/familyCenter/land_check.svg'
import land_hover from '../../../../assets/image/kita/detail/familyCenter/land_hover.svg'
import land_still from '../../../../assets/image/kita/detail/familyCenter/land_still.svg'
import migration_check from '../../../../assets/image/kita/detail/familyCenter/migration_check.svg'
import migration_hover from '../../../../assets/image/kita/detail/familyCenter/migration_hover.svg'
import migration_still from '../../../../assets/image/kita/detail/familyCenter/migration_still.svg'
import pflege_check from '../../../../assets/image/kita/detail/familyCenter/pflege_check.svg'
import pflege_hover from '../../../../assets/image/kita/detail/familyCenter/pflege_hover.svg'
import pflege_still from '../../../../assets/image/kita/detail/familyCenter/pflege_still.svg'
import praevention_check from '../../../../assets/image/kita/detail/familyCenter/praevention_check.svg'
import praevention_hover from '../../../../assets/image/kita/detail/familyCenter/praevention_hover.svg'
import praevention_still from '../../../../assets/image/kita/detail/familyCenter/praevention_still.svg'

const FamilyCenter = ({ location, mode }) => {
  const [activeProp, setActiveProp] = useState(null)
  const translation = useSelector((s) => s[props.TRANSLATION])

  const staticIcons = {
    beratung: {
      still: beratung_still,
      hover: beratung_hover,
      check: beratung_check,
    },
    familienbildung: {
      still: familienbildung_still,
      hover: familienbildung_hover,
      check: familienbildung_check,
    },
    vereinbarkeit: {
      still: familie_beruf_still,
      hover: familie_beruf_hover,
      check: familie_beruf_check,
    },
  }

  const icons = {
    b: {
      still: berufstaetig_still,
      hover: berufstaetig_hover,
      check: berufstaetig_check,
    },
    f: {
      still: familienbegleitung_still,
      hover: familienbegleitung_hover,
      check: familienbegleitung_check,
    },
    h: {
      still: hetero_still,
      hover: hetero_hover,
      check: hetero_check,
    },
    k: {
      still: pflege_still,
      hover: pflege_hover,
      check: pflege_check,
    },
    l: {
      still: land_still,
      hover: land_hover,
      check: land_check,
    },
    m: {
      still: migration_still,
      hover: migration_hover,
      check: migration_check,
    },
    p: {
      still: praevention_still,
      hover: praevention_hover,
      check: praevention_check,
    },
  }

  const activate = (prop) => () => {
    if (activeProp === prop) setActiveProp(null)
    else setActiveProp(prop)
  }

  if (!location || !location.familyCenter) return <></>
  return (
    <StyledFamilyCenter>
      <Icons>
        {Object.keys(staticIcons).map((key) => (
          <Icon
            key={key}
            src={staticIcons[key].still}
            srcHover={mode === 'kitaoverview' ? staticIcons[key].hover : staticIcons[key].check}
            isActive={activeProp === key}
            onClick={activate(key)}
          />
        ))}
        {location.familyCenter.additionalInfo && (
          <Icon
            key={location.familyCenter.additionalInfo}
            src={icons[location.familyCenter.additionalInfo].still}
            srcHover={mode === 'kitaoverview' ? icons[location.familyCenter.additionalInfo].hover : icons[location.familyCenter.additionalInfo].check}
            isActive={activeProp === location.familyCenter.additionalInfo}
            onClick={activate(location.familyCenter.additionalInfo)}
          />
        )}
      </Icons>
      {activeProp && (
        <Text mode={mode}>
          {translation.kita.detail.familyCenter.staticProperties[activeProp] || translation.kita.detail.familyCenter.properties[activeProp]}
        </Text>
      )}
    </StyledFamilyCenter>
  )
}

export default FamilyCenter
