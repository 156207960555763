import { StyledSplit, Content, ImageSection, Image, TextSection, Headline, Subheadline, Text } from './Split.Styled'
import BubbleBackgroundFloat from '../../SubComponents/BubbleBackgroundFloat/BubbleBackgroundFloat'
import Animated from '../../SubComponents/Animated/Animated'
import { getUUID } from '../../../utility'

const Split = ({ invert, image, bubbleImage, data }) => {
  const { headline, subheadline, text } = data

  return (
    <StyledSplit>
      <Content reverse={invert}>
        <TextSection>
          <BubbleBackgroundFloat bubble={bubbleImage} height="150px" originX={invert ? 110 : -20} originY={-20}>
            <Headline>{headline}</Headline>
            <Subheadline>{subheadline}</Subheadline>
            {text.map((t) => (
              <Text dangerouslySetInnerHTML={{ __html: t }} key={getUUID()} />
            ))}
            {/* <CTAWrapper>
              <CTA href={href}>{translation.application.offerings.itemCTA}</CTA>
            </CTAWrapper> */}
          </BubbleBackgroundFloat>
        </TextSection>
        <ImageSection>
          <Animated animation={invert ? 'fadeInLeft' : 'fadeInRight'} duration={3}>
            <Image src={image} />
          </Animated>
        </ImageSection>
      </Content>
    </StyledSplit>
  )
}

export default Split
