import styled from 'styled-components'

export const StyledHero = styled.div`
  position: relative;
  height: 500px;
  width: 100%;
  background-image: url(${({ bubble }) => bubble});
  background-size: auto 300px;
  background-repeat: no-repeat;
  background-position: center bottom;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-size: 100% auto;
    height: 400px;
  }
`
export const PersonContainer = styled.div`
  position: absolute;
  left: 40%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 1;
  height: 350px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 250px;
  }
`
export const Person = styled.img`
  height: 350px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 250px;
  }
`

export const Map = styled.img`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  height: 150px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 125px;
  }
`
