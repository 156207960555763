import { Container } from '@think-internet/ui-components'
import Layout from '../Layout/Layout'
import { StyledContact } from './Contact.Styled.js'
import ContactForm from '../SubComponents/ContactForm/ContactForm'
import Hero from './Hero/Hero'

const Contact = () => {
  return (
    <Layout authRequired={false}>
      <StyledContact>
        <Hero />
        <Container>
          <ContactForm />
        </Container>
      </StyledContact>
    </Layout>
  )
}

export default Contact
