import { Image, Text } from '../Accordion.Styled'
import war from '../../../../assets/image/story/accordion/solferino/war.png'
import person from '../../../../assets/image/story/accordion/solferino/person.png'
import { translation } from '../../../../translations/de'
import { getUUID } from '../../../../utility'

const Solferino = () => {
  return (
    <div>
      <Image src={war} />
      <Text>{translation.story.accordion.solferino.war}</Text>
      <Image src={person} />
      {translation.story.accordion.solferino.person.map((t) => (
        <Text key={getUUID()}>{t}</Text>
      ))}
    </div>
  )
}

export default Solferino
