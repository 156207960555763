import styled from 'styled-components'

export const StyledHero = styled.div``

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: 'bold';
  color: ${({ theme }) => theme.color.font.secondary};
  text-align: center;
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`
export const Subheadline = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
  text-align: center;
  margin-bottom: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.md};
    margin-top: 10px;
    margin-bottom: 0px;
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const Icon = styled.img`
  width: 75px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 30px;
  }
`
export const Logo = styled.img`
  height: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 30px;
  }
`
