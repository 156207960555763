import { Input } from '@think-internet/ui-components'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { list } from '../../../../../redux/action/crud'
import { setLocal } from '../../../../../redux/action/local'
import props from '../../../../../redux/props'
import { StyledList, CustomAccordion } from './List.Styled'
import Article from '../Article/Article'

const List = () => {
  const token = useSelector((s) => s[props.TOKEN])
  const translation = useSelector((s) => s[props.TRANSLATION])
  const articles = useSelector((s) => s[props.NEWS_ARTICLE])
  const [searchTerm, setSearchTerm] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    if (token) {
      dispatch(list(props.NEWS_ARTICLE))
    }
  }, [dispatch, token])

  const filter = (a) => a.title.toLowerCase().includes(searchTerm.toLowerCase())

  const removeCallback = (uuid) => {
    dispatch(setLocal(articles.filter((a) => a.uuid !== uuid)))
  }

  if (!Array.isArray(articles)) return <></>
  return (
    <StyledList>
      <Input value={searchTerm} onChange={setSearchTerm} placeholder={translation.dashboard.sharedPages.news.search.placeholder} />
      <CustomAccordion
        items={articles.filter(filter).map((a) => ({
          title: a.title,
          content: <Article data={a} removeCallback={removeCallback} />,
        }))}
      />
    </StyledList>
  )
}

export default List
