import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import call from '../../../interface/functional'
import props from '../../../redux/props'
import routes from '../../../redux/routes'
import toast, { TYPE } from '../../../toast'

const useGetKitaResultsByGooglePlaceIdAndRadius = (googlePlaceId, radius) => {
  const [data, setData] = useState(null)
  const translation = useSelector((s) => s[props.TRANSLATION])

  useEffect(() => {
    const getResults = async () => {
      const payload = await call(null, routes.get_kita_results_by_google_place_id_and_radius, { googlePlaceId, radius })
      if (payload.status && payload.response && Array.isArray(payload.response.locations) && payload.response.centerLocation) {
        if (payload.response.locations.length === 0) {
          toast(TYPE.ERROR, translation.kitapiloot.results.error.noLocations)
        }
        setData({ locations: payload.response.locations, centerLocation: payload.response.centerLocation })
      } else {
        toast(TYPE.ERROR, translation.kitapiloot.results.error.internal)
      }
    }
    if (!data) {
      getResults()
    }
  }, [googlePlaceId, radius, data, translation])

  return data || { locations: null, centerLocation: null }
}

export default useGetKitaResultsByGooglePlaceIdAndRadius
