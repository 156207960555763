import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { Label, View, StyledRadiusSelection, Selected, Value, Options, Option, Arrow } from './RadiusSelection.Styled'
import drowdownArrow from '../../../../assets/image/generic/blue-dropdown-arrow-down.svg'

const RadiusSelection = ({ radius, setRadius, invertedColorScheme }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const options = [2, 5, 10, 25, 50, 100]
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!radius) {
      setRadius(2)
    }
  }, [radius, setRadius])

  const select = (value) => () => {
    setRadius(value)
    setOpen(false)
  }

  return (
    <StyledRadiusSelection onClick={() => setOpen(!open)}>
      <Label invertedColorScheme={invertedColorScheme}>{translation.kitapiloot.search.radiusSelection.label}</Label>
      <View>
        <Selected>
          <Value>{radius}</Value>
          {translation.generic.km}
        </Selected>
        <Arrow src={drowdownArrow} />
      </View>
      <Options open={open}>
        {options.map((value) => (
          <Option key={value} onClick={select(value)}>
            <Value>{value}</Value>
            {translation.generic.km}
          </Option>
        ))}
      </Options>
    </StyledRadiusSelection>
  )
}

export default RadiusSelection
