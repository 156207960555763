import { StyledLocationList } from './LocationList.Styled'
import Location from './Location/Location'
import { getUUID } from '../../../../utility'
import Detail from '../../../Kita/Detail/Detail'

const LocationList = ({ locations, geoLocation = null, mode = 'kitapiloot' }) => {
  if (!Array.isArray(locations)) return <></>

  const sortByDistance = (a, b) => {
    return a.distance - b.distance
  }

  return (
    <StyledLocationList>
      {locations.sort(sortByDistance).map((location, i) => (
        <Location geoLocation={geoLocation} mode={mode} data={location} key={getUUID()} index={i} />
      ))}
      <Detail mode={mode} />
    </StyledLocationList>
  )
}

export default LocationList
