import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { StyledHeader, GenericInfo, Label, TrafficLight, DetailToggle, GoToToggle, IndexWrapper } from './Header.Styled'

const Header = ({ mode = 'kitapiloot' }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledHeader>
      <IndexWrapper></IndexWrapper>
      <GenericInfo>
        <Label mode={mode}>{translation.kitapiloot.results.location.header.genericInfo}</Label>
      </GenericInfo>
      <TrafficLight>
        <Label mode={mode}>{translation.kitapiloot.results.location.header.trafficLight}</Label>
      </TrafficLight>
      <DetailToggle>
        <Label mode={mode}>{translation.kitapiloot.results.location.header.detail}</Label>
      </DetailToggle>
      <GoToToggle />
    </StyledHeader>
  )
}

export default Header
