import styled from 'styled-components'
import { Link } from '@think-internet/ui-components'
import Wave from '../../SubComponents/Wave/Wave'

export const StyledFooter = styled.div`
  padding-bottom: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    padding-bottom: 0px;
  }
`

export const Column = styled.div`
  flex: 1 1 0px;
  margin: ${({ margin }) => margin || '0px'};

  :last-child() {
    @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      margin-top: 10px;
    }
  }
`

export const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.font.size.md};
`

export const CustomText = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.lightblack};
`

export const Flex = styled.div`
  display: flex;
  gap: 25px;
  align-items: flex-end;
  justify-content: ${({ justify }) => justify || 'center'};
  width: 100%;
  margin: ${({ margin }) => margin || '0px'};

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: block;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`

export const Text = styled.div`
  margin-bottom: 10px;
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const Logo = styled.img`
  width: 150px;
`

export const BackgroundWave = styled(Wave).attrs(({ theme, mode }) => {
  if (mode === 'default') return { backgroundColor: theme.color.background.primary }
  return { backgroundColor: theme.color.background.lightblue }
})``
