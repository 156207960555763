import Animated from '../../SubComponents/Animated/Animated'

const { default: styled } = require('styled-components')

export const StyledReasonPageLayout = styled.div``

export const Content = styled.div`
  padding: 50px 0;
`

export const Headline = styled(Animated)`
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: bold;
  text-align: center;
  z-index: 1;
  position: relative;
`

export const Reasons = styled.div``
export const Reason = styled(Animated)`
  margin-bottom: 50px;
  font-size: ${({ theme }) => theme.font.size.lg};
  display: block;
  &:first-child {
    margin-top: 50px;
  }
`

export const Image = styled.img`
  margin-bottom: 50px;
  max-height: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  z-index: 1;
  position: relative;
`

export const SlimContainer = styled.div`
  max-width: 750px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
`
