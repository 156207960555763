import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import {
  StyledProductOverview,
  Headline,
  Items,
  StyledItem,
  Icon,
  NameImageWrapper,
  NameImage,
  Description,
  ActionHint,
  Content,
  Hint,
  HintText,
  DownloadCTA,
  DownloadCTAWrapper,
  DownloadCTAIcon,
} from './ProductOverview.Styled'
import kitapiloot from '../../../assets/image/generic/kitapiloot.svg'
import kitapilootName from '../../../assets/image/generic/kitapilootName.svg'
import kitauebersicht from '../../../assets/image/generic/kitauebersicht.svg'
import kitauebersichtName from '../../../assets/image/generic/kitauebersichtName.svg'
import bubble from '../../../assets/image/home/productOverview/bubble.svg'
import downloadCTAIcon from '../../../assets/image/home/productOverview/downloadCTAIcon.png'
import BubbleBackgroundFloat from '../../SubComponents/BubbleBackgroundFloat/BubbleBackgroundFloat'
import { Container } from '@think-internet/ui-components'
import { getUUID } from '../../../utility'

const Item = ({ icon, nameImage, description, href, nameImageHeight = '30px' }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledItem href={href}>
      <Icon src={icon} />
      <NameImageWrapper height={nameImageHeight}>
        <NameImage src={nameImage} />
      </NameImageWrapper>
      <Description>{description}</Description>
      <ActionHint>{translation.home.productOverview.actionHint}</ActionHint>
    </StyledItem>
  )
}

const ProductOverview = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledProductOverview>
      <Container>
        {translation.home.productOverview.headline.map((h, i) => (
          <Headline disabledOnMobile animation={'typing'} duration="3" delay={i * 2.5} key={getUUID()}>
            {h}
          </Headline>
        ))}
      </Container>
      <BubbleBackgroundFloat bubble={bubble}>
        <Content>
          <Items>
            <Item
              icon={kitapiloot}
              nameImageHeight="35px"
              nameImage={kitapilootName}
              description={translation.home.productOverview.kitapiloot.description}
              href={'/kitapiloot'}
            />
            <Item
              icon={kitauebersicht}
              nameImage={kitauebersichtName}
              description={translation.home.productOverview.kitauebersicht.description}
              href={'/kitauebersicht'}
            />
          </Items>
          <Hint>
            {translation.home.productOverview.hint.map((h) => (
              <HintText key={getUUID()}>{h}</HintText>
            ))}
          </Hint>
          <DownloadCTAWrapper>
            <DownloadCTA href="/infomaterial">
              {translation.home.productOverview.downloadCTA}
              <DownloadCTAIcon src={downloadCTAIcon} />
            </DownloadCTA>
          </DownloadCTAWrapper>
        </Content>
      </BubbleBackgroundFloat>
    </StyledProductOverview>
  )
}

export default ProductOverview
