import { StyledStart, Headline, Subheadline } from './Start.Styled'
import Layout from '../../Layout/Layout'
import Hero from './Hero/Hero'
import { Container } from '@think-internet/ui-components'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'
import Search from '../Search/Search'

const Start = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <Layout authRequired={false} mode="kitapiloot">
      <StyledStart>
        <Container>
          <Hero />
          <Headline>{translation.kitapiloot.start.headline}</Headline>
          <Subheadline>{translation.kitapiloot.start.subheadline}</Subheadline>
          <Search />
        </Container>
      </StyledStart>
    </Layout>
  )
}

export default Start
