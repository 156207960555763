import { DragAndDrop } from '@think-internet/ui-components'
import { Processing, StyledImageManager } from './ImageManager.Styled'
import Slider from './Slider/Slider'
import toast, { TYPE } from '../../../../../../toast'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import { s3Upload } from '../../../../../../fetch'
import call from '../../../../../../interface/functional'
import routes from '../../../../../../redux/routes'

const ImageManager = ({ callback, title, hint, existingImageKeys = [], multiple = true }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const token = useSelector((s) => s[props.TOKEN])
  const [isProcessing, setIsProcessing] = useState(false)

  const processNewImages = async (images) => {
    if (images.length > 0) {
      setIsProcessing(true)
      const imageKeyList = []
      for (const image of images) {
        const { status, response } = await call(token, routes.get_news_article_image_upload_url, {
          fileMeta: { name: image.name, type: image.type, size: image.size },
        })
        if (status && response) {
          const { key, presignedURL } = response
          const uploadStatus = await s3Upload(presignedURL, image)
          if (!uploadStatus) return false
          imageKeyList.push(key)
        } else {
          toast(TYPE.ERROR, translation.dashboard.sharedPages.news.upsert.error.upload)
        }
      }
      callback([...existingImageKeys, ...imageKeyList])
      setIsProcessing(false)
    }
  }

  const removeImageKey = (key) => {
    if (multiple === false) {
      callback(null)
    } else {
      const newImageKeys = existingImageKeys.filter((imageKey) => imageKey !== key)
      callback(newImageKeys)
    }
  }

  return (
    <StyledImageManager>
      <DragAndDrop
        title={title}
        hint={hint}
        onFileCallback={processNewImages}
        maxFileSizeInBytes={5000000}
        maxFileSizeExceededToast={translation.dashboard.sharedPages.news.upsert.images.maxFileSizeExceededToast}
        multiple={multiple}
        allowedFileExtensions={['jpeg', 'jpg', 'png']}
      />
      {isProcessing && <Processing>{translation.dashboard.sharedPages.news.upsert.images.processingImageLabel}</Processing>}
      <Slider imageKeys={existingImageKeys} remove={removeImageKey} />
    </StyledImageManager>
  )
}

export default ImageManager
