import { Button, Modal } from '@think-internet/ui-components'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import UpsertForm from '../UpsertForm/UpsertForm'
import { StyledCreate, ButtonWrapper } from './Create.Styled'

const Create = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)

  return (
    <StyledCreate>
      <ButtonWrapper>
        <Button onClick={() => setOpen(!open)} text={translation.dashboard.admin.districts.upsert.toggleCTA} />
      </ButtonWrapper>
      <Modal
        title={translation.dashboard.admin.districts.upsert.toggleCTA}
        close={() => setOpen(false)}
        closeContent={translation.dashboard.admin.districts.upsert.close}
        open={open}
      >
        <UpsertForm callback={() => setOpen(false)} />
      </Modal>
    </StyledCreate>
  )
}

export default Create
