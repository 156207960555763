import styled from 'styled-components'

export const StyledRadiusSelection = styled.div`
  width: 30%;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const Label = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  text-align: center;
  text-transform: uppercase;
  ${({ invertedColorScheme, theme }) => invertedColorScheme && `color: ${theme.color.font.orange};`}
`

export const View = styled.div`
  background-color: ${({ theme }) => theme.color.background.white};
  height: 39px;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  width: 100%;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Selected = styled.div`
  text-align: center;
  text-transform: uppercase;
`
export const Value = styled.span`
  font-family: 'bold';
  font-size: 20px;
`

export const Options = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  ${({ open }) => (open ? 'display: block' : 'display: none')};
  width: 100%;
  background-color: ${({ theme }) => theme.color.background.white};
  border-radius: 20px;
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;
`

export const Option = styled.div`
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid ${({ theme }) => theme.color.border.lightGrey};
  text-transform: uppercase;
  cursor: pointer;
  &:first-child {
    border-top: none;
  }
`

export const Arrow = styled.div`
  background-image: url(${({ src }) => src});
  background-position: center center;
  background-repeat: no-repeat;
  width: 25px;
  position: absolute;
  right: 10px;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
`
