import { Image, Text } from '../Accordion.Styled'
import paper from '../../../../assets/image/story/accordion/truth/paper.jpg'
import { translation } from '../../../../translations/de'
import { getUUID } from '../../../../utility'

const Truth = () => {
  return (
    <div>
      <Image src={paper} />
      {translation.story.accordion.truth.text.map((t) => (
        <Text key={getUUID()}>{t}</Text>
      ))}
    </div>
  )
}

export default Truth
