import { Container } from '@think-internet/ui-components'
import Layout from '../Layout/Layout'
import { StyledApplication, OfferingsHeadline, MoreHeadline } from './Application.Styled.js'
import { useSelector } from 'react-redux'
import Hero from './Hero/Hero'
import Split from './Split/Split'
import image1 from '../../assets/image/application/image1.svg'
import image2 from '../../assets/image/application/image2.svg'
import image3 from '../../assets/image/application/image3.svg'
import bubble1 from '../../assets/image/application/bubble1.svg'
import bubble2 from '../../assets/image/application/bubble2.svg'
import bubble3 from '../../assets/image/application/bubble3.svg'
import PersonioList from './PersonioList/PersonioList.js'

const Application = () => {
  const translation = useSelector((s) => s.TRANSLATION)
  const sectionImages = [image1, image2, image3]
  const sectionBubbleImages = [bubble1, bubble2, bubble3]

  return (
    <Layout authRequired={false}>
      <StyledApplication>
        <Hero />
        <Container>
          <OfferingsHeadline>{translation.application.offerings.headline}</OfferingsHeadline>
          {translation.application.offerings.items.map((data, i) => (
            <Split invert={i % 2 === 1} key={i} image={sectionImages[i]} bubbleImage={sectionBubbleImages[i]} data={data} />
          ))}
          <MoreHeadline id="open-positions">{translation.application.personio.headline}</MoreHeadline>
          <PersonioList />
        </Container>
      </StyledApplication>
    </Layout>
  )
}

export default Application
