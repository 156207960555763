import styled from 'styled-components'

export const StyledPersonioList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 70%;
  margin: auto;
  padding: 50px 0px;
`
