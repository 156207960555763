import { Button, Input } from '@think-internet/ui-components'
import { useState } from 'react'
import { StyledFamilyCenterUpsertForm, CTASection } from './FamilyCenterUpsertForm.Styled'
import { useDispatch, useSelector } from 'react-redux'
import { remove, list } from '../../../../redux/action/crud'
import toast, { TYPE } from '../../../../toast'
import { upsert } from '../../../../interface/crud'
import props from '../../../../redux/props'
import LocationAssignment from './LocationAssignment/LocationAssignment'
import { levels } from '../../../../enum'
import InfoPDFManager from '../InfoPDFManager/InfoPDFManager'
import AdditionalInfoSelection from './AdditionalInfoSelection/AdditionalInfoSelection'
import routes from '../../../../redux/routes'
import MoreInfoPDFManager from '../MoreInfoPDFManager/MoreInfoPDFManager'

const FamilyCenterUpsertForm = ({ data: initData, callback }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState(initData || {})
  const token = useSelector((s) => s[props.TOKEN])
  const account = useSelector((s) => s[props.ACCOUNT])
  const translation = useSelector((s) => s[props.TRANSLATION])

  const removeHandler = async () => {
    await dispatch(remove(props.FAMILY_CENTER, { uuid: data.uuid }))
    if (callback) callback()
  }

  const updateProp = (prop) => (value) => setData({ ...data, [prop]: value })

  const updateMoreInfoProps = (value) => setData({ ...data, moreInfoPDFNameKeyList: value.pdfNameKeyList, moreInfoZIP: value.zip })

  const submit = async () => {
    const familyCenter = await upsert(token, props.FAMILY_CENTER, data, ['uuid'])
    if (familyCenter) {
      toast(TYPE.SUCCESS, translation.dashboard.admin.familyCenter.upsert.success.submit)
      dispatch(list(props.FAMILY_CENTER))
      if (callback) callback()
    } else {
      toast(TYPE.ERROR, translation.dashboard.admin.familyCenter.upsert.error.submit)
    }
  }

  const isAdmin = () => account.level === levels.ADMIN

  return (
    <StyledFamilyCenterUpsertForm>
      <Input label={translation.generic.name} placeholder={translation.generic.name} value={data.name} onChange={updateProp('name')} required />
      <Input
        label={translation.generic.responsiblePerson}
        placeholder={translation.generic.responsiblePerson}
        value={data.responsiblePerson}
        onChange={updateProp('responsiblePerson')}
        required
      />
      <Input label={translation.generic.phone} placeholder={translation.generic.phone} value={data.phone} onChange={updateProp('phone')} required />
      {isAdmin() && <LocationAssignment locationUUID={data.locationUUID} callback={updateProp('locationUUID')} />}
      <InfoPDFManager
        route={routes.get_family_center_pdf_upload_url}
        title={`${translation.dashboard.admin.familyCenter.upsert.infoPDF.dynamicTitlePDF}Beratung und Unterstützung`}
        hint={translation.dashboard.admin.familyCenter.upsert.infoPDF.hint}
        existingPDFKey={data.beratungInfoPDF}
        callback={updateProp('beratungInfoPDF')}
      />
      <InfoPDFManager
        route={routes.get_family_center_pdf_upload_url}
        title={`${translation.dashboard.admin.familyCenter.upsert.infoPDF.dynamicTitlePDF}Familienbildung und Erziehungspartnerschaft`}
        hint={translation.dashboard.admin.familyCenter.upsert.infoPDF.hint}
        existingPDFKey={data.familienbildungInfoPDF}
        callback={updateProp('familienbildungInfoPDF')}
      />
      <InfoPDFManager
        route={routes.get_family_center_pdf_upload_url}
        title={`${translation.dashboard.admin.familyCenter.upsert.infoPDF.dynamicTitlePDF}Vereinbarkeit von Familie und Beruf`}
        hint={translation.dashboard.admin.familyCenter.upsert.infoPDF.hint}
        existingPDFKey={data.vereinbarkeitInfoPDF}
        callback={updateProp('vereinbarkeitInfoPDF')}
      />
      <AdditionalInfoSelection additionalProperty={data.additionalInfo} set={updateProp('additionalInfo')} />
      {!!data.additionalInfo && (
        <InfoPDFManager
          route={routes.get_family_center_pdf_upload_url}
          title={`${translation.dashboard.admin.familyCenter.upsert.infoPDF.dynamicTitlePDF}${
            translation.kita.detail.familyCenter.properties[data.additionalInfo]
          }`}
          hint={translation.dashboard.admin.familyCenter.upsert.infoPDF.hint}
          existingPDFKey={data.additionalInfoPDF}
          callback={updateProp('additionalInfoPDF')}
        />
      )}
      <MoreInfoPDFManager data={data} callback={updateMoreInfoProps} />
      <CTASection singleItem={!isAdmin() || !initData?.uuid}>
        {isAdmin() && initData?.uuid && <Button text={translation.generic.remove} onClick={removeHandler} />}
        <Button text={translation.generic.save} onClick={submit} />
      </CTASection>
    </StyledFamilyCenterUpsertForm>
  )
}

export default FamilyCenterUpsertForm
