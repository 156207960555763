import styled from 'styled-components'

export const StyledPrinciples = styled.div`
  margin-top: 100px;
`

export const Headline = styled.div`
  font-family: bold;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.xlg};

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.md};
    margin-top: 10px;
  }
`

export const SubHeadline = styled.div`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.lg};

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.md};
  }
`

export const BigWheel = styled.img`
  margin-top: 50px;
`

export const CTA = styled.a`
  display: block;
  border-radius: 30px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.background.darkBlue};
  text-transform: uppercase;
  padding: 10px 20px;
  width: 30%;
  text-align: center;
  margin: auto;
  margin-top: 100px;
  color: ${({ theme }) => theme.color.font.secondary};
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 80%;
  }
`

export const HeadlineWrapper = styled.div`
  width: 100%;
  text-align: center;
`
