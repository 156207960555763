import { Container } from '@think-internet/ui-components'
import Layout from '../Layout/Layout'
import { StyledContactPerson, Subline, Section, Title } from './ContactPerson.Styled.js'
import { useSelector } from 'react-redux'
import Hero from './Hero/Hero'
import Split from './Split/Split'
import arnd_organisch from '../../assets/image/contactPerson/arnd_organisch.svg'
import arnd from '../../assets/image/contactPerson/arnd.png'
import valessa_organisch from '../../assets/image/contactPerson/valessa_organisch.svg'
import valessa from '../../assets/image/contactPerson/valessa.png'
import nina_organisch from '../../assets/image/contactPerson/nina_organisch.svg'
import nina from '../../assets/image/contactPerson/nina.png'
import nattler_organisch from '../../assets/image/contactPerson/nattler_organisch.svg'
import nattler from '../../assets/image/contactPerson/nattler.png'
import poschmann_organisch from '../../assets/image/contactPerson/poschmann_organisch.svg'
import poschmann from '../../assets/image/contactPerson/poschmann.png'
import poschmann_man from '../../assets/image/contactPerson/poschmann_man.png'
import form from '../../assets/image/contactPerson/form.png'
import poschmann_man_organic from '../../assets/image/contactPerson/poschmann_man_organic.png'
import form_organic from '../../assets/image/contactPerson/form_organic.png'
import andrea_organisch from '../../assets/image/contactPerson/andrea_organisch.svg'
import andrea from '../../assets/image/contactPerson/andrea.png'
import { getUUID } from '../../utility'

const ContactPerson = () => {
  const translation = useSelector((s) => s.TRANSLATION)
  const sectionImages = [
    [arnd, nina, valessa, poschmann, nattler, andrea],
    [poschmann_man, form],
  ]
  const sectionBubbleImages = [
    [arnd_organisch, nina_organisch, valessa_organisch, poschmann_organisch, nattler_organisch, andrea_organisch],
    [poschmann_man_organic, form_organic],
  ]

  return (
    <Layout authRequired={false}>
      <StyledContactPerson>
        <Hero />
        <Container>
          {translation.contactPerson.sections.map(({ title, people }, i) => (
            <Section key={getUUID()}>
              {title && <Title>{title}</Title>}
              {people.map((data, j) => {
                return (
                  <Split
                    invert={people.length > 1 ? j % 2 === 1 : i % 2 === 1}
                    key={i}
                    image={sectionImages[i][j]}
                    bubbleImage={sectionBubbleImages[i][j]}
                    data={data}
                  />
                )
              })}
            </Section>
            // <Split invert={i % 2 === 1} key={i} image={sectionImages[i]} bubbleImage={sectionBubbleImages[i]} data={data} />
          ))}
          <Subline>{translation.contactPerson.subline}</Subline>
        </Container>
      </StyledContactPerson>
    </Layout>
  )
}

export default ContactPerson
