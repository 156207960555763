import { StyledBubbleBackgroundFloat, Bubble, Content } from './BubbleBackgroundFloat.Styled'

const BubbleBackgroundFloat = ({ children, bubble, originX = 50, originY = 50, height = '100%' }) => {
  return (
    <StyledBubbleBackgroundFloat>
      <Bubble src={bubble} originX={originX} originY={originY} height={height} />
      <Content>{children}</Content>
    </StyledBubbleBackgroundFloat>
  )
}

export default BubbleBackgroundFloat
