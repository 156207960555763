import { useParams } from 'react-router-dom'
import Layout from '../../Layout/Layout'
import { Icon, Logo, LogoWrapper, StyledResults, Content, GoogleMapWrapper, RefreshPage } from './Results.Styled'
import GoogleMap from './GoogleMap/GoogleMap'
import { Container } from '@think-internet/ui-components'
import logoIcon from '../../../assets/image/generic/kitapiloot.svg'
import logo from '../../../assets/image/generic/kitapilootName.svg'
import Search from '../Search/Search'
import useGetKitaResultsByGooglePlaceIdAndRadius from './useGetKitaResultsByGooglePlaceIdAndRadius'
import LocationList from './LocationList/LocationList'
import LocationListHeader from './LocationList/Header/Header'
import BubbleBackgroundFloat from '../../SubComponents/BubbleBackgroundFloat/BubbleBackgroundFloat'
import headlineBubble from '../../../assets/image/kitapiloot/results/headlineBubble.svg'
import contentBubble from '../../../assets/image/kitapiloot/results/contentBubble.svg'
import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'

const Results = () => {
  const { googlePlaceId, radius } = useParams()
  const { locations, centerLocation } = useGetKitaResultsByGooglePlaceIdAndRadius(googlePlaceId, radius)
  const [enableGoogleMap, setEnableGoogleMap] = useState(false)

  const refreshPage = () => window.location.reload()

  useEffect(() => {
    const cookies = new Cookies()
    const consent = cookies.get('consent')
    setEnableGoogleMap(consent === 'accepted')
  }, [])

  return (
    <Layout authRequired={false} mode="kitapiloot">
      <StyledResults>
        <BubbleBackgroundFloat bubble={headlineBubble}>
          <LogoWrapper>
            <Icon src={logoIcon} />
            <Logo src={logo} />
          </LogoWrapper>
        </BubbleBackgroundFloat>
        <BubbleBackgroundFloat bubble={contentBubble} originX={80} originY={140} height="400px">
          <Container>
            {centerLocation && locations && (
              <Content>
                <Search invertedColorScheme initLocation={centerLocation} initRadius={radius} />
                {enableGoogleMap && (
                  <GoogleMapWrapper>
                    <GoogleMap centerCoordinates={centerLocation.geometry.location} locations={locations} />
                  </GoogleMapWrapper>
                )}
                <LocationListHeader />
                <LocationList locations={locations} />
                <RefreshPage onClick={refreshPage} />
              </Content>
            )}
          </Container>
        </BubbleBackgroundFloat>
      </StyledResults>
    </Layout>
  )
}

export default Results
