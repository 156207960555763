import { Container } from '@think-internet/ui-components'
import styled from 'styled-components'

export const StyledDistricts = styled.div`
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-top: 0px;
  }
`

export const List = styled(Container)`
  display: flex;
  position: relative;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 25px auto;
  box-sizing: border-box;

  ${({ isSlider, theme }) =>
    isSlider &&
    `
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-y: hidden;
    oveflow-x: auto;
    border-bottom: thin solid ${theme.color.border.secondary};
  `}
`
