import { DragAndDrop } from '@think-internet/ui-components'
import { Processing, StyledInfoPDFManager, RemoveButton } from './InfoPDFManager.Styled'
import toast, { TYPE } from '../../../../toast'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { s3Upload } from '../../../../fetch'
import call from '../../../../interface/functional'

const InfoPDFManager = ({ route, callback, title, hint, existingPDFKey = null }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const token = useSelector((s) => s[props.TOKEN])
  const [isProcessing, setIsProcessing] = useState(false)

  const processNewPDF = async (files) => {
    if (files.length === 1) {
      setIsProcessing(true)
      const pdf = files[0]
      const { status, response } = await call(token, route, {
        fileMeta: { name: pdf.name, type: pdf.type, size: pdf.size },
      })
      if (status && response) {
        const { key, presignedURL } = response
        const uploadStatus = await s3Upload(presignedURL, pdf)
        if (!uploadStatus) return false
        callback(key)
      } else {
        toast(TYPE.ERROR, translation.dashboard.admin.familyCenter.upsert.error.upload)
      }
      setIsProcessing(false)
    }
  }

  const removePDFKey = () => callback(null)

  return (
    <StyledInfoPDFManager>
      <DragAndDrop
        title={title}
        hint={hint}
        onFileCallback={processNewPDF}
        maxFileSizeInBytes={10000000}
        maxFileSizeExceededToast={translation.dashboard.admin.familyCenter.upsert.maxFileSizeExceededToast}
        multiple={false}
        allowedFileExtensions={['pdf']}
      />
      {isProcessing && <Processing>{translation.dashboard.admin.familyCenter.upsert.processingPDFLabel}</Processing>}
      {!!existingPDFKey && (
        <RemoveButton onClick={removePDFKey}>{translation.dashboard.admin.familyCenter.upsert.infoPDF.removeExisting}</RemoveButton>
      )}
    </StyledInfoPDFManager>
  )
}

export default InfoPDFManager
