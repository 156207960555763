import { getPrettyDate } from '../../../utility'
import { StyledEntry, ImageWrapper, Content, Image, DateLabel, Title, Location, Text } from './Entry.Styled'

const Entry = ({ entry }) => {
  return (
    <StyledEntry>
      <ImageWrapper>
        {entry.imageURLs.map((url) => (
          <Image src={url} key={url} />
        ))}
      </ImageWrapper>
      <Content>
        <DateLabel>{getPrettyDate(new Date(entry.createdOn))}</DateLabel>
        <Title>{entry.title}</Title>
        <Location>{entry.location.name}</Location>
        <Text>{entry.text}</Text>
      </Content>
    </StyledEntry>
  )
}

export default Entry
