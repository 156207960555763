import Arrow from '../../../SubComponents/ArrowRedDownAnimated/ArrowRedDownAnimated'
import { StyledHero, Content, Subline, ImageWrapper, Image } from './Hero.Styled'
import Animated from '../../../SubComponents/Animated/Animated'

const Hero = ({ image, subline, sublineColorKey }) => {
  return (
    <StyledHero>
      <ImageWrapper>
        <Animated animation="fadeIn" duration={2.5}>
          <Image src={image} />
        </Animated>
      </ImageWrapper>
      <Content>
        <Subline colorKey={sublineColorKey} duration="2.5" animation="fadeIn" delay={3}>
          {subline}
        </Subline>
        <Arrow />
      </Content>
    </StyledHero>
  )
}

export default Hero
