import styled from 'styled-components'

export const StyledImageManager = styled.div`
  width: calc(50% - 7.5px);
`
export const Processing = styled.div`
  text-align: center;
  margin: 10px 0;
  font-size: ${({ theme }) => theme.font.size.sm};
`
