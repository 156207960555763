import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { StyledLocationAssignment, List, Location, Headline } from './LocationAssignment.Styled'
import { getUUID } from '../../../../../utility'
import { list } from '../../../../../redux/action/crud'

const LocationAssignment = ({ locationUUID = null, callback }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const locations = useSelector((s) => s[props.LOCATION])
  const dispatch = useDispatch()

  useEffect(() => {
    if (!locations) dispatch(list(props.LOCATION))
  }, [locations, dispatch])

  const toggleLocation = (uuid) => () => callback(locationUUID === uuid ? null : uuid)

  if (!Array.isArray(locations)) return false
  return (
    <StyledLocationAssignment>
      <Headline>{translation.dashboard.admin.familyCenter.upsert.locationAssignment.headline}</Headline>
      <List>
        {locations.map((u) => (
          <Location className={locationUUID === u.uuid ? 'selected' : ''} key={getUUID()} onClick={toggleLocation(u.uuid)}>
            {u.name}
          </Location>
        ))}
      </List>
    </StyledLocationAssignment>
  )
}

export default LocationAssignment
