import styled from 'styled-components'

export const StyledFamilyCenter = styled.div`
  padding: 100px 0;
`

export const Headline = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.xlg};
`

export const Content = styled.div`
  margin-top: 25px;
`
