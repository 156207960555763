import { useDispatch, useSelector } from 'react-redux'
import props from '../../../redux/props'
import { List, StyledDistricts } from './Districts.Styled'
import { getUUID } from '../../../utility'
import DistrictItem from './DistrictItem/DistrictItem'
import { useEffect, useState } from 'react'
import { list } from '../../../redux/action/crud'
import Results from './Results/Results'
import BubbleBackgroundFloat from '../../SubComponents/BubbleBackgroundFloat/BubbleBackgroundFloat'
import contentBubble from '../../../assets/image/kitaoverview/start/contentBubble.svg'
import { Container } from '@think-internet/ui-components'

const Districts = () => {
  const dispatch = useDispatch()
  const districts = useSelector((s) => s[props.DISTRICT])
  const [selectedDistrictUUID, setSelectedDistrictUUID] = useState(null)

  useEffect(() => {
    if (!Array.isArray(districts)) {
      dispatch(list(props.DISTRICT))
    }
  }, [districts, dispatch])

  const isSlider = () => !!selectedDistrictUUID

  if (!Array.isArray(districts)) return <></>
  return (
    <StyledDistricts>
      <BubbleBackgroundFloat bubble={contentBubble} height="750px" originX={20} originY={0}>
        <List isSlider={isSlider()}>
          {districts.map((district) => (
            <DistrictItem
              key={getUUID()}
              isSlider={isSlider()}
              isSelected={selectedDistrictUUID === district.uuid}
              district={district}
              select={() => setSelectedDistrictUUID(district.uuid)}
            />
          ))}
        </List>
      </BubbleBackgroundFloat>
      <Container>
        <Results districtUUID={selectedDistrictUUID} />
      </Container>
    </StyledDistricts>
  )
}

export default Districts
