import { StyledDownloadCircle, Text, Image } from './DownloadCircle.Styled'
import downloadCircleImage from '../../../../assets/image/header/download.svg'
import downloadCircleTextImage from '../../../../assets/image/header/download-text.png'
import { useEffect } from 'react'
import { useState } from 'react'
import Arrow from './Arrow/Arrow'

const DownloadCircle = () => {
  const [scroll, setScroll] = useState(window.scrollY)

  useEffect(() => {
    const handleNavigation = () => setScroll(window.scrollY / 25)

    window.addEventListener('scroll', (e) => handleNavigation(e))

    return () => {
      window.removeEventListener('scroll', (e) => handleNavigation(e))
    }
  })

  const isHome = () => {
    const path = window.location.pathname
    return path === '/'
  }

  if (!isHome()) return <></>
  return (
    <StyledDownloadCircle>
      <Arrow />
      <a href="/bewerbung#open-positions">
        <Text src={downloadCircleTextImage} rotate={scroll} />
        <Image src={downloadCircleImage} />
      </a>
    </StyledDownloadCircle>
  )
}

export default DownloadCircle
