import styled from 'styled-components'
import ctaBGImage from '../../../../assets/image/kita/detail/familyCenter/popup/cta.png'
import ctaBGImageHover from '../../../../assets/image/kita/detail/familyCenter/popup/cta_hover.png'
import ctaDownloadBGImage from '../../../../assets/image/kita/detail/familyCenter/popup/cta_download.png'
import ctaDownloadBGImageHover from '../../../../assets/image/kita/detail/familyCenter/popup/cta_download_hover.png'
import closeHover from '../../../../assets/image/kita/detail/familyCenter/popup/close.png'
import close from '../../../../assets/image/kita/detail/familyCenter/popup/close_hover.png'

export const StyledFamilyCenterPopup = styled.div`
  position: fixed;
  z-index: 1004;
  padding: 15px;
  border-radius: 30px;
  top: 50px;
  left: 10%;
  width: 80%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.background.orange};
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: calc(100% - 60px);
    left: 15px;
  }
`

export const ImageWrapper = styled.div`
  text-align: center;
`

export const Image = styled.img`
  height: 200px;
  width: auto;
  margin: 0 auto;
`

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  text-align: center;
  color: ${({ theme }) => theme.color.font.secondary};
  margin-top: 15px;
`
export const Subheadline = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  text-align: center;
  color: ${({ theme }) => theme.color.font.secondary};
`
export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
  margin: 0 auto;
  margin-top: 25px;
`

export const Item = styled.div`
  display: flex;
  gap: 10px;
`

export const Name = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.background.white};
  border-radius: 30px;
  text-align: center;
  padding: 5px;
  font-size: ${({ theme }) => theme.font.size.sm};
`
export const CTA = styled.a`
  width: 100%;
  background-color: ${({ theme }) => theme.color.background.white};
  background-image: url(${({ download }) => (download ? ctaDownloadBGImage : ctaBGImage)});
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center center;
  align-self: stretch;
  width: 60px;
  border-radius: 30px;
  &:hover {
    background-color: ${({ theme }) => theme.color.background.darkBlue};
    background-image: url(${({ download }) => (download ? ctaDownloadBGImageHover : ctaBGImageHover)});
  }
`

export const BackButton = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: fit-content;
  margin: 25px auto 0 auto;
  background-color: ${({ theme }) => theme.color.background.white};
  padding: 7.5px 15px;
  border-radius: 30px;
  cursor: pointer;
`

export const BackIcon = styled.img`
  height: 20px;
`

export const BackText = styled.div`
  color: ${({ theme }) => theme.color.font.orange};
  font-size: 14px;
`

export const CloseButton = styled.div`
  cursor: pointer;
  height: 35px;
  width: 35px;
  background-size: cover;
  position: absolute;
  right: 25px;
  top: 25px;
  background-image: url(${close});
  &:hover {
    background-image: url(${closeHover});
  }
`
