import { DragAndDrop } from '@think-internet/ui-components'
import { Processing, StyledPDFManager, CurrentPDF } from './PDFManager.Styled'
import toast, { TYPE } from '../../../../../../toast'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import { s3Upload } from '../../../../../../fetch'
import call from '../../../../../../interface/functional'
import routes from '../../../../../../redux/routes'
import { s3StorageBucketPublicURLPrefix } from '../../../../../../utility'

const PDFManager = ({ callback, title, hint, existingPDFKeys = [], multiple = true }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const token = useSelector((s) => s[props.TOKEN])
  const [isProcessing, setIsProcessing] = useState(false)

  const processNewPDF = async (pdfs) => {
    if (pdfs.length > 0) {
      setIsProcessing(true)
      const pdfKeyList = []
      for (const pdf of pdfs) {
        const { status, response } = await call(token, routes.get_job_pdf_upload_url, {
          fileMeta: { name: pdf.name, type: pdf.type, size: pdf.size },
        })
        if (status && response) {
          const { key, presignedURL } = response
          const uploadStatus = await s3Upload(presignedURL, pdf)
          if (!uploadStatus) return false
          pdfKeyList.push(key)
        } else {
          toast(TYPE.ERROR, translation.dashboard.admin.jobs.upsert.error.upload)
        }
      }
      callback(pdfKeyList[0])
      setIsProcessing(false)
    }
  }

  return (
    <StyledPDFManager>
      <DragAndDrop
        title={title}
        hint={hint}
        onFileCallback={processNewPDF}
        maxFileSizeInBytes={10000000}
        maxFileSizeExceededToast={translation.dashboard.admin.jobs.upsert.maxFileSizeExceededToast}
        multiple={multiple}
        allowedFileExtensions={['pdf']}
      />
      {isProcessing && <Processing>{translation.dashboard.admin.jobs.upsert.processingPDFLabel}</Processing>}
      {Array.isArray(existingPDFKeys) && existingPDFKeys.length > 0 && (
        <CurrentPDF href={`${s3StorageBucketPublicURLPrefix}${existingPDFKeys[0]}`} target="_blank">
          {translation.dashboard.admin.jobs.upsert.currentPDFDownload}
        </CurrentPDF>
      )}
    </StyledPDFManager>
  )
}

export default PDFManager
