import Layout from '../Layout/Layout'
import Jobs from '../SubComponents/Jobs/Jobs'
import { StyledConcept } from './Concept.Styled'
import Hero from './Hero/Hero'
import PhilosophieAndConcept from './PhilosophieAndConcept/PhilosophieAndConcept'
import Principles from './Principles/Principles.js'

const Concept = () => {
  return (
    <Layout authRequired={false}>
      <StyledConcept>
        <Hero />
        <PhilosophieAndConcept />
        <Principles />
        <Jobs />
      </StyledConcept>
    </Layout>
  )
}

export default Concept
