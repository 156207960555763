import styled from 'styled-components'

export const StyledWhyAndHistory = styled.div`
  padding: 100px 0;
`

export const Items = styled.div`
  display: flex;
  gap: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    gap: 100px;
  }
`
export const StyledItem = styled.a`
  padding: 25px;
  border-radius: 15px;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  &:first-child {
    background-color: ${({ theme }) => theme.color.background.rose};
  }
  &:last-child {
    background-color: ${({ theme }) => theme.color.background.lightblue};
  }
`
export const IconWrapper = styled.div`
  height: 100px;
  position: relative;
`
export const Icon = styled.img`
  height: 200px;
  display: block;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
`
export const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.blue};
  margin-top: 15px;
  font-family: 'bold';
`
export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.blue};
  text-align: center;
  margin-top: 10px;
  text-align: justify;
`
export const ActionHint = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.secondary};
  background-color: ${({ theme }) => theme.color.background.blue};
  padding: 10px 15px;
  border-radius: 20px;
  margin-top: 15px;
  margin: 15px auto 0px;
  width: fit-content;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`

export const Content = styled.div`
  padding: 75px 0px;
  width: 40%;
  margin: 0 auto;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 95%;
    margin-left: 2.5%;
    margin-top: 25px;
    padding: 25px 0px;
  }
`
