import { Container } from '@think-internet/ui-components'
import Layout from '../Layout/Layout'
import { StyledStory, Headline, Text } from './Story.Styled.js'
import { useSelector } from 'react-redux'
import Hero from './Hero/Hero'
import Jobs from '../SubComponents/Jobs/Jobs'
import Accordion from './Accordion/Accordion'

const Story = () => {
  const translation = useSelector((s) => s.TRANSLATION)
  return (
    <Layout authRequired={false}>
      <StyledStory>
        <Hero />
        <Container>
          <Headline>{translation.story.headline}</Headline>
          <Text>{translation.story.text}</Text>
          <Accordion />
        </Container>
        <Jobs />
      </StyledStory>
    </Layout>
  )
}

export default Story
