import styled from 'styled-components'
import { Link } from '@think-internet/ui-components'
import obenRechts from '../../../assets/image/header/bg-top-right.svg'
import obenLinks from '../../../assets/image/header/bg-top-left.svg'

export const StyledHeader = styled.div`
  position: absolute;
  width: 100vw;
  z-index: 999;
  top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const Left = styled.div`
  ${({ mode }) => mode === 'default' && `background-image: url(${obenLinks});`}
  background-position: -40px top;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 10px;
  flex-wrap: wrap;
  padding-left: 20px;
  gap: 50px;
  height: ${({ mode }) => (mode === 'default' ? `400px` : 'fit-content')};
  width: 150px;
  position: fixed;
  z-index: 1002;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    height: 50px;
    background-position: -20px top;
    ${({ mode }) => mode === 'default' && `height: 200px;`}
  }
`

export const Right = styled.div`
  ${({ mode }) => mode === 'default' && `background-image: url(${obenRechts});`}
  background-position: right -20px;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;
  gap: 10px;
  height: 150px;
  width: ${({ mode }) => (mode === 'default' ? `400px` : 'fit-content')};
  position: fixed;
  right: 0;
  z-index: 1001;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    height: 40px;
    background-position: right -10px;
    ${({ mode }) => mode !== 'default' && `display: none;`}
  }
`

export const List = styled.div`
  display: flex;
  align-items: left;
  justify-content: right;
  margin-top: 10px;
  gap: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    margin-top: 5px;
    right: 0;
    position: absolute;
    margin-right: 20px;
  }
`

export const Single = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
  padding: 25px;
`

export const Colaborator = styled.a`
  display: block;
`

export const Social = styled.div`
  gap: 10px;
  margin-top: 30px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    margin-top: 15px;
  }
`

export const Image = styled.img`
  height: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    height: 15px;
  }
`
export const Wrapper = styled.div`
  flex-basis: 100%;
`

export const Breadcrumb = styled.img`
  margin-top: 30px;
  width: 40px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    margin-top: 15px;
    width: 30px;
  }
`

export const SocialMedia = styled.img`
  height: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    margin-top: 5px;
    height: 25px;
  }
`

export const Text = styled.div`
  margin-left: 5px;
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.secondary};
`

export const ItemWrapper = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background-color: ${({ theme }) => theme.color.background.darkBlue};
  z-index: 10000;
  padding: 10px;
`

export const Item = styled.div`
  flex: 1 1 0px;
  margin: 0px 15px;
  color: ${({ theme }) => theme.color.font.primary};
  &:last-child {
    text-align: right;
  }
`

export const StyledToggle = styled.img`
  height: 40px;
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    height: 30px;
  }
`

export const Navigation = styled.div`
  padding: 100px 0px;
  background-color: ${({ theme }) => theme.color.background.primary};
  z-index: 998;
  position: absolute;
  width: 100vw;
  height: calc(100vh - 80px);
  margin-top: 80px;
  top: 0px;
  left: 0px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  align-content: flex-start;
`

export const CustomLink = styled(Link)`
  margin: 10px 0px;
  display: block;
  color: ${({ theme }) => theme.color.font.thirdary};
  ${({ onClick }) => !onClick && 'cursor: default'};
  cursor: pointer;
  ${({ bold }) =>
    bold
      ? `
    font-family: 'bold';
    margin-top: 20px;
  `
      : 'margin-left: 20px;'};
  &:hover {
    ${({ onClick }) => onClick && 'text-decoration: underline;'};
  }
`

export const Logo = styled.img`
  cursor: pointer;
`

export const Follow = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  white-space: nowrap;
  line-height: 53px;
  font-size: ${({ theme }) => theme.font.size.sm};
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: none;
  }
`

export const FollowWrapper = styled.div`
  position: relative;
  width: 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: none;
  }
`

export const FollowRotateWrapper = styled.div`
  /* transform: rotate(90deg);
  transform-origin: 0% 100%; */
  transform: scale(-1) translate(-45%, -100%);
  rotate: 90deg;
  top: -10px;
  position: absolute;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: none;
  }
`

export const CloseWrapper = styled.div`
  text-align: right;
`

export const Close = styled.img`
  width: 20px;
  cursor: pointer;
`

export const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`

export const FollowSmall = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.secondary};
`

export const BackToHome = styled.a`
  z-index: 1003;
  display: block;
  position: fixed;
  top: 45%;
  display: flex;
  gap: 10px;
  align-items: center;
  left: 20px;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.sm};
  text-decoration: none;
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
  &:hover {
    .image {
      filter: invert(1);
    }
  }
`

export const BackIcon = styled.img`
  transition: all 0.2s ease-in-out;
  height: 30px;
  width: 30px;
`
