import { Container } from '@think-internet/ui-components'
import styled from 'styled-components'

export const StyledJobs = styled.div`
  display: flex;
  align-items: center;
  padding: 100px 0;
`

export const Content = styled(Container)`
  display: flex;
  gap: 50px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`

export const ImageSection = styled.div`
  width: 40%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    text-align: center;
  }
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const TextSection = styled.div`
  width: 60%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: 'bold';
`

export const Subheadline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
`

export const Text = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const P = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  text-align: justify;
`

export const CTAWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const CTA = styled.a`
  color: ${({ theme }) => theme.color.font.secondary};
  background-color: ${({ theme }) => theme.color.background.blue};
  font-size: ${({ theme }) => theme.font.size.sm};
  border-radius: 30px;
  padding: 10px 20px;
  display: inline-block;
  margin: 25px auto;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`
