const black = '#1a1919'
const white = '#fafbfd'
const lightgray = '#cdcdcd'
const blue = 'rgb(0,40,73)'
const darkBlue = '#072044'
const red = 'rgb(240,33,51)'
const dirtyWhite = 'rgb(233,218,211)'
const rose = 'rgb(254,184,167)'
const lightblue = 'rgb(183,195,212)'
const orange = '#ea6c50'
const lightorange = 'rgb(254,185,167)'
const midblue = 'rgb(89,131,163)'

const theme = {
  font: {
    size: {
      sm: '16px',
      md: '18px',
      lg: '22px',
      xlg: '30px',
    },
  },
  color: {
    font: {
      primary: blue,
      secondary: white,
      hint: lightgray,
      darkBlue,
      orange: orange,
      lightblue: lightblue,
      midblue,
      lightorange,
      red: red,
    },
    background: {
      primary: dirtyWhite,
      secondary: dirtyWhite,
      thirdary: '#f8b49f',
      darkBlue,
      lightblue,
      orange: orange,
      blue: blue,
      red: red,
      rose: rose,
      white,
      lightorange,
    },
    border: {
      primary: black,
      secondary: white,
      blue,
      darkBlue,
      lightorange,
      orange,
    },
    availability: {
      AVAILABLE: '#7fff7b',
      LIMITED: '#fffe7d',
      UNAVAILABLE: '#f85259',
      default: 'rgb(226,226,226)',
    },
    lighterBlue: '#a7d1f7',
    lightBlue: '#7daed2',
    darkBlue: '#648db4',

    red: '#e5192c',
  },
  breakpoint: {
    sm: '768px',
    md: '992px',
    lg: '1200px',
  },
  border: {
    radius: '5px',
  },
  shadow: '2px 2px 16px -1px rgba(0,0,0,0.48)',
}

const adjustedComponentStyling = {
  accordion: {
    accordion: ``,
    flex: ``,
    item: {
      item: ``,
      header: ``,
      text: ``,
      openicon: ``,
      body: ``,
    },
  },
  button: {
    button: `
    background-color: transparent;
    &.danger {
      background-color: ${theme.color.background.red};
      color: ${theme.color.font.secondary};
      border: none;
    }
    `,
    anchor: ``,
  },
  checkbox: {
    checkbox: ``,
    flex: ``,
    checkedicon: ``,
    label: ``,
    styledcheckbox: ``,
  },
  container: {
    container: ``,
  },
  draganddrop: {
    draganddrop: `
    box-sizing: border-box;
    border-radius: 20px;
    background-color: ${theme.color.background.white};
    border: none;
    `,
    title: ``,
    hint: ``,
    filelist: ``,
    filename: ``,
  },
  dropdown: {
    dropdown: `margin-top: 10px;color: ${theme.color.font.darkBlue}; width: 100%;`,
    select: `border-radius: 20px;border: none;padding: 10px;color: ${theme.color.font.darkBlue};background-color: ${theme.color.background.white};`,
    option: ``,
    label: ``,
  },
  form: {
    form: ``,
  },
  headline: {
    headline: ``,
  },
  hero: {
    hero: ``,
  },
  input: {
    input: `
    border-radius: 20px;
    border: none;
    margin-top: 10px;
    padding: 10px;
    color: ${theme.color.font.darkBlue};
    background-color: ${theme.color.background.white};
    `,
    wrapper: ``,
    label: ``,
  },
  link: {
    link: ``,
  },
  modal: {
    modal: `
    `,
    background: `
    `,
    core: `
    `,
    header: `
    `,
    title: ``,
    close: ``,
    content: ``,
    footer: ``,
  },
  progressbar: {
    progressbar: ``,
    progress: ``,
    label: ``,
  },
  textarea: {
    textarea: `background-color: ${theme.color.background.white};border-radius: 20px; border: none;margin-top: 10px;padding: 10px; color: ${theme.color.font.darkBlue}`,
    wrapper: ``,
    label: ``,
  },
  datepicker: {
    wrapper: ``,
    label: ``,
  },
}

const exportableTheme = { ...theme, components: adjustedComponentStyling }

export default exportableTheme
