import styled from 'styled-components'

export const StyledTrafficLight = styled.div`
  width: fit-content;
  display: flex;
  gap: 7.5px;
  padding: 5px;
  justify-content: space-between;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.color.background.white};
`
export const Light = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${({ theme, color }) => theme.color.availability[color || 'default']};
  border-radius: 30px;
`
