import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { levels } from '../../../../enum'
import { get } from '../../../../interface/crud'
import props from '../../../../redux/props'
import toast, { TYPE } from '../../../../toast'
import Layout from '../../../Layout/Layout'
import { Headline, StyledFamilyCenter, Content } from './FamilyCenter.Styled'
import FamilyCenterUpsertForm from '../../SubComponents/FamilyCenterUpsertForm/FamilyCenterUpsertForm'
import { Container } from '@think-internet/ui-components'

const FamilyCenter = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const token = useSelector((s) => s[props.TOKEN])
  const { uuid } = useParams()
  const [data, setData] = useState(null)

  useEffect(() => {
    const getData = async () => {
      const response = await get(token, props.FAMILY_CENTER, { uuid })
      if (response) {
        setData(response)
      } else {
        toast(TYPE.ERROR, translation.dashboard.manager.familyCenters.error.fetch)
      }
    }
    if (!data && token) getData()
  }, [uuid, token, data, translation])

  return (
    <Layout requiredLevel={levels.MANAGER}>
      {data && (
        <StyledFamilyCenter>
          <Headline>{data.name}</Headline>
          <Content>
            <Container>
              <FamilyCenterUpsertForm data={data} />
            </Container>
          </Content>
        </StyledFamilyCenter>
      )}
    </Layout>
  )
}

export default FamilyCenter
