import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import Layout from '../Layout/Layout'
import { Headline, StyledImprint, Subheadline, P, Link, Strong } from './Imprint.Styled'

const Imprint = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <Layout authRequired={false}>
      <StyledImprint>
        <Container>
          <Headline>{translation.imprint.headline}</Headline>
          <Subheadline>Anbieterkennung nach § 5 TMG</Subheadline>
          <P>
            DRK Kindertageseinrichtungen im Kreis Coesfeld gGmbH
            <br />
            Bahnhofstraße 128
            <br />
            48653 Coesfeld
          </P>

          <P>
            E-Mail: <Link href="mailto:kita-fachberatung@drk-coe.de">kita-fachberatung(at)drk-coe.de</Link>
          </P>

          <P>Haben Sie Fragen? Dann wenden Sie sich bitte an unseren Webmaster:</P>

          <Subheadline>Vertretungsberechtigte</Subheadline>
          <P>Die DRK Kindertageseinrichtung im Kreis Coesfeld gGmbH wird gesetzlich vertreten durch:</P>

          <P>
            <Strong>Christoph Schlütermann, Geschäftsführer</Strong>
          </P>
          <Subheadline>Handelsregister</Subheadline>
          <P>
            Die DRK-Kindertageseinrichtungen im Kreis Coesfeld gGmbH ist im Handelsregister des Amtsgerichts Coesfeld in Abteilung B unter der
            Registernummer 16333 eingetragen.
          </P>
          <Subheadline>Haftungsausschluss</Subheadline>
          <Subheadline>1. Inhalt der Seiten</Subheadline>
          <P>
            Die DRK-Kita gGmbH Coesfeld übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten
            Inhalte. Haftungsansprüche gegen der DRK-Kita gGmbH Coesfeld, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch
            die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen
            verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens der DRK-Kita gGmbH Coesfelde kein nachweislich vorsätzliches oder
            grob fahrlässiges Verschulden vorliegt.
          </P>

          <Subheadline>2. Verweise und Links</Subheadline>
          <P>
            Die Verantwortlichkeit des Diensteanbieters beschränkt sich gemäß § 7 Abs. 1 TMG ausschließlich auf die vom Diensteanbieter
            bereitgehaltenen eigenen Inhalte. Darüber hinaus enthält die Website auch entsprechend gekennzeichnete Verweise oder Links auf Websites
            Dritter. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der verlinkten/verknüpften Seiten hat die DRK-Kita
            gGmbH Coesfeld keinerlei Einfluss. Für den Inhalt der Seite ist allein der jeweilige Anbieter der Seite verantwortlich, weshalb die
            DRK-Kita gGmbH Coesfeld diesbezüglich keine Gewähr übernimmt.
          </P>

          <P>
            Die DRK-Kita gGmbH Coesfeld erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu
            verlinkenden Seiten erkennbar waren. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ohne konkrete Anhaltspunkte einer
            Rechtsverletzung ist jedoch nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden derartige Links umgehend entfernt.
          </P>

          <Subheadline>3. Urheber- und Kennzeichenrecht</Subheadline>
          <P>
            Die DRK-Kita gGmbH Coesfeld ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Bilder, Grafiken, Tondokumente,
            Videosequenzen und Texte zu beachten, von ihm selbst erstellte Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf
            lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen.
          </P>

          <P>
            Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den
            Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der
            bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind!
          </P>

          <P>
            Das Copyright für veröffentlichte, vom der DRK-Kita gGmbH Coesfeld selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts solcher Grafiken,
            Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen bedürfen der schriftlichen Zustimmung des
            Autors. Downloads und Kopien sind nur für den privaten, nicht aber den kommerziellen Gebrauch gestattet.
          </P>

          <Subheadline>4. Datenschutz</Subheadline>
          <P>
            Sofern innerhalb der Website die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten (Mailadressen, Namen, Anschriften)
            besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme und ggf.
            Bezahlung aller angebotenen Dienste ist - soweit technisch möglich und zumutbar - auch ohne Angabe solcher Daten bzw. unter Angabe
            anonymisierter Daten oder eines Pseudonyms gestattet. Eine Weiterleitung an Dritte erfolgt nicht ohne ausdrückliche Zustimmung des Users.
          </P>

          <P>
            Die Nutzung der im Rahmen des Impressums oder vergleichbarer Angaben veröffentlichten Kontaktdaten wie Postanschriften, Telefon- und
            Faxnummern sowie Emailadressen durch Dritte zur Übersendung von nicht ausdrücklich angeforderten Informationen ist nicht gestattet.
            Rechtliche Schritte gegen die Versender von sogenannten Spam-Mails bei Verstößen gegen dieses Verbot sind ausdrücklich vorbehalten.
          </P>

          <Subheadline>5. Rechtswirksamkeit dieses Haftungsausschlusses</Subheadline>
          <P>
            Dieser Haftungsausschluss ist als Teil des Informationsangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile
            oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben
            die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
          </P>
          <Subheadline>6. Hinweis auf EU-Streitschlichtung</Subheadline>
          <P>
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:{' '}
            <Link href="ec.europa.eu/consumers/odr/" target="_blank">
              ec.europa.eu/consumers/odr/
            </Link>{' '}
          </P>

          <P>
            <Strong>Hinweis:</Strong> Dieser Link führt auf die Seite eines anderen Anbieters und Sie verlassen unsere Webseite.
          </P>

          <P>Unsere E-Mail-Adresse finden Sie oben im Impressum</P>

          <P>
            Hinweis auf Verbraucherstreitbeilegung gemäß VSBG
            <br />
            Die DRK-Kita gGmbH Coesfeld nimmt nicht an Streitbeilegungsverfahren vor einer Streitbeilegungsstelle teil.
          </P>

          <P>
            <Link href="/datenschutz">siehe auch „Datenschutz“</Link>
          </P>
        </Container>
      </StyledImprint>
    </Layout>
  )
}

export default Imprint
