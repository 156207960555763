import { StyledHero, Person, Map, PersonContainer } from './Hero.Styled'
import bubble from '../../../../assets/image/kitapiloot/start/bubble.svg'
import map from '../../../../assets/image/kitapiloot/start/map.svg'
import person from '../../../../assets/image/kitapiloot/start/person.svg'
import Animated from '../../../SubComponents/Animated/Animated'

const Hero = () => {
  return (
    <StyledHero bubble={bubble}>
      <PersonContainer>
        <Animated animation="fadeInLeft" duration={2.5}>
          <Person src={person} />
        </Animated>
      </PersonContainer>
      <Map src={map} />
    </StyledHero>
  )
}

export default Hero
