import styled from 'styled-components'

export const StyledDistrictItem = styled.div`
  aspect-ratio: 1 / 1;
  width: calc(100% / 5 - 10px);
  background-image: ${({ bg }) => `url(${bg})`};
  position: relative;
  border-radius: 30px;
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(100% / 2 - 10px);
  }
  ${({ isSlider, theme }) =>
    isSlider &&
    `
    width: 200px;
    height: 200px;
    @media (max-width: ${theme.breakpoint.md}) {
      width: 175px;
      height: 175px;
    }
  `}
  ${({ isSelected, theme }) =>
    isSelected &&
    `
    width: 250px;
    height: 250px;
    @media (max-width: ${theme.breakpoint.md}) {
      width: 225px;
      height: 225px;
    }
  `}
  &:hover {
    background-size: 110% 110%;
    .outline {
      transform: scale(1.15);
    }
  }
`

export const Name = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  position: absolute;
  bottom: 15px;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.color.font.secondary};
`

export const Outline = styled.img`
  max-height: 50%;
  max-width: 80%;
  transition: all 0.3s ease-in-out;
`
