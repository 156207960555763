import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { StyledManagerAssignment, List, User, Headline } from './ManagerAssignment.Styled'
import { getUUID } from '../../../../../utility'
import { list } from '../../../../../redux/action/crud'
import { levels } from '../../../../../enum'

const ManagerAssignment = ({ managingUserUUIDList = [], callback }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const users = useSelector((s) => s[props.USER])
  const dispatch = useDispatch()

  useEffect(() => {
    if (!users) dispatch(list(props.USER, { level: levels.MANAGER }))
  }, [users, dispatch])

  const toggleUser = (uuid) => () => {
    if (managingUserUUIDList.includes(uuid)) {
      callback(managingUserUUIDList.filter((u) => u !== uuid))
    } else {
      callback([...managingUserUUIDList, uuid])
    }
  }

  if (!Array.isArray(users)) return false
  return (
    <StyledManagerAssignment>
      <Headline>{translation.dashboard.admin.locations.upsert.managerAssignment.headline}</Headline>
      <List>
        {users
          .filter((u) => u.level === levels.MANAGER)
          .map((u) => (
            <User
              className={managingUserUUIDList.includes(u.uuid) ? 'selected' : ''}
              key={getUUID()}
              onClick={toggleUser(u.uuid)}
            >{`${u.firstname} ${u.lastname} (${u.mail})`}</User>
          ))}
      </List>
    </StyledManagerAssignment>
  )
}

export default ManagerAssignment
