import { Button, Input } from '@think-internet/ui-components'
import { useState } from 'react'
import { StyledUpsertForm, CTASection, ImageManagerWrapper } from './UpsertForm.Styled'
import { useDispatch, useSelector } from 'react-redux'
import { remove, list } from '../../../../../redux/action/crud'
import toast, { TYPE } from '../../../../../toast'
import { upsert } from '../../../../../interface/crud'
import props from '../../../../../redux/props'
import ImageManager from './ImageManager/ImageManager'
import LocationAssignment from './LocationAssignment/LocationAssignment'
import PlacePrediction from '../../../../SubComponents/PlacePrediction/PlacePrediction'

const UpsertForm = ({ data: initData, callback }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState(initData || {})
  const token = useSelector((s) => s[props.TOKEN])
  const translation = useSelector((s) => s[props.TRANSLATION])

  const removeHandler = async () => {
    await dispatch(remove(props.DISTRICT, { uuid: data.uuid }))
    if (callback) callback()
  }

  const updateProp = (prop) => (value) => setData({ ...data, [prop]: value })

  const submit = async () => {
    const district = await upsert(token, props.DISTRICT, data, ['uuid'])
    if (district) {
      toast(TYPE.SUCCESS, translation.dashboard.admin.districts.upsert.success.submit)
      dispatch(list(props.DISTRICT))
      if (callback) callback()
    } else {
      toast(TYPE.ERROR, translation.dashboard.admin.districts.upsert.error.submit)
    }
  }

  return (
    <StyledUpsertForm>
      <Input placeholder={translation.generic.name} value={data.name} onChange={updateProp('name')} required />
      <PlacePrediction
        initInput={data.fullLocationString}
        set={(googlePrediction) => updateProp('googlePlaceId')(googlePrediction ? googlePrediction.place_id : null)}
        placeholder={translation.generic.address}
        required
      />
      <LocationAssignment locationUUIDList={data.locationUUIDList} callback={updateProp('locationUUIDList')} />
      <ImageManagerWrapper>
        {/* <ImageManager
          multiple={false}
          existingImageKeys={data.outlineImageKey ? [data.outlineImageKey] : []}
          callback={updateProp('outlineImageKey')}
          title={translation.dashboard.admin.districts.upsert.outline.title}
          hint={translation.dashboard.admin.districts.upsert.outline.hint}
        /> */}
        <ImageManager
          multiple={false}
          existingImageKeys={data.bgImageKey ? [data.bgImageKey] : []}
          callback={updateProp('bgImageKey')}
          title={translation.dashboard.admin.districts.upsert.bg.title}
          hint={translation.dashboard.admin.districts.upsert.bg.hint}
        />
      </ImageManagerWrapper>
      <CTASection singleItem={!initData?.uuid}>
        {initData?.uuid && <Button text={translation.generic.remove} onClick={removeHandler} />}
        <Button text={translation.generic.save} onClick={submit} />
      </CTASection>
    </StyledUpsertForm>
  )
}

export default UpsertForm
