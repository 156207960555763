import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStyle from './Global.Styled'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import Login from './components/Login/Login'
import './global.scss'
import Home from './components/Home/Home.js'
import Concept from './components/Concept/Concept.js'
import ManagingDirectors from './components/ManagingDirectors/ManagingDirectors.js'
import Principles from './components/Principles/Principles.js'
import Contact from './components/Contact/Contact.js'
import Story from './components/Story/Story.js'
import ReasonOverview from './components/ReasonOverview/ReasonOverview.js'
import ReasonPages from './components/ReasonPages/ReasonPages.js'
import News from './components/News/News.js'
import Application from './components/Application/Application.js'
import Dashboard from './components/Dashboard/Dashboard'
import Kitapiloot from './components/Kitapiloot/Kitapiloot.js'
import KitaOverview from './components/KitaOverview/KitaOverview.js'
import Download from './components/Download/Download.js'
import DownloadThankYou from './components/DownloadThankYou/DownloadThankYou.js'
import ContactPerson from './components/ContactPerson/ContactPerson.js'
import PageNotFound from './components/PageNotFound/PageNotFound.js'
import Imprint from './components/Imprint/Imprint.js'
import DataProtection from './components/DataProtection/DataProtection'
import Consent from './components/Layout/Consent/Consent'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Consent />
        <GlobalStyle />
        <Router>
          <Routes>
            <Route index element={<Home />} />
            <Route path={'konzept'} element={<Concept />} />
            <Route path={'geschaeftsfuehrung'} element={<ManagingDirectors />} />
            <Route path={'grundsaetze'} element={<Principles />} />
            <Route path={'kontakt'} element={<Contact />} />
            <Route path={'infomaterial'} element={<Download />} />
            <Route path={'infomaterial-danke'} element={<DownloadThankYou />} />
            <Route path={'ansprechpartner'} element={<ContactPerson />} />
            <Route path={'geschichte'} element={<Story />} />
            <Route path={'gruende-uebersicht'} element={<ReasonOverview />} />
            <Route path={'gruende/*'} element={<ReasonPages />} />
            <Route path={'news'} element={<News />} />
            <Route path={'bewerbung'} element={<Application />} />
            <Route path={'kitapiloot/*'} element={<Kitapiloot />} />
            <Route path={'kitauebersicht/*'} element={<KitaOverview />} />
            <Route path={'impressum'} element={<Imprint />} />
            <Route path={'datenschutz'} element={<DataProtection />} />
            <Route path={'login'} element={<Login />} />
            <Route path={'dashboard/*'} element={<Dashboard />} />
            <Route path={'404'} element={<PageNotFound />} />
            <Route path={'*'} element={<Navigate replace to="/404" />} />
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </div>
  )
}

export default App
