import { StyledItem, Text, Arrow } from './Item.Styled.js'

const Item = ({ href, onClick, text, reverse, margin }) => {
  return (
    <StyledItem
      margin={margin}
      reverse={reverse}
      href={href}
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
    >
      <Text reverse={reverse}>{text}</Text>
      <Arrow reverse={reverse} />
    </StyledItem>
  )
}

export default Item
