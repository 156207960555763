import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Arrow from '../../SubComponents/ArrowRedDownAnimated/ArrowRedDownAnimated'
import { StyledHero, Content, Headline, Subheadline, ImageWrapper, Image } from './Hero.Styled'
import image from '../../../assets/image/contactPerson/hero/image.svg'
import Animated from '../../SubComponents/Animated/Animated'

const Hero = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <StyledHero>
      <ImageWrapper>
        <Animated animation="fadeIn" duration={2.5}>
          <Image src={image} />
        </Animated>
      </ImageWrapper>
      <Content>
        <Headline duration="2.5" animation="fadeIn" delay={3}>
          {translation.contactPerson.hero.headline}
        </Headline>
        <Subheadline duration="2.5" animation="fadeIn" delay={3}>
          {translation.contactPerson.hero.subheadline}
        </Subheadline>
        <Arrow />
      </Content>
    </StyledHero>
  )
}

export default Hero
