import { Container } from '@think-internet/ui-components'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { levels } from '../../../../enum'
import { list } from '../../../../interface/crud'
import props from '../../../../redux/props'
import toast, { TYPE } from '../../../../toast'
import Layout from '../../../Layout/Layout'
import { FamilyCenter, Name, StyledFamilyCenters, Headline, List } from './FamilyCenters.Styled.js'

const FamilyCenters = () => {
  const token = useSelector((s) => s[props.TOKEN])
  const translation = useSelector((s) => s[props.TRANSLATION])
  const navigate = useNavigate()

  const [familyCenters, setFamilyCenters] = useState(null)

  useEffect(() => {
    const fetchFamilyCenters = async () => {
      const response = await list(token, props.FAMILY_CENTER)
      if (response) {
        if (response.length === 1) {
          navigate(`/dashboard/manager/family-center/${response[0].uuid}`)
        } else {
          setFamilyCenters(response)
        }
      } else {
        toast(TYPE.ERROR, translation.dashboard.manager.familyCenters.error.fetch)
      }
    }
    if (token && !familyCenters) fetchFamilyCenters()
  }, [familyCenters, token, translation, navigate])

  const goTo = (uuid) => () => {
    navigate(`/dashboard/manager/family-center/${uuid}`)
  }

  return (
    <Layout requiredLevel={levels.MANAGER}>
      <StyledFamilyCenters>
        <Headline>{translation.dashboard.manager.familyCenters.headline}</Headline>
        <Container>
          <List>
            {Array.isArray(familyCenters) &&
              familyCenters.map((familyCenter) => (
                <FamilyCenter key={familyCenter.uuid}>
                  <Name onClick={goTo(familyCenter.uuid)}>{familyCenter.name}</Name>
                </FamilyCenter>
              ))}
          </List>
        </Container>
      </StyledFamilyCenters>
    </Layout>
  )
}

export default FamilyCenters
