import styled from 'styled-components'

export const StyledReasonOverview = styled.div`
  background-color: ${({ theme }) => theme.color.background.primary};
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 100px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding-top: 150px;
  }
`

export const Boxes = styled.div`
  display: flex;
  gap: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    gap: 100px;
  }
`

export const Box = styled.a`
  width: 100%;
  background-color: ${({ theme }) => theme.color.background.lightblue};
  padding: 35px 25px;
  border-radius: 15px;
  box-sizing: border-box;
  text-decoration: none;
  transition: all 0.8s ease-in-out;
  &:hover,
  &:hover .image-wrapper {
    transform: scale(1.05);
  }
`

export const ImageWrapper = styled.div`
  height: 200px;
  position: relative;
  transition: all 0.3s ease-in-out;
`

export const Image = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
  height: 300px;
`

export const BoxHeadline = styled.div`
  font-family: bold;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.lg};
  text-align: center;
  margin-top: 25px;
`

export const BoxSubheadline = styled.div`
  text-align: center;
  .keyword {
    font-family: bold;
  }
`

export const Headline = styled.div`
  font-family: bold;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.xlg};
  text-align: center;
  margin-top: 50px;
`

export const Subheadline = styled.div`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.xlg};
  text-align: center;
`
