import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { StyledAccordion, StyledAccordionItem, Indicator, Header, Title, Subtitle, Content } from './Accordion.Styled'
import Solferino from './Solferino/Solferino'
import RememberSolferino from './RememberSolferino/RememberSolferino'
import Truth from './Truth/Truth'
import up from '../../../assets/image/generic/arrow-blue-up.svg'
import down from '../../../assets/image/generic/arrow-blue-down.svg'
import { getUUID } from '../../../utility'

const AccordionItem = ({ title, subtitle, component, isOpen, openCallback }) => {
  return (
    <StyledAccordionItem>
      <Header onClick={openCallback}>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        {isOpen && <Indicator className="indicator" src={up} />}
        {!isOpen && <Indicator src={down} className="indicator" />}
      </Header>
      {isOpen && <Content>{component}</Content>}
    </StyledAccordionItem>
  )
}

const Accordion = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [openIndex, setOpenIndex] = useState(null)
  const accordionComponents = [<Solferino />, <RememberSolferino />, <Truth />]

  const open = (index) => () => setOpenIndex(openIndex === index ? null : index)

  return (
    <StyledAccordion>
      {translation.story.accordion.items.map((item, i) => (
        <AccordionItem
          isOpen={i === openIndex}
          openCallback={open(i)}
          title={item.title}
          subtitle={item.subtitle}
          component={accordionComponents[i]}
          key={getUUID()}
        />
      ))}
    </StyledAccordion>
  )
}

export default Accordion
