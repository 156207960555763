import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import { levels } from '../../../../enum'
import props from '../../../../redux/props'
import Layout from '../../../Layout/Layout'
import { StyledNews, Headline } from './News.Styled'
import Create from './Create/Create'
import List from './List/List'

const News = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <Layout requiredLevel={[levels.ADMIN, levels.MANAGER]}>
      <StyledNews>
        <Headline>{translation.dashboard.sharedPages.news.headline}</Headline>
        <Container>
          <Create />
          <List />
        </Container>
      </StyledNews>
    </Layout>
  )
}

export default News
