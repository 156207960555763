import { Container } from '@think-internet/ui-components'
import Layout from '../Layout/Layout'
import { StyledNews, Entries } from './News.Styled.js'
import Hero from './Hero/Hero'
import { getUUID } from '../../utility'
import Entry from './Entry/Entry'
import Animated from '../SubComponents/Animated/Animated'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../redux/props'
import { list } from '../../redux/action/crud'
import { useEffect } from 'react'

export const News = () => {
  const dispatch = useDispatch()
  const articles = useSelector((s) => s[props.NEWS_ARTICLE])

  useEffect(() => {
    if (!Array.isArray(articles)) dispatch(list(props.NEWS_ARTICLE, {}, true))
  })

  if (!Array.isArray(articles)) return <></>
  return (
    <Layout authRequired={false}>
      <StyledNews>
        <Hero />
        <Container>
          <Entries>
            {articles.map((e) => (
              <Animated animation={'fadeIn'} key={getUUID()}>
                <Entry entry={e} />
              </Animated>
            ))}
          </Entries>
        </Container>
      </StyledNews>
    </Layout>
  )
}

export default News
