import { Route, Routes, Navigate } from 'react-router-dom'
import Users from './Users/Users'
import Locations from './Locations/Locations'
import FamilyCenter from './FamilyCenter/FamilyCenter'
import News from '../SharedPages/News/News'
import Districts from './Districts/Districts'
import Jobs from './Jobs/Jobs'

const Dashboard = () => {
  return (
    <Routes>
      <Route path={'users'} element={<Users />} />
      <Route path={'locations'} element={<Locations />} />
      <Route path={'family-center'} element={<FamilyCenter />} />
      <Route path={'news'} element={<News />} />
      <Route path={'districts'} element={<Districts />} />
      <Route path={'jobs'} element={<Jobs />} />
      <Route path={'*'} element={<Navigate replace to="users" />} />
    </Routes>
  )
}

export default Dashboard
