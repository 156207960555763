import Layout from '../Layout/Layout'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import { StyledDownloadThankYou, Content, Headline, Subheadline, ImageWrapper, Image, CTA, CTAWrapper } from './DownloadThankYou.Styled'
import { getUUID } from '../../utility'
import image from '../../assets/image/download-thank-you/image.svg'
import wende_dich_an_uns_button from '../../assets/image/download-thank-you/wende_dich_an_uns_button.svg'
import zur_startseite_button from '../../assets/image/download-thank-you/zur_startseite_button.svg'
import Animated from '../SubComponents/Animated/Animated'

const DownloadThankYou = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <Layout authRequired={false}>
      <StyledDownloadThankYou>
        <ImageWrapper>
          <Animated animation="fadeIn" duration={2.5}>
            <Image src={image} />
          </Animated>
        </ImageWrapper>
        <Content>
          <Headline duration="2.5" animation="fadeIn" delay={3}>
            {translation.downloadThankYou.headline}
          </Headline>
          <Subheadline duration="5" animation="fadeIn">
            {translation.downloadThankYou.subheadline.map((s, i) => (
              <span key={getUUID()} style={{ fontFamily: i === 1 ? 'bold' : 'regular' }}>
                {s}
              </span>
            ))}
          </Subheadline>
          <CTAWrapper>
            <a href="/">
              <CTA src={zur_startseite_button} />
            </a>
            <a href="/kontakt">
              <CTA src={wende_dich_an_uns_button} />
            </a>
          </CTAWrapper>
        </Content>
      </StyledDownloadThankYou>
    </Layout>
  )
}

export default DownloadThankYou
