import { StyledHero, Headline, Subheadline, LogoWrapper, Icon, Logo } from './Hero.Styled'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'
import headlineBubble from '../../../assets/image/kitaoverview/hero/headlineBubble.svg'
import logoIcon from '../../../assets/image/generic/kitaoverview.svg'
import logo from '../../../assets/image/generic/kitaoverviewName.svg'
import BubbleBackgroundFloat from '../../SubComponents/BubbleBackgroundFloat/BubbleBackgroundFloat'

const Hero = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledHero>
      <BubbleBackgroundFloat bubble={headlineBubble} height="150%">
        <LogoWrapper>
          <Icon src={logoIcon} />
          <Logo src={logo} />
        </LogoWrapper>
      </BubbleBackgroundFloat>
      <Headline>{translation.kitaoverview.start.headline}</Headline>
      <Subheadline>{translation.kitaoverview.start.subheadline}</Subheadline>
    </StyledHero>
  )
}

export default Hero
