import styled from 'styled-components'

export const StyledSlider = styled.div`
  width: 100%;
`

export const ActiveImage = styled.div`
  width: 100%;
  height: 300px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  border-radius: 30px;
`
export const ActiveVideoWrapper = styled.div`
  width: 100%;
  height: 300px;
`

export const ActiveVideo = styled.video`
  max-width: 100%;
  max-height: 100%;
`

export const Navigator = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
`

export const Move = styled.div`
  cursor: pointer;
  height: 20px;
  width: 20px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
`

export const Thumbnails = styled.div`
  display: flex;
  gap: 3px;
`

export const VideoThumbnail = styled.video`
  cursor: pointer;
  height: 75px;
  width: 75px;
  ${({ mode, active, theme }) => {
    if (mode === 'kitaoverview') {
      return `border: 3px solid ${active ? theme.color.border.orange : theme.color.border.lightorange};`
    }
    return `border: 3px solid ${active ? theme.color.border.lightorange : theme.color.border.darkBlue};`
  }}
  border-radius: 30px;
`

export const Thumbnail = styled.div`
  cursor: pointer;
  height: 75px;
  width: 75px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  ${({ mode, active, theme }) => {
    if (mode === 'kitaoverview') {
      return `border: 3px solid ${active ? theme.color.border.orange : theme.color.border.lightorange};`
    }
    return `border: 3px solid ${active ? theme.color.border.lightorange : theme.color.border.darkBlue};`
  }}
  border-radius: 30px;
`

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
`

export const Dot = styled.div`
  height: 7.5px;
  width: 7.5px;
  border-radius: 10px;
  background-color: ${({ theme, active }) => (active ? theme.color.background.orange : theme.color.background.white)};
  cursor: pointer;
`
