import { Button, Dropdown, Input, Textarea } from '@think-internet/ui-components'
import { useState } from 'react'
import { StyledLocationUpsertForm, CTASection } from './LocationUpsertForm.Styled'
import { useDispatch, useSelector } from 'react-redux'
import { remove, list } from '../../../../redux/action/crud'
import toast, { TYPE } from '../../../../toast'
import { upsert } from '../../../../interface/crud'
import props from '../../../../redux/props'
import ImageVideoManager from './ImageVideoManager/ImageVideoManager'
import ManagerAssignment from './ManagerAssignment/ManagerAssignment'
import PlacePrediction from '../../../SubComponents/PlacePrediction/PlacePrediction'
import { levels, location_availability } from '../../../../enum'
import InfoPDFManager from '../InfoPDFManager/InfoPDFManager'
import routes from '../../../../redux/routes'

const LocationUpsertForm = ({ data: initData, callback }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState(initData || {})
  const token = useSelector((s) => s[props.TOKEN])
  const account = useSelector((s) => s[props.ACCOUNT])
  const translation = useSelector((s) => s[props.TRANSLATION])

  const removeHandler = async () => {
    await dispatch(remove(props.LOCATION, { uuid: data.uuid }))
    if (callback) callback()
  }

  const updateProp = (prop) => (value) => setData({ ...data, [prop]: value })

  const submit = async () => {
    const location = await upsert(token, props.LOCATION, data, ['uuid'])
    if (location) {
      toast(TYPE.SUCCESS, translation.dashboard.admin.locations.upsert.success.submit)
      dispatch(list(props.LOCATION))
      if (callback) callback()
    } else {
      toast(TYPE.ERROR, translation.dashboard.admin.locations.upsert.error.submit)
    }
  }

  const availabilityItems = Object.keys(location_availability).map((c) => ({ value: translation.generic.availability[c], key: c }))

  const isAdmin = () => account.level === levels.ADMIN

  return (
    <StyledLocationUpsertForm>
      <Input label={translation.generic.name} placeholder={translation.generic.name} value={data.name} onChange={updateProp('name')} required />
      <PlacePrediction
        initInput={data.fullLocationString}
        set={(googlePrediction) => updateProp('googlePlaceId')(googlePrediction ? googlePrediction.place_id : null)}
        placeholder={translation.generic.address}
        required
      />
      <Input
        label={translation.generic.contactPerson}
        placeholder={translation.generic.contactPerson}
        value={data.contactPerson}
        onChange={updateProp('contactPerson')}
      />
      <Input
        label={translation.generic.title}
        placeholder={translation.generic.title}
        value={data.contactPersonTitle}
        onChange={updateProp('contactPersonTitle')}
      />
      <Input label={translation.generic.mail} placeholder={translation.generic.mail} value={data.mail} onChange={updateProp('mail')} />
      <Input label={translation.generic.phone} placeholder={translation.generic.phone} value={data.phone} onChange={updateProp('phone')} />
      <Input
        label={translation.generic.virtualViewingLink}
        placeholder={translation.generic.virtualViewingLink}
        value={data.virtualViewingLink}
        onChange={updateProp('virtualViewingLink')}
      />
      <Input
        label={translation.generic.externalSignupLink}
        placeholder={translation.generic.externalSignupLink}
        value={data.externalSignupLink}
        onChange={updateProp('externalSignupLink')}
      />
      <Textarea
        rows="10"
        label={translation.generic.description}
        placeholder={translation.generic.description}
        value={data.description}
        onChange={updateProp('description')}
      />
      <Dropdown
        label={translation.dashboard.admin.locations.upsert.availability.headline}
        emptySelectionLabel={translation.dashboard.admin.locations.upsert.availability.headline}
        items={availabilityItems}
        value={data.availability}
        onChange={updateProp('availability')}
      />
      {isAdmin() && <ManagerAssignment managingUserUUIDList={data.managingUserUUIDList} callback={updateProp('managingUserUUIDList')} />}
      <ImageVideoManager
        existingAssetKeys={data.galleryImageKeys}
        callback={updateProp('galleryImageKeys')}
        title={translation.dashboard.admin.locations.upsert.gallery.title}
        hint={translation.dashboard.admin.locations.upsert.gallery.hint}
        additionalFileExtensions={['mp4', 'webm', 'mpeg']}
      />
      <InfoPDFManager
        route={routes.get_location_asset_upload_url}
        title={translation.dashboard.admin.locations.upsert.conceptInfo.title}
        hint={translation.dashboard.admin.locations.upsert.conceptInfo.hint}
        existingPDFKey={data.conceptInfoFileKey}
        callback={updateProp('conceptInfoFileKey')}
      />
      {isAdmin() && (
        <ImageVideoManager
          multiple={false}
          existingAssetKeys={
            data.contactPersonImageKey ? (Array.isArray(data.contactPersonImageKey) ? data.contactPersonImageKey : [data.contactPersonImageKey]) : []
          }
          callback={updateProp('contactPersonImageKey')}
          title={translation.dashboard.admin.locations.upsert.contactPerson.title}
          hint={translation.dashboard.admin.locations.upsert.contactPerson.hint}
        />
      )}
      <CTASection singleItem={!isAdmin() || !initData?.uuid}>
        {isAdmin() && initData?.uuid && <Button text={translation.generic.remove} onClick={removeHandler} />}
        <Button text={translation.generic.save} onClick={submit} />
      </CTASection>
    </StyledLocationUpsertForm>
  )
}

export default LocationUpsertForm
