const routes = {
  get_location_asset_upload_url: 'get_location_asset_upload_url',
  contact: 'contact',
  application: 'application',
  application_upload_url: 'application_upload_url',
  geo_coordinates_to_address: 'geo_coordinates_to_address',
  get_location_predictions: 'get_location_predictions',
  get_kita_results_by_google_place_id_and_radius: 'get_kita_results_by_google_place_id_and_radius',
  get_news_article_image_upload_url: 'get_news_article_image_upload_url',
  get_district_image_upload_url: 'get_district_image_upload_url',
  get_kita_results_by_district: 'get_kita_results_by_district',
  get_job_pdf_upload_url: 'get_job_pdf_upload_url',
  get_family_center_pdf_upload_url: 'get_family_center_pdf_upload_url',
  get_family_center_zip: 'get_family_center_zip',
}

export default routes
