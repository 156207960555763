import { Dropdown, Input, Textarea } from '@think-internet/ui-components'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import call from '../../../interface/functional.js'
import routes from '../../../redux/routes.js'
import { StyledContactForm, CTA } from './ContactForm.Styled.js'

const ContactForm = () => {
  const [body, setBody] = useState({})
  const translation = useSelector((s) => s.TRANSLATION)

  const updateProp = (prop) => (value) => {
    setBody({ ...body, [prop]: value })
  }

  const send = async () => {
    const status = await call(null, routes.contact, body)
    if (status) {
      setBody({})
    }
  }

  const categoryItems = translation.contact.form.categories.map((c) => ({ value: c, key: c }))

  return (
    <StyledContactForm>
      <Input value={body.firstName} onChange={updateProp('firstName')} placeholder={translation.contact.form.firstname} />
      <Input value={body.lastName} onChange={updateProp('lastName')} placeholder={translation.contact.form.lastname} />
      <Input value={body.email} onChange={updateProp('email')} placeholder={translation.contact.form.mail} />
      <Dropdown
        emptySelectionLabel={translation.contact.form.category}
        items={categoryItems}
        value={body.category}
        onChange={updateProp('category')}
      />
      <Textarea rows="5" value={body.message || ''} onChange={updateProp('message')} placeholder={translation.contact.form.message} />
      {/* <CustomCheckbox checked={body.newsletter} onChange={updateProp('newsletter')} label={translation.contact.form.newsletter} /> */}
      <CTA onClick={send}>{translation.contact.form.cta}</CTA>
    </StyledContactForm>
  )
}

export default ContactForm
