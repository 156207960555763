import styled from 'styled-components'

export const StyledPageNotFound = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.background.lightblue};
`

export const Content = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  height: 100%;
`

export const Image = styled.img`
  height: 500px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: auto;
    width: 100%;
  }
`

export const Headline = styled.div`
  font-size: 100px;
  font-family: 'bold';
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 50px;
  }
`

export const Subheadline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: 'bold';
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`
