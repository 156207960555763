import { Accordion } from '@think-internet/ui-components'
import styled from 'styled-components'

export const StyledList = styled.div`
  margin-top: 50px;
`

export const CustomAccordion = styled(Accordion)`
  margin-top: 10px;
`

export const Headline = styled.div`
  font-family: 'bold';
`
