import styled from 'styled-components'

export const StyledLayout = styled.div``

export const CoreContent = styled.div`
  ${({ mode, theme }) => mode === 'default' && `background-color: ${theme.color.background.primary};`}
  ${({ mode, theme }) => mode === 'kitapiloot' && `background-color: ${theme.color.background.lightblue};`}
  ${({ mode, theme }) => mode === 'kitaoverview' && `background-color: ${theme.color.background.lightblue};`}
  min-height: calc(100vh - ${({ hideHeader }) => (hideHeader ? '0px' : '80px')});
  margin: ${({ sideMenuItems }) => (sideMenuItems ? '0px 0px 50px 200px' : '0px')};
  /* margin-top: ${({ hideHeader }) => (hideHeader ? '0px' : '80px')}; */
  /* padding-top: ${({ hideHeader }) => (hideHeader ? '0px' : '50px')}; */
`
