import Layout from '../Layout/Layout'
import illustation from '../../assets/image/principles/illustation.svg'
import { Container } from '@think-internet/ui-components'
import { StyledPrinciples, Illustration, Content, CustomWave } from './Principles.Styled.js'
import Hero from './Hero/Hero'
import Animated from '../SubComponents/Animated/Animated'
import Jobs from '../SubComponents/Jobs/Jobs'
import Cards from './Cards/Cards'

const Principles = () => {
  return (
    <Layout authRequired={false}>
      <StyledPrinciples>
        <Hero />
        <Content>
          <Container>
            <Animated animation="fadeIn" duration={2.5}>
              <Illustration src={illustation} />
            </Animated>
            <Cards />
          </Container>
        </Content>
        <CustomWave width="200%" backgroundColor={'white'} />
        <Jobs />
      </StyledPrinciples>
    </Layout>
  )
}

export default Principles
