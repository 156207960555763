import Create from './Create/Create'
import List from './List/List'
import { Container } from '@think-internet/ui-components'
import Layout from '../../../Layout/Layout'
import { levels } from '../../../../enum'
import { Headline, StyledJobs } from './Jobs.Styled'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'

const Jobs = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <Layout requiredLevel={levels.ADMIN}>
      <StyledJobs>
        <Headline>{translation.dashboard.admin.jobs.headline}</Headline>
        <Container>
          <Create />
          <List />
        </Container>
      </StyledJobs>
    </Layout>
  )
}

export default Jobs
