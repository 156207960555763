import styled from 'styled-components'

export const StyledInfoPDFManager = styled.div`
  width: 100%;
`
export const Processing = styled.div`
  text-align: center;
  margin: 10px 0;
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const RemoveButton = styled.div`
  margin: 5px auto 10px;
  margin-left: auto;
  color: ${({ theme }) => theme.color.font.red};
  width: fit-content;
  cursor: pointer;
`
