import { StyledArrowRedDownAnimated, Icon } from './ArrowRedDownAnimated.Styled'
import arrowDown from '../../../assets/image/generic/arrowDown.svg'

const ArrowRedDownAnimated = () => {
  return (
    <StyledArrowRedDownAnimated>
      <Icon duration="2.5" animation="float" src={arrowDown} />
    </StyledArrowRedDownAnimated>
  )
}

export default ArrowRedDownAnimated
