import styled from 'styled-components'

export const StyledGoogleMap = styled.div`
  height: ${({ height }) => height || '500px'};
  width: ${({ width }) => width || '100%'};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: ${({ height }) => height || '250px'};
  }
`

export const StyledMarker = styled.div``

export const ImageWrapper = styled.div``

export const MarkerIcon = styled.img`
  height: 50px;
  width: 35px;
  position: relative;
  top: -50px;
  left: -17.5px;
`

export const Overlay = styled.div`
  z-index: 99999;
  position: absolute;
  width: 1000px;
  text-align: left;
  width: 150px;
  background-color: ${({ theme }) => theme.color.background.thirdary};
  border-radius: ${({ theme }) => theme.border.radius};
  padding: 10px;
`

export const Name = styled.div``

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
`

export const CloseIcon = styled.img`
  width: 15px;
`
