import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import { StyledLocationAssignment, List, Location, Headline } from './LocationAssignment.Styled'
import { getUUID } from '../../../../../../utility'
import { list } from '../../../../../../redux/action/crud'
import { useCallback } from 'react'

const LocationAssignment = ({ locationUUID = null, callback }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const locations = useSelector((s) => s[props.LOCATION])
  const dispatch = useDispatch()

  const hasMultipleLocations = useCallback(() => {
    return Array.isArray(locations) && locations.length > 1
  }, [locations])

  useEffect(() => {
    if (!locations) dispatch(list(props.LOCATION))
    if (!hasMultipleLocations() && Array.isArray(locations) && !locationUUID) {
      callback(locations[0].uuid)
    }
  }, [locations, dispatch, callback, locationUUID, hasMultipleLocations])

  const toggleLocation = (uuid) => () => {
    callback(uuid === locationUUID ? null : uuid)
  }

  if (!hasMultipleLocations()) return <></>
  return (
    <StyledLocationAssignment>
      <Headline>{translation.dashboard.sharedPages.news.upsert.locationAssignment.headline}</Headline>
      <List>
        {locations.map((l) => (
          <Location className={locationUUID === l.uuid ? 'selected' : ''} key={getUUID()} onClick={toggleLocation(l.uuid)}>
            {l.name}
          </Location>
        ))}
      </List>
    </StyledLocationAssignment>
  )
}

export default LocationAssignment
