import styled from 'styled-components'
import Wave from '../SubComponents/Wave/Wave'

export const StyledPrinciples = styled.div``

export const Content = styled.div`
  background-color: ${({ theme }) => theme.color.background.white};
  padding: 50px 0px;
`

export const Illustration = styled.img`
  max-width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    max-width: 100%;
  }
`

export const CustomWave = styled(Wave).attrs(({ theme }) => ({ backgroundColor: theme.color.background.white }))``
