import styled from 'styled-components'
import Animated from '../Animated/Animated'

export const StyledArrowRedDownAnimated = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

export const Icon = styled(Animated)`
  width: 30px;
  height: 30px;
  background-image: url(${({ src }) => src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`
