import { StyledReasonPageLayout, Content, Headline, Reasons, Reason, Image, SlimContainer } from './ReasonPageLayout.Styled'
import Layout from '../../Layout/Layout'
import thoughtsImage from '../../../assets/image/reasonsPages/thoughts.svg'
import bubble1 from '../../../assets/image/reasonsPages/bubble1.svg'
import bubble2 from '../../../assets/image/reasonsPages/bubble2.svg'
import ContactForm from '../../SubComponents/ContactForm/ContactForm'
import Hero from './Hero/Hero'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { getUUID } from '../../../utility'
import BubbleBackgroundFloat from '../../SubComponents/BubbleBackgroundFloat/BubbleBackgroundFloat'

const ReasonPageLayout = ({ heroImage, heroSubline, heroSublineColorKey, headline, reasonsArray }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <Layout authRequired={false}>
      <StyledReasonPageLayout>
        <Hero image={heroImage} subline={heroSubline} sublineColorKey={heroSublineColorKey} />
        <SlimContainer>
          <Content>
            <BubbleBackgroundFloat bubble={bubble1} height="500%" originY="-50" originX={-50}>
              {headline.map((h, i) => (
                <Headline disabledOnMobile animation={'typing'} duration="3" delay={i * 2} key={getUUID()}>
                  {h}
                </Headline>
              ))}
            </BubbleBackgroundFloat>
            <BubbleBackgroundFloat bubble={bubble2} height="50%" originY="100">
              <Reasons>
                {reasonsArray.map((reason, index) => (
                  <Reason key={index} animation="fadeInLeft" duration="1">
                    {`${index + 1}. ${reason}`}
                  </Reason>
                ))}
              </Reasons>
            </BubbleBackgroundFloat>
          </Content>
          <Image src={thoughtsImage} />
          {translation.reasonPages.thoughtsHeadlines.map((h, i) => (
            <Headline disabledOnMobile animation={'typing'} duration="2" delay={i * 1.5} key={getUUID()}>
              {h}
            </Headline>
          ))}
          <ContactForm />
        </SlimContainer>
      </StyledReasonPageLayout>
    </Layout>
  )
}

export default ReasonPageLayout
