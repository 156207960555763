import styled from 'styled-components'

export const StyledLocationAssignment = styled.div`
  width: 100%;
`

export const List = styled.div`
  background-color: ${({ theme }) => theme.color.background.white};
  height: 150px;
  border-radius: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 5px;
`
export const Location = styled.div`
  cursor: pointer;
  padding: 10px 15px;
  &:hover {
    font-family: 'bold';
  }
  &.selected {
    background-color: ${({ theme }) => theme.color.background.thirdary};
  }
`

export const Headline = styled.div``
