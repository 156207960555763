import styled, { keyframes } from 'styled-components'

// https://www.youtube.com/watch?v=10DQeSk1LaY
const float = (originX, originY) => keyframes`
    0% {
        transform: translate(${originX}%, ${originY}%);
    }
    10% {
        transform: translate(calc(${originX}% + 5px), calc(${originY}% - 5px));
    }
    20% {
        transform: translate(calc(${originX}% + 10px), calc(${originY}% - 10px));
    }
    30% {
        transform: translate(calc(${originX}% + 5px), calc(${originY}% - 5px));
    }
    40% {
        transform: translate(calc(${originX}% - 5px), calc(${originY}% - 5px));
    }
    50% {
        transform: translate(calc(${originX}% - 5px), calc(${originY}%));
    }
    60% {
        transform: translate(calc(${originX}% - 5px), calc(${originY}% + 5px));
    }
    70% {
        transform: translate(calc(${originX}% - 5px), calc(${originY}% + 10px));
    }
    80% {
        transform: translate(calc(${originX}% - 5px), calc(${originY}% + 5px));
    }
    90% {
        transform: translate(calc(${originX}% - 5px), calc(${originY}% + 0px));
    }
    100% {
        transform: translate(${originX}%, ${originY}%);
    }
`

export const StyledBubbleBackgroundFloat = styled.div`
  position: relative;
  overflow: visible;
`

export const Content = styled.div`
  position: relative;
  z-index: 1;
`

export const Bubble = styled.img`
  position: absolute;
  height: ${({ height }) => height};
  top: ${({ originY }) => `${originY}%`};
  left: ${({ originX }) => `${originX}%`};
  z-index: 0;
  animation: 10s ${({ originX, originY }) => float(originX * -1, originY * -1)} infinite;
`
