import { StyledStart } from './Start.Styled'
import Layout from '../../Layout/Layout'
import { Container } from '@think-internet/ui-components'
import Districts from '../Districts/Districts'
import Hero from '../Hero/Hero'

const Start = () => {
  return (
    <Layout authRequired={false} mode="kitaoverview">
      <StyledStart>
        <Container>
          <Hero />
        </Container>
        <Districts />
      </StyledStart>
    </Layout>
  )
}

export default Start
