import fetch, { METHOD } from '../fetch'

export const login = async (identifier, password) => {
  const { status, token } = await fetch(METHOD.POST, '/auth/login', {
    identifier,
    password,
  })
  if (status && token) {
    return token
  }
  return false
}

export const resolveToken = async (token) => {
  const { status, account } = await fetch(METHOD.POST, '/auth/resolve-token', {
    token,
  })
  return status ? account : false
}
