import { useDispatch, useSelector } from 'react-redux'
import props from '../../../redux/props'
import TrafficLight from '../TrafficLight/TrafficLight'
import fzLogo from '../../../assets/image/kita/detail/familyCenter/logo.png'
import {
  Arrow,
  Availability,
  City,
  CityIcon,
  CityName,
  Description,
  AvailabilityLabel,
  LabelThatsUsLabel,
  LocationName,
  Page,
  Split,
  StyledDetail,
  ThatsUs,
  ContactPerson,
  Name,
  Title,
  Image,
  PropertyValue,
  PropertyLabel,
  Property,
  AlignCenter,
  CTAWrapper,
  DiscoverVirtually,
  DiscoverVirtuallyIcon,
  DiscoverVirtuallyLabel,
  ContactNow,
  Back,
  Content,
  Exit,
  FamilyCenterButton,
  FamilyCenterButtonLabel,
  FZLogo,
  ExternalSignup,
} from './Detail.Styled'
import arrowIcon from '../../../assets/image/kita/detail/thatsus-arrow.png'
import arrowIconKitaOverview from '../../../assets/image/kita/detail/thatsus-arrow-kitaoverview.svg'
import Slider from './Slider/Slider'
import { setLocal } from '../../../redux/action/local'
import FamilyCenter from './FamilyCenter/FamilyCenter'
import { useState } from 'react'
import FamilyCenterPopup from './FamilyCenterPopup/FamilyCenterPopup'

const Detail = () => {
  const dispatch = useDispatch()
  const translation = useSelector((s) => s[props.TRANSLATION])
  const locationData = useSelector((s) => s[props.KITA_DETAIL_LOCATION])
  const [showFamilyCenterPopup, setShowFamilyCenterPopup] = useState(false)

  const close = () => dispatch(setLocal(props.KITA_DETAIL_LOCATION, null))

  const showFamilyCenterIcon = () => {
    const { location } = locationData
    if (!location) return false
    if (!location.familyCenter) return false
    const { additionalInfo, additionalInfoPDF, beratungInfoPDF, familienbildungInfoPDF, moreInfoZIP } = location.familyCenter
    return (!!additionalInfo && !!additionalInfoPDF) || !!beratungInfoPDF || !!familienbildungInfoPDF || !!moreInfoZIP
  }

  const toggleFamilyCenterPopup = () => {
    setShowFamilyCenterPopup(!showFamilyCenterPopup)
  }

  if (!locationData) return <></>
  const { mode, location } = locationData
  return (
    <StyledDetail>
      <Content mode={mode}>
        <Split>
          <Page>
            <City mode={mode} href={`https://www.google.com/maps/place/${location.fullLocationString}`} target="_blank">
              <CityIcon mode={mode} className="city-icon" />
              <CityName className="city-name" mode={mode}>
                {location.city}
              </CityName>
            </City>
            <LocationName mode={mode}>{location.name}</LocationName>
            <Availability>
              <TrafficLight availability={location.availability} />
              <AvailabilityLabel mode={mode}>{translation.kita.detail.availability[location.availability]}</AvailabilityLabel>
            </Availability>
            <FamilyCenter mode={mode} location={location} />
            <ThatsUs>
              <LabelThatsUsLabel mode={mode}>{translation.kita.detail.thatsUs}</LabelThatsUsLabel>
              <Arrow src={mode === 'kitaoverview' ? arrowIconKitaOverview : arrowIcon} />
            </ThatsUs>
            <Description mode={mode}>{location.description}</Description>
            <ContactPerson>
              <Split>
                {location.contactPersonImageURL && (
                  <Page>
                    <Image src={location.contactPersonImageURL} />
                  </Page>
                )}
                <Page>
                  <Name mode={mode}>{location.contactPerson}</Name>
                  <Title mode={mode}>{location.contactPersonTitle}</Title>
                  <Property>
                    <PropertyLabel mode={mode}>{translation.generic.mail}:</PropertyLabel>
                    <PropertyValue mode={mode} href={`mailto:${location.mail}`}>
                      {location.mail}
                    </PropertyValue>
                  </Property>
                  <Property>
                    <PropertyLabel mode={mode}>{translation.generic.phone}:</PropertyLabel>
                    <PropertyValue mode={mode} href={`tel:${location.phone}`}>
                      {location.phone}
                    </PropertyValue>
                  </Property>
                  <Property>
                    <PropertyLabel mode={mode}>{translation.generic.address}:</PropertyLabel>
                    <PropertyValue mode={mode} target="_blank" href={`https://www.google.com/maps/place/${location.fullLocationString}`}>
                      {location.fullLocationString}
                    </PropertyValue>
                  </Property>
                </Page>
              </Split>
            </ContactPerson>
          </Page>
          <Page>
            <AlignCenter>
              <Slider mode={mode} assetURLs={location.galleryImageURLs} />
              <CTAWrapper>
                {showFamilyCenterIcon() && (
                  <>
                    <FZLogo src={fzLogo} />
                    <FamilyCenterButton mode={mode} onClick={toggleFamilyCenterPopup}>
                      <FamilyCenterButtonLabel mode={mode} className="label">
                        {translation.kita.detail.familyCenter.button}
                      </FamilyCenterButtonLabel>
                    </FamilyCenterButton>
                  </>
                )}
                {!!location.virtualViewingLink && (
                  <DiscoverVirtually mode={mode} target="_blank" href={location.virtualViewingLink}>
                    <DiscoverVirtuallyIcon mode={mode} className="icon" />
                    <DiscoverVirtuallyLabel mode={mode} className="label">
                      {translation.kita.detail.discoverVirtually}
                    </DiscoverVirtuallyLabel>
                  </DiscoverVirtually>
                )}
                {!!location.externalSignupLink && (
                  <ExternalSignup mode={mode} target="_blank" href={location.externalSignupLink}>
                    {translation.kita.detail.externalSignup}
                  </ExternalSignup>
                )}
                {!!location.conceptInfoFileURL && (
                  <ExternalSignup mode={mode} target="_blank" href={location.conceptInfoFileURL}>
                    {translation.kita.detail.conceptInfo}
                  </ExternalSignup>
                )}
                <ContactNow mode={mode} href={`mailto:${location.mail}`}>
                  {translation.kita.detail.contactNow}
                </ContactNow>
              </CTAWrapper>
            </AlignCenter>
          </Page>
        </Split>
        {mode !== 'kitaoverview' && <Back onClick={close}>{translation.kita.detail.back}</Back>}
        {mode === 'kitaoverview' && <Exit onClick={close} />}
        {showFamilyCenterPopup && <FamilyCenterPopup data={location.familyCenter} close={toggleFamilyCenterPopup} />}
      </Content>
    </StyledDetail>
  )
}

export default Detail
