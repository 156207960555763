import { ProgressBar } from 'react-loader-spinner'
import { StyledLoading } from './Loading.Styled'

const Loading = () => {
  return (
    <StyledLoading>
      <ProgressBar
        visible={true}
        height="80"
        width="80"
        barColor="#002849"
        borderColor="white"
        ariaLabel="progress-bar-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </StyledLoading>
  )
}

export default Loading
