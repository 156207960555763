import { useEffect } from 'react'
import Cookies from 'universal-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { resolveToken } from '../../../interface/auth'
import props from '../../../redux/props'
import { setLocal } from '../../../redux/action/local'

const Secure = ({ children, authRequired = true, requiredLevel = null }) => {
  const token = useSelector((state) => state[props.TOKEN])
  const account = useSelector((state) => state[props.ACCOUNT])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const evaluate = async () => {
      const cookies = new Cookies()
      const token = cookies.get('token', { path: '/' })
      let account = false
      if (token) {
        account = await resolveToken(token)
      }
      if (
        account &&
        (requiredLevel !== null || Array.isArray(requiredLevel) ? requiredLevel.includes(account.level) : account.level === requiredLevel)
      ) {
        dispatch(setLocal(props.ACCOUNT, account))
        dispatch(setLocal(props.TOKEN, token))
      } else if (authRequired) {
        cookies.remove('token', { path: '/' })
        dispatch(setLocal(props.ACCOUNT, null))
        dispatch(setLocal(props.TOKEN, null))
        navigate('/')
      }
    }
    if (!token && !account) {
      evaluate()
    }
  }, [authRequired, dispatch, navigate, token, account, pathname, searchParams, requiredLevel])

  return (token && account) || !authRequired ? <>{children}</> : <></>
}

export default Secure
