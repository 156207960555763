import styled from 'styled-components'

export const StyledNews = styled.div`
  padding: 100px 0;
`

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  text-transform: uppercase;
  font-family: 'bold';
  text-align: center;
`

export const Card = styled.div`
  width: 300px;
  text-align: left;
  background-color: ${({ theme }) => theme.color.background.darkBlue};
  border-radius: 20px;
  padding: 10px;
  padding-bottom: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 250px;
  }
`

export const Image = styled.img`
  width: 100%;
`

export const DateLabel = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.color.font.orange};
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const Title = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
`

export const Location = styled.div`
  color: ${({ theme }) => theme.color.font.orange};
  font-size: ${({ theme }) => theme.font.size.sm};
  margin-top: 10px;
`

export const CTA = styled.a`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
  position: relative;
  &:hover {
    .arrow {
      right: 0px;
    }
  }
`

export const CTAText = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const Arrow = styled.img`
  height: 15px;
  position: absolute;
  right: 40px;
  transition: all 0.2s ease-in-out;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    height: auto;
    width: 40px;
  }
`
