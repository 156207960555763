import { Container } from '@think-internet/ui-components'
import { StyledPeople, Section, Title } from './People.Styled.js'
import { useSelector } from 'react-redux'
import Split from './Split/Split.js'
import puening from '../../../assets/image/managingDirectors/people/puening.png'
import schluetermann from '../../../assets/image/managingDirectors/people/schluetermann.png'
import puening_organic from '../../../assets/image/managingDirectors/people/puening_organic.png'
import schluetermann_organic from '../../../assets/image/managingDirectors/people/schluetermann_organic.png'
// import headline_organic from '../../../assets/image/managingDirectors/people/headline_organic.png'
import { getUUID } from '../../../utility.js'
// import BubbleBackgroundFloat from '../../SubComponents/BubbleBackgroundFloat/BubbleBackgroundFloat.js'

const People = () => {
  const translation = useSelector((s) => s.TRANSLATION)
  const sectionImages = [[schluetermann], [puening]]
  const sectionBubbleImages = [[schluetermann_organic], [puening_organic]]

  return (
    <StyledPeople>
      <Container>
        {/* <BubbleBackgroundFloat bubble={headline_organic} height="125px" originX={60} originY={60}>
          <Headline>{translation.managingDirectors.people.headline}</Headline>
        </BubbleBackgroundFloat> */}
        {translation.managingDirectors.people.sections.map(({ title, people }, i) => (
          <Section key={getUUID()}>
            <Title>{title}</Title>
            {people.map((data, j) => {
              return (
                <Split
                  invert={people.length > 1 ? j % 2 === 1 : i % 2 === 1}
                  key={i}
                  image={sectionImages[i][j]}
                  bubbleImage={sectionBubbleImages[i][j]}
                  data={data}
                />
              )
            })}
          </Section>
        ))}
      </Container>
    </StyledPeople>
  )
}

export default People
