import Layout from '../Layout/Layout'
import { StyledPageNotFound, Image, Content, Headline, Subheadline } from './PageNotFound.Styled'
import image from '../../assets/image/pageNotFound/image.svg'
import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../redux/props'

const PageNotFound = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <Layout authRequired={false} mode="pageNotFound">
      <StyledPageNotFound>
        <Container>
          <Content>
            <Image src={image} />
            <Headline>{translation.pageNotFound.headline}</Headline>
            <Subheadline>{translation.pageNotFound.subheadline}</Subheadline>
          </Content>
        </Container>
      </StyledPageNotFound>
    </Layout>
  )
}

export default PageNotFound
