import styled from 'styled-components'

export const StyledCreate = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
`

export const ArticleWrapper = styled.div`
  margin-top: 25px;
  width: 100%;
`
