import { StyledTrafficLight, Light } from './TrafficLight.Styled'

const TrafficLight = ({ availability }) => {
  return (
    <StyledTrafficLight>
      <Light color={availability === 'AVAILABLE' ? availability : null} /> <Light color={availability === 'LIMITED' ? availability : null} />{' '}
      <Light color={availability === 'UNAVAILABLE' ? availability : null} />
    </StyledTrafficLight>
  )
}
export default TrafficLight
