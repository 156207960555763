import { ItemWrapper, StyledSlider, Wrapper, ImageWrapper, Image, Remove } from './Slider.Styled'
import { getUUID, s3StorageBucketPublicURLPrefix } from '../../../../../../../utility'
import { useSelector } from 'react-redux'
import props from '../../../../../../../redux/props'

const Slider = ({ imageKeys, remove }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  if (!Array.isArray(imageKeys) || imageKeys.length === 0) return <></>

  const getURL = (imageKey) => s3StorageBucketPublicURLPrefix + imageKey

  return (
    <StyledSlider>
      <Wrapper>
        {imageKeys.map((key) => (
          <ItemWrapper key={getUUID()}>
            <ImageWrapper>
              <Image src={getURL(key)} />
              <Remove className="remove" onClick={() => remove(key)}>
                {translation.generic.remove}
              </Remove>
            </ImageWrapper>
          </ItemWrapper>
        ))}
      </Wrapper>
    </StyledSlider>
  )
}

export default Slider
