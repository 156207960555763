import { Button, Input, Textarea } from '@think-internet/ui-components'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { upsert } from '../../../../../interface/crud'
import { list, remove } from '../../../../../redux/action/crud'
import props from '../../../../../redux/props'
import toast, { TYPE } from '../../../../../toast'
import { StyledArticle, ButtonWrapper } from './Article.Styled'
import ImageManager from './ImageManager/ImageManager'
import LocationAssignment from './LocationAssignment/LocationAssignment'

const Article = ({ data: initData = {}, removeCallback, close }) => {
  const token = useSelector((s) => s[props.TOKEN])
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState(initData || {})
  const dispatch = useDispatch()

  const updateProp = (prop) => (value) => {
    setData({ ...data, [prop]: value })
  }

  const submit = async () => {
    const location = await upsert(token, props.NEWS_ARTICLE, data, ['uuid'])
    if (location) {
      toast(TYPE.SUCCESS, translation.dashboard.sharedPages.news.success.submit)
      if (!initData.uuid) {
        setData({ title: '', text: '', locationUUID: null, imageKeys: [] })
        close()
      }
      dispatch(list(props.NEWS_ARTICLE))
    } else {
      toast(TYPE.ERROR, translation.dashboard.sharedPages.news.error.submit)
    }
  }

  const removeArticle = () => {
    dispatch(remove(props.NEWS_ARTICLE, { uuid: data.uuid }))
    removeCallback(data.uuid)
  }

  return (
    <StyledArticle>
      <Input value={data.title} onChange={updateProp('title')} placeholder={translation.dashboard.sharedPages.news.title} />
      <Textarea rows={10} value={data.text} onChange={updateProp('text')} placeholder={translation.dashboard.sharedPages.news.text} />
      <LocationAssignment locationUUID={data.locationUUID} callback={updateProp('locationUUID')} />
      <ImageManager
        existingImageKeys={data.imageKeys}
        callback={updateProp('imageKeys')}
        title={translation.dashboard.sharedPages.news.upsert.images.title}
        hint={translation.dashboard.sharedPages.news.upsert.images.hint}
      />
      <ButtonWrapper singleMode={!data.uuid}>
        {!!data.uuid && <Button onClick={removeArticle} text={translation.generic.remove} />}
        <Button onClick={submit} text={translation.generic.save} />
      </ButtonWrapper>
    </StyledArticle>
  )
}

export default Article
