import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Wave from '../../SubComponents/Wave/Wave'
import { StyledHero, Content, Headline, HeadlineSpan, Subheadline, TopSection, BottomSection, FloatIcon, CTA, CTAArrow } from './Hero.Styled'
import floatIcon from '../../../assets/image/home/hero/floatIcon.svg'
import blueArrowRight from '../../../assets/image/home/hero/blueArrowRight.png'
import ArrowRedDownAnimated from '../../SubComponents/ArrowRedDownAnimated/ArrowRedDownAnimated'
import { getUUID } from '../../../utility'

const Hero = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <StyledHero>
      <TopSection>
        <Wave reverse={true} width="125%" />
        <Content>
          <FloatIcon src={floatIcon} />
          <Headline duration="2.5" animation="fadeIn">
            {translation.home.hero.headline.map((t, i) => (
              <HeadlineSpan highlight={i % 2 === 1} key={getUUID()}>
                {t}
              </HeadlineSpan>
            ))}
          </Headline>
          <Subheadline duration="5" animation="fadeIn">
            {translation.home.hero.subheadline}
          </Subheadline>
          <ArrowRedDownAnimated />
        </Content>
      </TopSection>
      <BottomSection>
        <CTA href="/kitauebersicht">
          {translation.home.hero.cta}
          <CTAArrow src={blueArrowRight} />
        </CTA>
      </BottomSection>
      <Wave width="250%" />
    </StyledHero>
  )
}

export default Hero
