import styled from 'styled-components'
import detailIcon from '../../../../../assets/image/kitapiloot/results/location/detail.png'
import detailIconHover from '../../../../../assets/image/kitapiloot/results/location/detail-hover.png'
import goToIcon from '../../../../../assets/image/kitapiloot/results/location/goTo.png'
import goToIconHover from '../../../../../assets/image/kitapiloot/results/location/goTo-hover.png'

export const StyledLocation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
    align-items: flex-start;
  }
`

export const IndexWrapper = styled.div`
  width: 5%;
`

export const Index = styled.div`
  border-radius: 30px;
  border: 1px solid ${({ theme }) => theme.color.border.secondary};
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.font.secondary};
`

export const GenericInfo = styled.div`
  display: flex;
  width: 70%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(100% - 40px);
  }
`
export const Colorizer = styled.div`
  background-color: ${({ theme, color }) => theme.color.background[color]};
  display: flex;
  width: 100%;
  border-radius: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`
export const Label = styled.div`
  padding: 10px 25px;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.sm};
  white-space: nowrap;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 10px 15px;
  }
`
export const TrafficLightWrapper = styled.div`
  width: 10%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: fit-content;
  }
`

export const VirtualViewingLink = styled.a`
  width: 5%;
  align-self: stretch;
  background-image: url(${detailIcon});
  background-repeat: no-repeat;
  background-size: 30px auto;
  background-position: center center;
  background-color: ${({ theme }) => theme.color.background.white};
  border-radius: 30px;
  cursor: pointer;
  &:hover {
    background-image: url(${detailIconHover});
    background-color: ${({ theme }) => theme.color.background.orange};
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(30% - 5px);
  }
`
export const GoToToggle = styled.div`
  width: 5%;
  align-self: stretch;
  background-image: url(${goToIcon});
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 20px auto;
  background-position: center center;
  background-color: ${({ theme }) => theme.color.background.white};
  border-radius: 30px;
  cursor: pointer;
  &:hover {
    background-image: url(${goToIconHover});
    background-color: ${({ theme }) => theme.color.background.orange};
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(30% - 5px);
  }
`
