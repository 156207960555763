import * as operation from '../operation'

//actions
export const list = (featureName, query = {}, replace = true) => ({
  type: operation.LIST,
  payload: {
    query,
    featureName,
    replace,
  },
})

export const get = (featureName, query = {}, replace = true) => ({
  type: operation.GET,
  payload: {
    query,
    featureName,
    replace,
  },
})

export const update = (featureName, query = {}, replace = true) => ({
  type: operation.UPDATE,
  payload: { query, featureName, replace },
})

export const upsert = (featureName, query = {}, replace = true) => ({
  type: operation.UPSERT,
  payload: { query, featureName, replace },
})

export const multiUpsert = (featureName, query = {}, replace = true) => ({
  type: operation.MULTI_UPSERT,
  payload: { query, featureName, replace },
})

export const create = (featureName, query = {}, replace = true) => ({
  type: operation.CREATE,
  payload: { query, featureName, replace },
})

export const remove = (featureName, query = {}) => ({
  type: operation.DELETE,
  payload: { query, featureName },
})
