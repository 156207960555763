import { Route, Routes, Navigate } from 'react-router-dom'
import Start from './Start/Start'
import Results from './Results/Results'

const Kitapiloot = () => {
  return (
    <Routes>
      <Route path={'/'} element={<Start />} />
      <Route path={'/results/:googlePlaceId/:radius'} element={<Results />} />
      <Route path={'*'} element={<Navigate replace to="/kitapiloot" />} />
    </Routes>
  )
}

export default Kitapiloot
