import Layout from '../Layout/Layout'
import { StyledHome } from './Home.Styled.js'
import Hero from './Hero/Hero'
import ProductOverview from './ProductOverview/ProductOverview'
import Concept from './Concept/Concept'
import WhyAndHistory from './WhyAndHistory/WhyAndHistory'
import News from './News/News'
import Jobs from '../SubComponents/Jobs/Jobs'

const Home = () => {
  return (
    <Layout authRequired={false}>
      <StyledHome>
        <Hero />
        <ProductOverview />
        <Concept />
        <WhyAndHistory />
        <News />
        <Jobs />
      </StyledHome>
    </Layout>
  )
}

export default Home
