import { StyledCreate, ArticleWrapper } from './Create.Styled'
import Article from '../Article/Article'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { useState } from 'react'
import { Button } from '@think-internet/ui-components'

const Create = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)
  return (
    <StyledCreate>
      <Button onClick={() => setOpen(!open)} text={translation.dashboard.sharedPages.news.upsert.toggleCTA} />
      {open && (
        <ArticleWrapper>
          <Article close={() => setOpen(false)} />
        </ArticleWrapper>
      )}
    </StyledCreate>
  )
}

export default Create
