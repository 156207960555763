import { StyledResults } from './Results.Styled'
import LocationListHeader from '../../../Kitapiloot/Results/LocationList/Header/Header'
import LocationList from '../../../Kitapiloot/Results/LocationList/LocationList'
import useGetKitaResultsByDistrict from './useGetKitaResultsByDistrict'
import useGeoLocator from '../../../Kitapiloot/Search/UseExactLocation/useGeoLocator'

const Results = ({ districtUUID }) => {
  const locations = useGetKitaResultsByDistrict(districtUUID)
  const geoLocation = useGeoLocator()

  if (!Array.isArray(locations) || locations.length === 0) return <></>
  return (
    <StyledResults>
      <LocationListHeader mode="kitaoverview" />
      <LocationList geoLocation={geoLocation} locations={locations} mode="kitaoverview" />
    </StyledResults>
  )
}

export default Results
