import styled from 'styled-components'
import Animated from '../../SubComponents/Animated/Animated'

export const StyledProductOverview = styled.div``
export const Headline = styled(Animated)`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: 'bold';
  position: relative;
  z-index: 1;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    &:first-child {
      margin-top: 50px;
    }
  }
`
export const Items = styled.div`
  display: flex;
  gap: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
  }
`
export const StyledItem = styled.a`
  background-color: ${({ theme }) => theme.color.background.rose};
  padding: 25px;
  border-radius: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`
export const Icon = styled.img`
  height: 100px;
  display: block;
`
export const NameImageWrapper = styled.div`
  height: ${({ height }) => height};
  width: 100%;
  text-align: center;
  padding: 15px 0px;
`
export const NameImage = styled.img`
  height: 100%;
`
export const Description = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.midblue};
  text-align: center;
`
export const ActionHint = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.midblue};
  margin-top: 15px;
`
export const Content = styled.div`
  padding: 75px 0px;
  width: 40%;
  margin: 0 auto;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 95%;
    margin-left: 2.5%;
    margin-top: 25px;
    padding: 25px 0px;
  }
`
export const Hint = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const HintText = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.sm};
  text-align: center;
`

export const DownloadCTAWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: center;
  margin-top: 50px;
`

export const DownloadCTA = styled.a`
  color: ${({ theme }) => theme.color.font.secondary};
  background-color: ${({ theme }) => theme.color.background.blue};
  font-size: ${({ theme }) => theme.font.size.sm};
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 10px 15px;
  border-radius: 30px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`

export const DownloadCTAIcon = styled.img`
  height: 20px;
`
