import { useDispatch, useSelector } from 'react-redux'
import { StyledNews, Headline, Card, Image, DateLabel, Title, CTA, Location, CTAText, Arrow } from './News.Styled.js'
import cardReadArrow from '../../../assets/image/home/news/readArrow.svg'
import bubble from '../../../assets/image/home/news/bubble.svg'
import BubbleBackgroundFloat from '../../SubComponents/BubbleBackgroundFloat/BubbleBackgroundFloat.js'
import Carousel from '../../SubComponents/Casousel/Carousel'
import { getPrettyDate, getUUID } from '../../../utility.js'
import { Container } from '@think-internet/ui-components'
import props from '../../../redux/props.js'
import { useEffect } from 'react'
import { list } from '../../../redux/action/crud'

const News = () => {
  const dispatch = useDispatch()
  const translation = useSelector((s) => s[props.TRANSLATION])
  const articles = useSelector((s) => s[props.NEWS_ARTICLE])

  useEffect(() => {
    if (!Array.isArray(articles)) dispatch(list(props.NEWS_ARTICLE, {}, true))
  })

  if (!Array.isArray(articles) || articles.length === 0) return <></>
  return (
    <StyledNews>
      <Container>
        <BubbleBackgroundFloat bubble={bubble} originX="40" height="100px">
          <Headline>{translation.home.news.headline}</Headline>
        </BubbleBackgroundFloat>
        <Carousel autoplay={true} autoplay_speed={5000}>
          {articles.map((card) => (
            <Card key={getUUID()}>
              <Image src={card.imageURLs[0]} />
              <DateLabel>{getPrettyDate(new Date(card.createdOn))}</DateLabel>
              <Title>{card.title}</Title>
              <Location>{card.location.name}</Location>
              <CTA href={'/news'}>
                <CTAText>{translation.home.news.cta}</CTAText>
                <Arrow className="arrow" src={cardReadArrow} />
              </CTA>
            </Card>
          ))}
        </Carousel>
      </Container>
    </StyledNews>
  )
}

export default News
