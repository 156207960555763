import { StyledCustomLink, Text, Arrow } from './CustomLink.styles.js'
import arrowImg from '../../../../assets/image/footer/readArrow.svg'

const CustomLink = ({ text, margin, newTab, href }) => {
  const scrollUp = () => {
    window.scrollTo(0, 0)
  }

  return (
    <StyledCustomLink margin={margin}>
      <Text newTab={newTab} href={href} onClick={scrollUp} text={text} />
      <Arrow src={arrowImg} />
    </StyledCustomLink>
  )
}

export default CustomLink
