import {
  Left,
  Right,
  Colaborator,
  Image,
  Breadcrumb,
  SocialMedia,
  Social,
  List,
  Wrapper,
  Follow,
  FollowWrapper,
  FollowRotateWrapper,
  ItemWrapper,
  CloseWrapper,
  Close,
  SocialWrapper,
  FollowSmall,
  Single,
  BackToHome,
  BackIcon,
} from './Header.Styled'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import props from '../../../redux/props'
import { setLocal } from '../../../redux/action/local'
import drkLogo from '../../../assets/image/generic/kita-white.svg'
import verbandLogo from '../../../assets/image/generic/drk-white.svg'
import kitapilootLogo from '../../../assets/image/generic/kitapilootName.svg'
import backArrow from '../../../assets/image/generic/blue-arrow-circle-left.png'
import breadcrumbLogo from '../../../assets/image/header/breadcrumb.png'
import instagramLogo from '../../../assets/image/header/instagram.png'
import facebookLogo from '../../../assets/image/header/facebook.png'
import Item from './Item/Item.js'
import closeOrange from '../../../assets/image/burgermenu/burgermenu_exit.svg'
import DownloadCircle from './DownloadCircle/DownloadCircle'

import instagramLogoOrange from '../../../assets/image/burgermenu/burgermenu_instagram.svg'
import facebookLogoOrange from '../../../assets/image/burgermenu/burgermenu_facebook.svg'
import { levels } from '../../../enum'

const Header = ({ mode }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const token = useSelector((s) => s[props.TOKEN])
  const account = useSelector((s) => s[props.ACCOUNT])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const logout = () => {
    const cookies = new Cookies()
    cookies.remove('token', { path: '/' })
    dispatch(setLocal(props.ACCOUNT, null))
    dispatch(setLocal(props.TOKEN, null))
    navigate('/')
  }

  const getBackLink = () => {
    const path = window.location.pathname
    if (path === '/') return null
    if (path.includes('/gruende/')) return '/gruende'
    return '/'
  }

  return (
    <>
      <Left mode={mode}>
        <Breadcrumb src={breadcrumbLogo} onClick={() => setOpen(true)} />
        {mode === 'default' && (
          <Social>
            <Wrapper>
              <a target="_blank" href="https://www.instagram.com/deine.kitas/?hl=de" rel="noreferrer">
                <SocialMedia src={instagramLogo} />
              </a>
            </Wrapper>
            <Wrapper>
              <a target="_blank" href="https://www.facebook.com/deine.drk.kitas" rel="noreferrer">
                <SocialMedia src={facebookLogo} />
              </a>
            </Wrapper>
            <FollowWrapper>
              <FollowRotateWrapper>
                <Follow>{translation.header.follow}</Follow>
              </FollowRotateWrapper>
            </FollowWrapper>
          </Social>
        )}
      </Left>

      <Right mode={mode}>
        {mode === 'default' && (
          <List>
            <Colaborator href="/">
              <Image src={drkLogo} alt={'logo'} />
            </Colaborator>
            <Colaborator href="/">
              <Image src={verbandLogo} alt={'logo'} />
            </Colaborator>
          </List>
        )}
        {mode === 'kitapiloot' && (
          <Single>
            <Colaborator href="/kitapiloot">
              <Image src={kitapilootLogo} alt={'logo'} />
            </Colaborator>
          </Single>
        )}
      </Right>

      {getBackLink() && (
        <BackToHome href={getBackLink()}>
          <BackIcon className="image" src={backArrow} />
          {translation.header.back}
        </BackToHome>
      )}

      <DownloadCircle />

      <ItemWrapper open={open}>
        <CloseWrapper>
          <Close src={closeOrange} onClick={() => setOpen(false)} />
        </CloseWrapper>
        {!token && (
          <>
            <Item margin="" href={'/'} text={translation.nav.Home} />
            <Item margin="10px 0 0 0" href={'/kitapiloot'} text={translation.nav.Kitapilot} />
            <Item margin="10px 0 0 0" href={'/kitauebersicht'} text={translation.nav.Kitauebersicht} />
            <Item margin="10px 0 0 0" href={'/geschichte'} text={translation.nav.Ueberuns} />
            <Item margin="10px 0 0 0" href={'/konzept'} text={translation.nav.OurConcept} />
            <Item margin="10px 0 0 0" href={'/bewerbung'} text={translation.nav.Bewerbung} />
            <Item margin="10px 0 0 0" href={'/kontakt'} text={translation.nav.Kontakt} />
            <Item margin="10px 0 0 0" href={'/geschaeftsfuehrung'} text={translation.nav.ManagingDirectors} />
            <Item margin="10px 0 0 0" href={'/ansprechpartner'} text={translation.nav.Ansprechpartner} />
            <Item margin="10px 0 0 0" href={'/impressum'} text={translation.nav.Impressum} />
            <Item margin="10px 0 0 0" href={'/datenschutz'} text={translation.nav.Datenschutz} />
            <Item margin="10px 0 0 0" href={'/login'} text={translation.nav.Login} />
            <Item margin="20px 0 0 0" href={'/kitauebersicht'} text={translation.nav.JetztEinenPlatzAnfragen} reverse />
            <Item margin="10px 0 0 0" href={'/infomaterial'} text={translation.nav.InfomaterialAnfordern} reverse />
          </>
        )}
        {token && account && account.level === levels.ADMIN && (
          <>
            <Item margin="10px 0 0 0" href={'/dashboard/admin/users'} text={translation.nav.Users} />
            <Item margin="10px 0 0 0" href={'/dashboard/admin/locations'} text={translation.nav.Locations} />
            <Item margin="10px 0 0 0" href={'/dashboard/admin/family-center'} text={translation.nav.FamilyCenter} />
            <Item margin="10px 0 0 0" href={'/dashboard/admin/districts'} text={translation.nav.Districts} />
            <Item margin="10px 0 0 0" href={'/dashboard/admin/news'} text={translation.nav.News} />
            <Item margin="10px 0 0 0" href={'/dashboard/admin/jobs'} text={translation.nav.Jobs} />
          </>
        )}
        {token && account && account.level === levels.MANAGER && (
          <>
            <Item margin="10px 0 0 0" href={'/dashboard/manager/locations'} text={translation.nav.Locations} />
            <Item margin="10px 0 0 0" href={'/dashboard/manager/family-center'} text={translation.nav.FamilyCenter} />
            <Item margin="10px 0 0 0" href={'/dashboard/manager/news'} text={translation.nav.News} />
          </>
        )}
        {token && <Item margin="30px 0 0 0" onClick={logout} href="/" text={translation.nav.Logout} />}

        <SocialWrapper>
          <a target="_blank" href="https://www.instagram.com/deine.kitas/?hl=de" rel="noreferrer">
            <SocialMedia src={instagramLogoOrange} />
          </a>
          <a target="_blank" href="https://www.facebook.com/deine.drk.kitas" rel="noreferrer">
            <SocialMedia src={facebookLogoOrange} />
          </a>

          <FollowSmall>{translation.header.follow}</FollowSmall>
        </SocialWrapper>
      </ItemWrapper>
    </>
  )
}

export default Header
