import { useEffect } from 'react'
import arrow from '../../../../../assets/image/header/arrow.png'
import Animated from '../../../../SubComponents/Animated/Animated'
import { Image, StyledArrow } from './Arrow.Styled'

const Arrow = () => {
  useEffect(() => {
    setTimeout(() => {
      document.getElementById('header-job-arrow').style.opacity = 0
    }, 3000)
  }, [])

  return (
    <StyledArrow>
      <Animated animation="slideInLeft" duration={2.5}>
        <Image id="header-job-arrow" src={arrow} />
      </Animated>
    </StyledArrow>
  )
}

export default Arrow
