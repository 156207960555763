import styled from 'styled-components'

export const StyledPlacePrediction = styled.div`
  width: 100%;
  position: relative;
`

export const Predictions = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.color.background.white};
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
`

export const Prediction = styled.div`
  padding: 10px 15px;
  border-top: 1px solid ${({ theme }) => theme.color.background.darkBlue};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.color.background.lightBlue};
  }
  &:first-child {
    border-top: none;
  }
`
