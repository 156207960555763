import styled from 'styled-components'

export const StyledDownloadCircle = styled.div`
  z-index: 1000;
  position: fixed;
  top: 150px;
  right: 40px;
  height: 125px;
  width: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`

export const Text = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transform: rotate(${({ rotate }) => rotate}deg);
  * {
    color: ${({ theme }) => theme.color.font.secondary};
    font-size: ${({ theme }) => theme.font.size.sm};
  }
`

export const Image = styled.img`
  height: 80px;
`
