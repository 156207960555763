import styled from 'styled-components'

export const StyledPDFManager = styled.div`
  width: 100%;
`
export const Processing = styled.div`
  text-align: center;
  margin: 10px 0;
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const CurrentPDF = styled.a`
  display: block;
  margin-top: 5px;
`
