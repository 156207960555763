import { DragAndDrop } from '@think-internet/ui-components'
import styled from 'styled-components'

export const StyledImageVideoManager = styled.div`
  width: 100%;
`
export const Processing = styled.div`
  text-align: center;
  margin: 10px 0;
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const CustomDragAndDrop = styled(DragAndDrop)`
  ${({ hasFiles }) =>
    hasFiles &&
    `
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  `}
`
