import styled from 'styled-components'

export const StyledSearch = styled.div``
export const SearchSplit = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`

export const SearchButton = styled.div`
  width: fit-content;
  margin: 15px auto 0 auto;
  padding: 10px 30px;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.secondary};
  background-color: ${({ theme, invertedColorScheme }) => (invertedColorScheme ? theme.color.background.orange : theme.color.background.darkBlue)};
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
`
