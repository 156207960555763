import { Route, Routes, Navigate } from 'react-router-dom'
import Locations from './Locations/Locations.js'
import Location from './Location/Location'
import News from '../SharedPages/News/News.js'
import FamilyCenters from './FamilyCenters/FamilyCenters.js'
import FamilyCenter from './FamilyCenter/FamilyCenter.js'

const Manager = () => {
  return (
    <Routes>
      <Route path={'locations'} element={<Locations />} />
      <Route path={'location/:uuid'} element={<Location />} />
      <Route path={'family-center'} element={<FamilyCenters />} />
      <Route path={'family-center/:uuid'} element={<FamilyCenter />} />
      <Route path={'news'} element={<News />} />
      <Route path={'*'} element={<Navigate replace to="locations" />} />
    </Routes>
  )
}

export default Manager
