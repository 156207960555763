import styled from 'styled-components'
import refreshIcon from '../../../assets/image/kitapiloot/results/refresh.svg'
import refreshIconHover from '../../../assets/image/kitapiloot/results/refresh-hover.svg'

export const StyledResults = styled.div`
  padding: 25px 0 200px 0;
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const Icon = styled.img`
  width: 75px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 50px;
  }
`
export const Logo = styled.img`
  height: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 40px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: ${({ theme }) => theme.color.background.darkBlue};
  border-radius: 30px;
  margin-top: 25px;
  position: relative;
`

export const GoogleMapWrapper = styled.div`
  margin: 25px 0;
`

export const RefreshPage = styled.div`
  margin: 25px 0 15px;
  height: 40px;
  transition: transform 0.2s ease-in-out;
  background-image: url(${refreshIcon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    background-image: url(${refreshIconHover});
  }
`
