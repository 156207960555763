import styled from 'styled-components'

export const StyledUseExactLocation = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Icon = styled.div`
  background-image: url(${({ src }) => src});
  background-position: center center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin-right: 10px;
`

export const Label = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  display: inline-block;
  vertical-align: middle;
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
  ${({ invertedColorScheme, theme }) => invertedColorScheme && `color: ${theme.color.font.orange};`}
`
