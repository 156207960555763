import styled from 'styled-components'

export const StyledStory = styled.div``

export const IllustrationFullWidth = styled.img`
  margin: ${({ margin }) => margin};
`

export const Headline = styled.div`
  font-family: bold;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.xlg};
  margin-top: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    font-size: ${({ theme }) => theme.font.size.md};
    margin-top: 10px;
  }
`

export const SubHeadline = styled.h3`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.lg};
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: ${({ bold }) => (bold ? 'bold' : 'regular')};
  text-align: left;
  /* width: 80%; */

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    font-size: ${({ theme }) => theme.font.size.sm};
  }
`

export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: justify;
  /* width: 80%; */
`
