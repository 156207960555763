import { StyledSplit, Content, ImageSection, Image, TextSection, Name, Title, Tel, Mail, Street, City } from './Split.Styled'
import BubbleBackgroundFloat from '../../SubComponents/BubbleBackgroundFloat/BubbleBackgroundFloat'
import Animated from '../../SubComponents/Animated/Animated'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'

const Split = ({ invert, image, bubbleImage, data }) => {
  const { name, speciality, phone, mail, street, city } = data
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledSplit>
      <Content reverse={invert}>
        <TextSection>
          <BubbleBackgroundFloat bubble={bubbleImage} height="150px" originX={invert ? 110 : -20} originY={-20}>
            <Name>{name}</Name>
            <Title>{speciality}</Title>
            <Tel href={`tel:${phone}`}>{`${translation.generic.phoneShortPrefix}${phone}`}</Tel>
            <Mail href={`mailto:${mail}`}>{mail}</Mail>
            <Street>{street}</Street>
            <City>{city}</City>
          </BubbleBackgroundFloat>
        </TextSection>
        <ImageSection>
          <Animated animation={invert ? 'fadeInLeft' : 'fadeInRight'} duration={3}>
            <Image src={image} />
          </Animated>
        </ImageSection>
      </Content>
    </StyledSplit>
  )
}

export default Split
