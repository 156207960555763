import { useState } from 'react'
import { StyledPersonioList } from './PersonioList.Styled'
import { useEffect } from 'react'
import axios from 'axios'
import Job from './Job/Job'
import { getUUID } from '../../../utility'
const { XMLParser } = require('fast-xml-parser')

const PersonioList = () => {
  const [jobs, setJobs] = useState(null)

  useEffect(() => {
    const filter = (jobs) => {
      return jobs.filter((j) => {
        const subcompany = j?.subcompany?.replaceAll(' ', '') === 'DRK-KITAsgGmbH'
        const keywords = j?.keywords?.includes('extern')
        return subcompany && keywords
      })
    }

    const fetchJobs = async () => {
      const result = await axios({ method: 'GET', url: 'https://drk-coe.jobs.personio.de/xml' })
      if (!!result?.data) {
        const parser = new XMLParser()
        const jObj = parser.parse(result.data)
        const jobs = jObj?.['workzag-jobs']?.position
        if (!!jobs) {
          setJobs(filter(jobs))
        }
      }
    }
    if (!jobs) {
      fetchJobs()
    }
  }, [jobs])

  if (!Array.isArray(jobs)) return null
  return (
    <StyledPersonioList>
      {jobs.map((j) => (
        <Job key={getUUID()} data={j} />
      ))}
    </StyledPersonioList>
  )
}

export default PersonioList
