import styled from 'styled-components'
import Animated from '../../SubComponents/Animated/Animated'

export const StyledHero = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: auto;
  }
`

export const Content = styled.div`
  padding: 25px 0;
  width: fit-content;
`

export const ImageWrapper = styled.div`
  display: inline-flex;
  position: relative;
  margin: 0;
  height: 100%;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    text-align: center;
    padding: 50px 0;
  }
`

export const Image = styled.img`
  position: absolute;
  bottom: 0%;
  left: 50%;
  max-height: 80%;
  max-width: 80%;
  transform: translate(-50%, -10%);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    position: relative;
    max-height: 100%;
    max-width: 100%;
    margin-top: 100px;
  }
`

export const Headline = styled(Animated)`
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: 'bold';
  color: ${({ theme }) => theme.color.font.blue};
  text-align: center;
  text-transform: uppercase;
`
export const Subheadline = styled(Animated)`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.blue};
  text-align: center;
  text-transform: uppercase;
`
