import styled from 'styled-components'

export const StyledPeople = styled.div`
  padding: 50px 0 100px;
`

export const OfferingsHeadline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: 'bold';
`

export const Section = styled.div`
  margin-top: 100px;
`

export const Title = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: 'bold';
  color: ${({ theme }) => theme.color.font.lightblue};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`

export const Headline = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: 'bold';
`
