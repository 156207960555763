import styled from 'styled-components'

export const StyledLocations = styled.div`
  padding: 100px 0;
`

export const Headline = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.xlg};
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`

export const Location = styled.div`
  margin-top: 10px;
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.color.background.lightorange};
  border-radius: 30px;
  cursor: pointer;
`

export const Name = styled.div``
