import { useEffect } from 'react'
import { useState } from 'react'
import ShowIfVisible from '../ShowIfVisible/ShowIfVisible'
import { StyledAnimated, StyledAnimatedTyping, Mock } from './Animated.Styled'

const AnimatedTyping = ({ disabledOnMobile, animation, duration, delay, className, children }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const isAllowedToAnimate = !disabledOnMobile || (disabledOnMobile && window.innerWidth > 768)

    let timeout = null
    if (delay && isAllowedToAnimate) {
      timeout = setTimeout(() => {
        setShow(true)
      }, delay * 1000)
    } else {
      setShow(true)
    }
    return () => clearTimeout(timeout)
  }, [show, delay, disabledOnMobile])

  if (!show) return <Mock className={className}>{children}</Mock>
  return (
    <StyledAnimatedTyping disabledOnMobile={disabledOnMobile} animation={animation} delay={delay} duration={duration} className={className}>
      {children}
    </StyledAnimatedTyping>
  )
}

const Animated = ({ disabledOnMobile, animation, duration, delay, infinite, className, children, showIfVisible = true }) => {
  let component = null
  if (animation === 'typing') {
    component = (
      <AnimatedTyping disabledOnMobile={disabledOnMobile} animation={animation} delay={delay} duration={duration} className={className}>
        {children}
      </AnimatedTyping>
    )
  } else {
    component = (
      <StyledAnimated
        disabledOnMobile={disabledOnMobile}
        animation={animation}
        delay={delay}
        infinite={infinite}
        duration={duration}
        className={className}
      >
        {children}
      </StyledAnimated>
    )
  }
  if (showIfVisible) {
    return <ShowIfVisible>{component}</ShowIfVisible>
  }
  return component
}

export default Animated
