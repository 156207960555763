const enumCollection = {
  operations: {
    CREATE: 'CREATE',
    READ: 'READ',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
  },
  location_availability: {
    AVAILABLE: 'AVAILABLE',
    LIMITED: 'LIMITED',
    UNAVAILABLE: 'UNAVAILABLE',
  },
  levels: {
    MANAGER: 'MANAGER',
    ADMIN: 'ADMIN',
  },
}

module.exports = enumCollection
