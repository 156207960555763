import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { StyledWhyAndHistory, Items, StyledItem, IconWrapper, Icon, Title, Text, ActionHint, Content } from './WhyAndHistory.Styled'
import why from '../../../assets/image/home/whyAndHistory/why.svg'
import history from '../../../assets/image/home/whyAndHistory/history.svg'
import bubble from '../../../assets/image/home/whyAndHistory/bubble.svg'
import BubbleBackgroundFloat from '../../SubComponents/BubbleBackgroundFloat/BubbleBackgroundFloat'
import { getUUID } from '../../../utility'

const Item = ({ icon, title, text, href }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledItem href={href}>
      <IconWrapper>
        <Icon src={icon} />
      </IconWrapper>
      <Title>{title}</Title>
      <Text>{text}</Text>
      <ActionHint>{translation.home.whyAndHistory.cta}</ActionHint>
    </StyledItem>
  )
}

const WhyAndHistory = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const whyAndHistoryIcons = [why, history]

  return (
    <StyledWhyAndHistory>
      <BubbleBackgroundFloat bubble={bubble} originX={20} height="90%">
        <Content>
          <Items>
            {translation.home.whyAndHistory.items.map((item, i) => {
              return <Item icon={whyAndHistoryIcons[i]} title={item.title} text={item.text} href={item.href} key={getUUID()} />
            })}
          </Items>
        </Content>
      </BubbleBackgroundFloat>
    </StyledWhyAndHistory>
  )
}

export default WhyAndHistory
