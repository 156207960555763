import styled from 'styled-components'
import bg from '../../../assets/image/home/hero/bg.jpg'
import Animated from '../../SubComponents/Animated/Animated'

export const StyledHero = styled.div``

export const TopSection = styled.div`
  background-image: url(${bg});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
`

export const Content = styled.div`
  background-color: ${({ theme }) => theme.color.background.blue};
  width: 100%;
  padding: 50px 0 25px 0;
  position: relative;
`

export const Headline = styled(Animated)`
  width: 100%;
  text-align: center;
`

export const HeadlineSpan = styled.span`
  color: ${({ theme, highlight }) => (highlight ? theme.color.font.red : theme.color.font.secondary)};
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: 'bold';
  width: 100%;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`

export const Subheadline = styled(Animated)`
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
  width: 100%;
  text-align: center;
  margin-top: 5px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.md};
  }
`

export const FloatIcon = styled.img`
  position: absolute;
  top: -25%;
  left: 50%;
  transform: translate(-50%, -35%);
  width: 100px;
`

export const BottomSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.background.blue};
  padding: 25px 0px;
`

export const CTA = styled.a`
  background-color: ${({ theme }) => theme.color.background.red};
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 10px 15px;
  border-radius: 30px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`

export const CTAArrow = styled.img`
  width: 20px;
`
