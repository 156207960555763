import { useSelector } from 'react-redux'
import {
  StyledPhilosophieAndConcept,
  SplitSection,
  ImageWrapper,
  Image,
  Content,
  Headline,
  SubHeadline,
  Text,
  CTA,
  CTAArrow,
  CTAText,
  SplitSectionsWrapper,
} from './PhilosophieAndConcept.Styled.js'
import seesawImg from '../../../assets/image/concept/philosophieAndConcept/seesaw.svg'
import trampolineImg from '../../../assets/image/concept/philosophieAndConcept/trampoline.svg'
import seesawBackground from '../../../assets/image/concept/philosophieAndConcept/seesaw_bg.svg'
import trampolineBackground from '../../../assets/image/concept/philosophieAndConcept/trampoline_bg.svg'
import arrowCTA from '../../../assets/image/generic/ctaArrowLeft.svg'
import BubbleBackgroundFloat from '../../SubComponents/BubbleBackgroundFloat/BubbleBackgroundFloat'
import { Container } from '@think-internet/ui-components'
import Animated from '../../SubComponents/Animated/Animated.js'
import { getUUID } from '../../../utility.js'

const PhilosophieAndConcept = () => {
  const translation = useSelector((s) => s.TRANSLATION)
  const splitSectionImages = [seesawImg, trampolineImg]
  const splitSectionBackgrounds = [seesawBackground, trampolineBackground]

  return (
    <StyledPhilosophieAndConcept>
      <Container>
        <SplitSectionsWrapper>
          {translation.concept.philosophieAndConcept.splitSections.map((s, i) => (
            <SplitSection invert={i % 2 === 1} key={getUUID()}>
              <ImageWrapper>
                <BubbleBackgroundFloat invert={i % 2 === 1} bubble={splitSectionBackgrounds[i]}>
                  <Animated animation={i % 2 === 1 ? 'fadeInLeft' : 'fadeInRight'}>
                    <Image src={splitSectionImages[i]} />
                  </Animated>
                </BubbleBackgroundFloat>
              </ImageWrapper>
              <Content invert={i % 2 === 1}>
                <Headline invert={i % 2 === 1}>{s.headline}</Headline>
                <SubHeadline>{s.subHeadline}</SubHeadline>
                {s.text.map((t) => (
                  <Text key={getUUID()}>{t}</Text>
                ))}
              </Content>
            </SplitSection>
          ))}
        </SplitSectionsWrapper>
        <CTA href="/kitauebersicht">
          <CTAText>{translation.concept.philosophieAndConcept.cta}</CTAText>
          <CTAArrow src={arrowCTA} />
        </CTA>
      </Container>
    </StyledPhilosophieAndConcept>
  )
}

export default PhilosophieAndConcept
