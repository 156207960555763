import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import { StyledLocationAssignment, List, Location, Headline } from './LocationAssignment.Styled'
import { getUUID } from '../../../../../../utility'
import { list } from '../../../../../../redux/action/crud'

const LocationAssignment = ({ locationUUIDList = [], callback }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const locations = useSelector((s) => s[props.LOCATION])
  const dispatch = useDispatch()

  useEffect(() => {
    if (!locations) dispatch(list(props.LOCATION))
  }, [locations, dispatch])

  const toggleLocation = (uuid) => () => {
    if (locationUUIDList.includes(uuid)) {
      callback(locationUUIDList.filter((u) => u !== uuid))
    } else {
      callback([...locationUUIDList, uuid])
    }
  }

  if (!Array.isArray(locations)) return false
  return (
    <StyledLocationAssignment>
      <Headline>{translation.dashboard.admin.districts.upsert.locationAssignment.headline}</Headline>
      <List>
        {locations.map((u) => (
          <Location className={locationUUIDList.includes(u.uuid) ? 'selected' : ''} key={getUUID()} onClick={toggleLocation(u.uuid)}>
            {u.name}
          </Location>
        ))}
      </List>
    </StyledLocationAssignment>
  )
}

export default LocationAssignment
