/**
 * Card position
 * @returns {Object}
 */
export const POSITION = {
  PREV: 'prev',
  NEXT: 'next',
  CURRENT: 'current',
  HIDDEN: 'hidden',
}

/**
 * Cards alignment
 * @returns {Object}
 */
export const ALIGNMENT = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
}

/**
 * Cards alignment
 * @returns {Object}
 */
export const SPREAD = {
  NARROW: 'narrow',
  MEDIUM: 'medium',
  WIDE: 'wide',
}
