import { useState } from 'react'
import {
  StyledSlider,
  ActiveImage,
  ActiveVideo,
  ActiveVideoWrapper,
  Navigator,
  Move,
  Thumbnails,
  Thumbnail,
  VideoThumbnail,
  Pagination,
  Dot,
} from './Slider.Styled'
import prevSlideIcon from '../../../../assets/image/kita/detail/prevSlide.svg'
import prevSlideIconKitaoverview from '../../../../assets/image/kita/detail/prevSlideKitaOverview.svg'
import nextSlideIcon from '../../../../assets/image/kita/detail/nextSlide.svg'
import nextSlideIconKitaoverview from '../../../../assets/image/kita/detail/nextSlideKitaOverview.svg'
import { getUUID } from '../../../../utility'

const Slider = ({ assetURLs, mode }) => {
  const [currentAsset, setCurrentAsset] = useState(0)
  const length = assetURLs.length

  const nextSlide = () => {
    setCurrentAsset(currentAsset === length - 1 ? 0 : currentAsset + 1)
  }

  const prevSlide = () => {
    setCurrentAsset(currentAsset === 0 ? length - 1 : currentAsset - 1)
  }

  const getPrevAsset = () => {
    return currentAsset === 0 ? assetURLs[length - 1] : assetURLs[currentAsset - 1]
  }

  const getNextAsset = () => {
    return currentAsset === length - 1 ? assetURLs[0] : assetURLs[currentAsset + 1]
  }

  const getCurrentAssetPage = () => {
    const currentPage = Math.floor(currentAsset / 5)
    return currentPage
  }

  const getFiveAssets = () => {
    const currentPage = getCurrentAssetPage()
    const assets = assetURLs.slice(currentPage * 5, currentPage * 5 + 5)
    if (assets.length < 5) {
      const diff = 5 - assets.length
      const firstAssets = assetURLs.slice(0, diff)
      assets.push(...firstAssets)
    }
    return assets
  }

  const getPaginationItems = () => {
    const assets = getFiveAssets()

    return assets
  }

  const isVideo = (assetKey) => {
    if (!assetKey) return false
    const videoExtentions = ['mp4', 'webm', 'mpeg']
    const ext = assetKey.split('.').pop()
    return videoExtentions.includes(ext)
  }

  return (
    <StyledSlider>
      {isVideo(assetURLs[currentAsset]) && (
        <ActiveVideoWrapper>
          <ActiveVideo src={assetURLs[currentAsset]} controls />
        </ActiveVideoWrapper>
      )}
      {!isVideo(assetURLs[currentAsset]) && <ActiveImage src={assetURLs[currentAsset]} />}
      <Navigator>
        <Move onClick={prevSlide} src={mode === 'kitaoverview' ? prevSlideIconKitaoverview : prevSlideIcon} />
        <Thumbnails>
          <Thumbnail mode={mode} src={getPrevAsset()} onClick={prevSlide} />
          {isVideo(assetURLs[currentAsset]) && <VideoThumbnail mode={mode} src={assetURLs[currentAsset]} active />}
          {!isVideo(assetURLs[currentAsset]) && <Thumbnail mode={mode} src={assetURLs[currentAsset]} active />}
          <Thumbnail mode={mode} src={getNextAsset()} onClick={nextSlide} />
        </Thumbnails>
        <Move onClick={nextSlide} src={mode === 'kitaoverview' ? nextSlideIconKitaoverview : nextSlideIcon} />
      </Navigator>
      <Pagination>
        {getPaginationItems().map((assetURL, index) => (
          <Dot key={getUUID()} active={assetURL === assetURLs[currentAsset]} onClick={() => setCurrentAsset(index)} />
        ))}
      </Pagination>
    </StyledSlider>
  )
}

export default Slider
