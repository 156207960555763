import styled from 'styled-components'

export const StyledDataProtection = styled.div`
  padding: 200px 0 100px 0;
`

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  text-align: center;
`

export const Subheadline = styled.div`
  font-family: 'bold';
  margin-top: 25px;
`
export const P = styled.div`
  margin-top: 25px;
`

export const Strong = styled.span`
  font-family: 'bold';
`

export const Link = styled.a`
  color: ${({ theme }) => theme.color.font.primary};
`

export const UL = styled.ul`
  margin-left: 20px;
`

export const LI = styled.li``
