import styled from 'styled-components'

export const StyledCarousel = styled.div`
  height: ${({ height }) => height || '500px'};
`

export const Arrow = styled.img`
  width: 50px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    filter: invert(1);
  }
`

export const ArrowWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 400px;
  position: absolute;
  z-index: 2;
  height: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    z-index: 5;
    gap: 70vw;
    pointer-events: none;
  }
`

export const ChildWrapper = styled.div`
  opacity: ${({ opacity }) => opacity};
`

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
