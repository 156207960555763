import { StyledSplit, Content, ImageSection, Image, TextSection, Name, Title, Tel, Fax, Mail, Street, City } from './Split.Styled'
import BubbleBackgroundFloat from '../../../SubComponents/BubbleBackgroundFloat/BubbleBackgroundFloat'
import Animated from '../../../SubComponents/Animated/Animated'
import props from '../../../../redux/props'
import { useSelector } from 'react-redux'

const Split = ({ invert, image, bubbleImage, data }) => {
  const { name, position, phone, mobile, fax, mail, street, city } = data
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledSplit>
      <Content reverse={invert}>
        <TextSection>
          <BubbleBackgroundFloat bubble={bubbleImage} height="150px" originX={invert ? 20 : -20} originY={invert ? -60 : 50}>
            <Name>{name}</Name>
            <Title>{position}</Title>
            {phone && <Tel href={`tel:${phone}`}>{`${translation.generic.phoneShortPrefix}${phone}`}</Tel>}
            {mobile && <Tel href={`tel:${mobile}`}>{`${translation.generic.mobileShortPrefix}${mobile}`}</Tel>}
            {fax && <Fax>{`${translation.generic.faxShortPrefix}${fax}`}</Fax>}
            {mail && <Mail href={`mailto:${mail}`}>{mail}</Mail>}
            {street && <Street>{street}</Street>}
            {city && <City>{city}</City>}
          </BubbleBackgroundFloat>
        </TextSection>
        <ImageSection>
          <Animated animation={invert ? 'fadeInLeft' : 'fadeInRight'} duration={3}>
            <Image src={image} />
          </Animated>
        </ImageSection>
      </Content>
    </StyledSplit>
  )
}

export default Split
