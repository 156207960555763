import { StyledList, Items, Item } from './List.Styled'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { list } from '../../../../../redux/action/crud'
import { Input, Modal } from '@think-internet/ui-components'
import UpsertForm from '../UpsertForm/UpsertForm'

const List = () => {
  const district = useSelector((s) => s[props.DISTRICT])
  const translation = useSelector((s) => s[props.TRANSLATION])
  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState('')
  const [formData, setFormData] = useState(null)

  useEffect(() => {
    if (!district) {
      dispatch(list(props.DISTRICT))
    }
  })

  const filter = (l) => l.name.toLowerCase().includes(searchTerm.toLowerCase())

  const closeForm = () => setFormData(null)

  if (!district) return <></>
  return (
    <StyledList>
      <Input value={searchTerm} onChange={setSearchTerm} placeholder={translation.dashboard.admin.districts.search.placeholder} />
      <Items>
        {district.filter(filter).map((l) => (
          <Item key={l.uuid} onClick={() => setFormData(l)}>
            {l.name}
          </Item>
        ))}
      </Items>
      {formData && (
        <Modal title={formData.name} close={closeForm} closeContent={translation.dashboard.admin.districts.upsert.close} open={!!formData}>
          <UpsertForm data={formData} callback={closeForm} />
        </Modal>
      )}
    </StyledList>
  )
}

export default List
