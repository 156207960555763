import { StyledConcept, Content, ImageSection, Image, TextSection, Headline, Subheadline, Description, CTA, CTAWrapper } from './Concept.Styled'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import BubbleBackgroundFloat from '../../SubComponents/BubbleBackgroundFloat/BubbleBackgroundFloat'
import image from '../../../assets/image/home/concept/image.svg'
import bubble from '../../../assets/image/home/concept/bubble.svg'
import Animated from '../../SubComponents/Animated/Animated'

const Concept = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledConcept>
      <Content>
        <ImageSection>
          <Animated animation="fadeInLeft" duration={3}>
            <Image src={image} />
          </Animated>
        </ImageSection>
        <TextSection>
          <BubbleBackgroundFloat bubble={bubble} height="200px" originX={110} originY={-50}>
            <Headline>{translation.home.concept.headline}</Headline>
            <Subheadline>{translation.home.concept.subheadline}</Subheadline>
            <Description>{translation.home.concept.description}</Description>
            <CTAWrapper>
              <CTA href="/konzept">{translation.home.concept.cta}</CTA>
            </CTAWrapper>
          </BubbleBackgroundFloat>
        </TextSection>
      </Content>
    </StyledConcept>
  )
}

export default Concept
