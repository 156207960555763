import styled from 'styled-components'
import Animated from '../SubComponents/Animated/Animated'

export const StyledDownload = styled.div`
  padding-bottom: 150px;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: auto;
  }
`

export const Content = styled.div`
  padding: 25px 0;
  width: fit-content;
`

export const ImageWrapper = styled.div`
  display: inline-flex;
  position: relative;
  margin: 0;
  height: 100%;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    text-align: center;
    padding: 100px 0 0px;
  }
`

export const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 75%;
  max-width: 75%;
  width: 75%;
  transform: translate(-50%, -50%);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
`

export const Headline = styled(Animated)`
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: 'bold';
  color: ${({ theme }) => theme.color.font.blue};
  text-align: center;
  text-transform: uppercase;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`
export const Subheadline = styled(Animated)`
  color: ${({ theme }) => theme.color.font.blue};
  text-align: center;
  * {
    font-size: ${({ theme }) => theme.font.size.xlg};
    text-transform: uppercase;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    * {
      font-size: ${({ theme }) => theme.font.size.lg};
    }
  }
`

export const CTA = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  background-color: ${({ theme }) => theme.color.background.blue};
  padding: 15px 50px;
  border-radius: 50px;
  margin-top: 15px;
  margin: 50px auto 50px;
  width: fit-content;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
`
