import {
  StyledFamilyCenterPopup,
  Image,
  ImageWrapper,
  Headline,
  Subheadline,
  List,
  Item,
  Name,
  CTA,
  BackButton,
  BackIcon,
  BackText,
  CloseButton,
} from './FamilyCenterPopup.styled'
import image from '../../../../assets/image/kita/detail/familyCenter/popup/image.png'
import backIcon from '../../../../assets/image/kita/detail/familyCenter/popup/back.png'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'

const FamilyCenterPopup = ({ data, close }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const { additionalInfo, additionalInfoPDF, beratungInfoPDF, familienbildungInfoPDF, vereinbarkeitInfoPDF, moreInfoZIP } = data

  return (
    <StyledFamilyCenterPopup>
      <CloseButton onClick={close} />
      <ImageWrapper>
        <Image src={image} />
      </ImageWrapper>
      <Headline>{translation.kita.detail.familyCenter.popup.headline}</Headline>
      <Subheadline>{translation.kita.detail.familyCenter.popup.subheadline}</Subheadline>
      <List>
        {beratungInfoPDF && (
          <Item>
            <Name>{translation.kita.detail.familyCenter.staticProperties.beratung}</Name>
            <CTA href={beratungInfoPDF} target="_blank" />
          </Item>
        )}
        {familienbildungInfoPDF && (
          <Item>
            <Name>{translation.kita.detail.familyCenter.staticProperties.familienbildung}</Name>
            <CTA href={familienbildungInfoPDF} target="_blank" />
          </Item>
        )}
        {vereinbarkeitInfoPDF && (
          <Item>
            <Name>{translation.kita.detail.familyCenter.staticProperties.vereinbarkeit}</Name>
            <CTA href={vereinbarkeitInfoPDF} target="_blank" />
          </Item>
        )}
        {additionalInfo && additionalInfoPDF && (
          <Item>
            <Name>{translation.kita.detail.familyCenter.properties[additionalInfo]}</Name>
            <CTA href={additionalInfoPDF} target="_blank" />
          </Item>
        )}
        {!!moreInfoZIP && (
          <Item>
            <Name>{translation.kita.detail.familyCenter.properties.moreInfoZIP}</Name>
            <CTA href={moreInfoZIP} target="_blank" download />
          </Item>
        )}
      </List>
      <BackButton onClick={close}>
        <BackIcon src={backIcon} />
        <BackText>{translation.kita.detail.familyCenter.popup.close}</BackText>
      </BackButton>
    </StyledFamilyCenterPopup>
  )
}

export default FamilyCenterPopup
