import styled from 'styled-components'

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  position: relative;
  margin-top: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`

export const IndexWrapper = styled.div`
  width: 5%;
`

export const GenericInfo = styled.div`
  width: 70%;
`
export const TrafficLight = styled.div`
  width: 10%;
`

export const DetailToggle = styled.div`
  width: 5%;
`
export const GoToToggle = styled.div`
  width: 5%;
`

export const Label = styled.div`
  color: ${({ theme }) => theme.color.font.orange};
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  ${({ mode, theme }) =>
    mode === 'kitaoverview' &&
    `
      color: ${theme.color.font.primary};
  `}
`
