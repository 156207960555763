import { Input, Button } from '@think-internet/ui-components'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { create } from '../../../../../interface/crud'
import { list } from '../../../../../redux/action/crud'
import props from '../../../../../redux/props'
import toast, { TYPE } from '../../../../../toast'
import { CustomForm, Headline, Row, StyledCreate } from './Create.Styled'

const Create = () => {
  const [payload, setPayload] = useState({})
  const token = useSelector((s) => s[props.TOKEN])
  const translation = useSelector((s) => s[props.TRANSLATION])
  const dispatch = useDispatch()

  const updateProp = (prop) => (value) => setPayload({ ...payload, [prop]: value })

  const submit = async () => {
    const user = await create(token, props.USER, payload)
    if (user) {
      toast(TYPE.SUCCESS, translation.dashboard.admin.users.create.success.submit)
      setPayload({})
      dispatch(list(props.USER))
    } else {
      toast(TYPE.ERROR, translation.dashboard.admin.users.create.error.submit)
    }
  }

  return (
    <StyledCreate>
      <Headline>{translation.dashboard.admin.users.create.headline}</Headline>
      <CustomForm onSubmit={submit}>
        <Row>
          <Input value={payload.firstname} onChange={updateProp('firstname')} placeholder={translation.generic.firstname} required />
          <Input value={payload.lastname} onChange={updateProp('lastname')} placeholder={translation.generic.lastname} required />
        </Row>
        <Row>
          <Input value={payload.mail} onChange={updateProp('mail')} placeholder={translation.generic.mail} type="email" required />
          <Input value={payload.password} onChange={updateProp('password')} placeholder={translation.generic.password} type={'password'} required />
        </Row>
        <Button type="submit" text={translation.generic.save} />
      </CustomForm>
    </StyledCreate>
  )
}

export default Create
