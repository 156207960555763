import styled from 'styled-components'

export const StyledList = styled.div`
  margin-top: 25px;
`

export const Items = styled.div`
  margin-top: 15px;
`

export const Item = styled.div`
  margin-top: 10px;
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.color.background.lightorange};
  border-radius: 30px;
  cursor: pointer;
`
