import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { Icon, Label, StyledUseExactLocation } from './UseExactLocation.Styled'
import icon from '../../../../assets/image/generic/blue-navigation-arrow.svg'
import invertedIcon from '../../../../assets/image/generic/orange-navigation-arrow.svg'
import useGeoLocator from './useGeoLocator'
import call from '../../../../interface/functional'
import routes from '../../../../redux/routes'

const UseExactLocation = ({ callback, invertedColorScheme }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const geoLocation = useGeoLocator()

  const resolveGeoCoordinates = async () => {
    if (geoLocation.lat && geoLocation.lng) {
      const payload = await call(null, routes.geo_coordinates_to_address, geoLocation)
      if (payload.status && payload.response) {
        callback(payload.response)
      }
    }
  }

  if (!geoLocation) return <></>
  return (
    <StyledUseExactLocation>
      <Icon src={invertedColorScheme ? invertedIcon : icon} />
      <Label invertedColorScheme={invertedColorScheme} onClick={resolveGeoCoordinates}>
        {translation.kitapiloot.search.useExactLocation.label}
      </Label>
    </StyledUseExactLocation>
  )
}

export default UseExactLocation
