import { Route, Routes, Navigate } from 'react-router-dom'
import ReasonPageLayout from './ReasonPageLayout/ReasonPageLayout'
import parentsHeroImage from '../../assets/image/reasonsPages/parents/hero.png'
import childrenHeroImage from '../../assets/image/reasonsPages/children/hero.png'
import interestedHeroImage from '../../assets/image/reasonsPages/interested/hero.png'
import { useSelector } from 'react-redux'
import props from '../../redux/props'

const ReasonPages = () => {
  const SpecifyReasonPage = ({ translationKey, heroImage, heroSublineColorKey }) => {
    const translation = useSelector((s) => s[props.TRANSLATION])
    return (
      <ReasonPageLayout
        heroImage={heroImage}
        heroSubline={translation.reasonPages[translationKey].heroSubline}
        headline={translation.reasonPages[translationKey].headline}
        reasonsArray={translation.reasonPages[translationKey].reasons}
        heroSublineColorKey={heroSublineColorKey}
      />
    )
  }

  return (
    <Routes>
      <Route path={'eltern'} element={<SpecifyReasonPage translationKey={'parents'} heroImage={parentsHeroImage} heroSublineColorKey={'orange'} />} />
      <Route path={'kinder'} element={<SpecifyReasonPage translationKey={'children'} heroImage={childrenHeroImage} />} />
      <Route
        path={'interessierte'}
        element={<SpecifyReasonPage translationKey={'interested'} heroImage={interestedHeroImage} heroSublineColorKey={'lightblue'} />}
      />
      <Route path={'*'} element={<Navigate replace to="/gruende-uebersicht" />} />
    </Routes>
  )
}

export default ReasonPages
