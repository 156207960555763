import { StyledCards, Headline, Wrapper } from './Cards.Styled'
import icon1 from '../../../assets/image/principles/cards/icon1.svg'
import bg1 from '../../../assets/image/principles/cards/bg1.png'
import icon2 from '../../../assets/image/principles/cards/icon2.svg'
import bg2 from '../../../assets/image/principles/cards/bg2.png'
import icon3 from '../../../assets/image/principles/cards/icon3.svg'
import bg3 from '../../../assets/image/principles/cards/bg3.png'
import icon4 from '../../../assets/image/principles/cards/icon4.svg'
import bg4 from '../../../assets/image/principles/cards/bg4.png'
import icon5 from '../../../assets/image/principles/cards/icon5.svg'
import bg5 from '../../../assets/image/principles/cards/bg5.png'
import icon6 from '../../../assets/image/principles/cards/icon6.svg'
import bg6 from '../../../assets/image/principles/cards/bg6.png'
import icon7 from '../../../assets/image/principles/cards/icon7.svg'
import bg7 from '../../../assets/image/principles/cards/bg7.png'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'
import Card from './Card/Card'
import { getUUID } from '../../../utility'

const Cards = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  const iconAndBackground = [
    { icon: icon1, bg: bg1 },
    { icon: icon2, bg: bg2 },
    { icon: icon3, bg: bg3 },
    { icon: icon4, bg: bg4 },
    { icon: icon5, bg: bg5 },
    { icon: icon6, bg: bg6 },
    { icon: icon7, bg: bg7 },
  ]

  return (
    <StyledCards>
      <Headline animation="typing" duration="5" disabledOnMobile>
        {translation.principles.cards.headline}
      </Headline>
      <Wrapper>
        {translation.principles.cards.items.map((card, i) => (
          <Card title={card.title} icon={iconAndBackground[i].icon} bg={iconAndBackground[i].bg} key={getUUID()} />
        ))}
      </Wrapper>
    </StyledCards>
  )
}

export default Cards
