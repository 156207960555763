import { backendDomain } from './utility'
import axios from 'axios'

export const METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
}

const mergeRequestHeaders = (headers, mergeObject) => {
  if (!headers) return mergeObject
  return { ...headers, ...mergeObject }
}

const prepRequestForDownload = (request, fileDownloadMimeType) => {
  if (!fileDownloadMimeType) return request
  request.responseType = 'blob'
  request.headers = mergeRequestHeaders(request.headers, {
    Accept: fileDownloadMimeType,
  })
  return request
}

const prepRequestForUpload = (request, body, filesForUpload, uploadProgressCallback) => {
  if (!filesForUpload) return request

  request.method = METHOD.POST

  const formData = new FormData()
  if (Array.isArray(filesForUpload)) {
    filesForUpload.forEach((file) => formData.append('files', file))
  }
  if (typeof body === 'object') {
    Object.keys(body).forEach((key) => formData.append(key, body[key]))
  }

  if (typeof body === 'object' && body.token) {
    request.url = `${request.url}?token=${body.token}`
  }

  request.data = formData
  request.headers = mergeRequestHeaders(request.headers, { 'Content-Type': 'multipart/form-data' })

  if (typeof progressCallback === 'function') {
    request.onUploadProgress = (e) => {
      const progress = parseInt(Math.round((e.loaded / e.total) * 100))
      uploadProgressCallback(progress)
    }
  }
  return request
}

const fetch = async (method, uriSuffix, body = {}, filesForUpload = null, uploadProgressCallback = null, fileDownloadMimeType = null) => {
  try {
    if (!uriSuffix) return false
    let request = {
      method,
      url: `${backendDomain}${uriSuffix}`,
      [method === METHOD.GET ? 'params' : 'data']: body,
    }

    request = prepRequestForDownload(request, fileDownloadMimeType)
    request = prepRequestForUpload(request, body, filesForUpload, uploadProgressCallback)

    const result = await axios(request)
    return result.data
  } catch (err) {
    return false
  }
}

export const s3Upload = async (presignedURL, file, progressCallback) => {
  const uploadResult = await axios.put(presignedURL, file, {
    headers: { 'Content-Type': file.type },
    onUploadProgress: (e) => {
      if (typeof progressCallback !== 'function') return
      const progress = parseInt(Math.round((e.loaded / e.total) * 100))
      progressCallback(progress)
    },
  })
  return uploadResult.status === 200
}

export default fetch
